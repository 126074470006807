import React, { useState } from "react";
import { NavLink as ReactLink, useNavigate } from "react-router-dom";
import {
    Collapse, Nav, NavItem, Navbar, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown, NavbarToggler, Button
} from "reactstrap";
import { useEffect } from 'react';
import axios from "axios";
//import { useMsal } from "@azure/msal-react";
//import { loginRequest } from "./authConfig";
import { log } from "console";
import APICalls from "./APICalls"
import { useMsal } from "@azure/msal-react";
import { UserType } from "./MagniTypes";


function Header2({ }) {
    const navigate = useNavigate();
    const { accounts } = useMsal();
    const activeAccount = accounts[0];
    const { instance } = useMsal();
    const [user, setUser] = useState<UserType>(new UserType());
    const apiCalls = new APICalls();
    const [isOpen, setIsOpen] = useState(true);



    useEffect(() => {
        if (localStorage.getItem('user') == null) {
            GetUser();
        }
        else {
            setUser(JSON.parse(localStorage.getItem('user') as string));
        }
    }, []);

    const GetUser = async () => {
        const response = await apiCalls.GetCQSUser();
        const data = response.data;
        setUser(data.UserName);
        localStorage.setItem('user', JSON.stringify(data));
    }

    const handeClick = () => {
        setIsOpen(!isOpen);
        console.log(isOpen);
    }

    const logout = async () => {
        delete axios.defaults.headers.common['Authorization'];
        instance.logout();
        navigate("/login");
      }
      return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ maxWidth: "100%", width: "100%", marginBottom:'20px' }}>  {/* Adjust maxWidth as needed */}
                <Navbar style={{ backgroundColor: "#efefef", padding: "0.5rem 1rem", borderRadius: "20px", }} expand="md">
                    <Nav className="w-100 d-flex align-items-center justify-content-between">
                        <NavbarToggler style={{ backgroundColor: "#EFEFEF" }} onClick={handeClick} />
                        <Collapse isOpen={isOpen} navbar className="d-flex w-100 justify-content-between">
                            <img
                                style={{  height: 50, borderColor: "#0d0673", marginLeft: '00px' }}
                                src="/Magni2.png"
                                alt="MagniDev Logo"
                            />

                            <h5 style={{ color: "#000000", marginLeft: '-50px', fontSize: '30px' }}>
                                Condition Reports
                            </h5>

                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                {user && (
                                    <span style={{ fontSize: '16px', fontWeight: 'bold', color: '#000000' }}>
                                        {typeof user === 'object' ? user.UserName : user}
                                    </span>
                                )}
                                <Button
                                    color="primary"
                                    style={{ fontSize: '16px', padding: '5px 10px', backgroundColor: '#efefef', color: '#000000' }}
                                    onClick={logout}
                                >
                                    Logout
                                </Button>
                            </div>
                        </Collapse>
                    </Nav>
                </Navbar>
            </div>
        </div>
    );
}
export default Header2