import { ChangeEvent } from "react";

export class ReportObject {
  public CRNumber: number = 0;
  public CRTitle: string = '';
  public CRDescription: string = '';
  public PossibleCause: string = '';
  public Company: string = '';
  public Product: string = '';
  public TotalQuantityAffected: number = 0;
  public Initiator: string = '';
  public DateInitiated: string | null = null;
  public RMARequired: boolean = false;
  public SCARRequired: boolean = false;
  public CRLevel: number = 0;
  public CARequired: boolean = false;
  public InitialComments: string = '';
  public QCManagerSignOff: number = 0;
  public ActionAssigned: number = 0;
  public ActionAssignedDate: string | null = null;
  public InitialAnalysis: string | null = '';
  public ProductDisposition: string | null = '';
  public DispositionInstruction: string | null = '';
  public FinalConclusion: string | null = '';
  public TrendCode1: string | null = '';
  public TrendCode2: string | null = '';
  public TrendCode3: string | null = '';
  public TrendCode4: string | null = '';
  public TrendCode5: string | null = '';
  public DateCompleted: string | null = null;
  public QCManagerApprove: number = 0;
  public QCDateApprove: string | null = null;
  public InitiatorApprove: number = 0;
  public InitiatorApproveDate: string | null = null;
  public Approve3: number = 0;
  public Approve3Date: string | null = null;
  public Units: string | null = '';
  public Location: string | null = '';
  public ReturnCategory: string | null = '';
  public DidNotFollowProcedure: boolean = false;
  public ProcessChange: boolean = false;
  public MISC: boolean = false;
  public OpportunityForImprovement: boolean = false;
  public SCARIssued: boolean = false;
  public InternalAuditFinding: boolean = false;
  public SerialNumber: string | null = '';
  public MagniDetroitCR: string | null = '';
  public ResearchProject: string | null = '';
  public Department: string | null = '';
  public CustomerFeedbackCategory: string | null = '';
  public CustomerComplaint: string | null = '';
  public AnalyticalReport: string | null = '';
  public AuditFinding: boolean = false;
  public ProposedAuditDate: string | null = null;
  public DidAssigneeCompleteSatisfactorily: boolean = false;
  public MeasurableFinding: boolean = false;
  public Level1: boolean = false;
  public Level2: boolean = false;
  public Level3: boolean = false;
  public ContinuousImprovement: boolean = false;
  public LastUpdate: string | null = null;
  public Visc: string | null = '';
  public WPG: string | null = '';
  public WtSlds: string | null = '';
  public Color: string | null = '';
  public ReclaimfromToteReturn: boolean = false;
  public Sales: number = 0;
  public ResearchSupported: boolean = false;
  public Quarantine: boolean = false;
  public ReturnCondition: string | null = '';
  public ReturnPlant: string | null = '';
  public Complaint: boolean = false;

  public CorrectiveAction: ReportCA | null = null;
  public ProcessChangeObject: ProcessChangeOBJ | null = null;
}


export class ReportObjectGrid {
  public CRNumber: number = 0;
  public CRTitle: string = '';
  public CRDescription: string = '';
  public PossibleCause: string = '';
  public Company: string = '';
  public Product: string = '';
  public TotalQuantityAffected: number = 0;
  public Initiator: string = '';
  public DateInitiated: string | null = null;
  public RMARequired: boolean = false;
  public SCARRequired: boolean = false;
  public CRLevel: number = 0;
  public CARequired: boolean = false;
  public InitialComments: string = '';
  public QCManagerSignOff: number = 0;
  public ActionAssigned: number = 0;
  public ActionAssignedDate: string | null = null;
  public InitialAnalysis: string | null = '';
  public ProductDisposition: string | null = '';
  public DispositionInstruction: string | null = '';
  public FinalConclusion: string | null = '';
  public TrendCode1: string | null = '';
  public TrendCode2: string | null = '';
  public TrendCode3: string | null = '';
  public TrendCode4: string | null = '';
  public TrendCode5: string | null = '';
  public DateCompleted: string | null = null;
  public QCManagerApprove: number = 0;
  public QCDateApprove: string | null = null;
  public InitiatorApprove: number = 0;
  public InitiatorApproveDate: string | null = null;
  public Approve3: number = 0;
  public Approve3Date: string | null = null;
  public Units: string | null = '';
  public Location: string | null = '';
  public ReturnCategory: string | null = '';
  public DidNotFollowProcedure: boolean = false;
  public ProcessChange: boolean = false;
  public MISC: boolean = false;
  public OpportunityForImprovement: boolean = false;
  public SCARIssued: boolean = false;
  public InternalAuditFinding: boolean = false;
  public SerialNumber: string | null = '';
  public MagniDetroitCR: string | null = '';
  public ResearchProject: string | null = '';
  public Department: string | null = '';
  public CustomerFeedbackCategory: string | null = '';
  public CustomerComplaint: string | null = '';
  public AnalyticalReport: string | null = '';
  public AuditFinding: boolean = false;
  public ProposedAuditDate: string | null = null;
  public DidAssigneeCompleteSatisfactorily: boolean = false;
  public MeasurableFinding: boolean = false;
  public Level1: boolean = false;
  public Level2: boolean = false;
  public Level3: boolean = false;
  public ContinuousImprovement: boolean = false;
  public LastUpdate: string | null = null;
  public Visc: string | null = '';
  public WPG: string | null = '';
  public WtSlds: string | null = '';
  public Color: string | null = '';
  public ReclaimfromToteReturn: boolean = false;
  public Sales: number = 0;
  public ResearchSupported: boolean = false;
  public Quarantine: boolean = false;
  public ReturnCondition: string | null = '';
  public ReturnPlant: string | null = '';
  public Lot: string | null = '';

  public CorrectiveAction: ReportCA | null = null;
  public ProcessChangeObject: ProcessChangeOBJ | null = null;
}

export class ProcessChangeOBJ {
  public ID: number = 0;
  public CRNumber: number = 0;
  public ProcessChangeDescription: string = '';
  public EquipmentImpacted: string = '';
  public PartNumbersImpacted: string = '';
  public StaffApproval: boolean = false;
  public StaffApprovalDate: string | null = null;
  public CustomerNotificationRequired: boolean = false;
  public DateNotified: string | null = null;
  public ProcessChangeLaunchDate: string | null = null;
  public CustomerApprovalReceived: boolean = false;
  public DateSent: string | null = null;
  public DateApproved: string | null = null;
  public LaunchFeedback: string = '';
}

export interface User {
  userID: number;
  FullName: string;
}

export class ReportCA {
  public CRNumber?: Number = 0;
  public CPAInterimAction: string = '';
  public CPARootCause: string = '';
  public CPAActionTaken: string = '';
  public CPAResponsibleSign: number = 0;
  public CPAImplementDate: string | null = null;
  public CPAFollowUP: string = '';
  public CPAPrevent: string = '';
  public CPACompletionDate: string | null = null;
  public CPAQCManagerApprove: number = 0;
  public CPAQCManagerDate: string | null = null;
  public CPAInitiatorApprove: number = 0;
  public CPAInitiatorDate: string | null = null;
  public CPAApprove3: number = 0;
  public CPAApprove3Date: string | null = null;
  public IntOrExt: string = '';
}

export interface Column {
  name: string;
  header: string;
  defaultFlex: number;
  headerProps: {
    style: {
      backgroundColor: string;
      color: string;
    };
    // Add other properties as needed
  };
}

export interface reportErrors {
  //CRNumber: boolean;
  CRTitle: boolean;
  // CRDescription: boolean;
  // PossibleCause: boolean;
  // Company: boolean;
  // Product: boolean;
  // TotalQuantityAffected: boolean;
  // Initiator: boolean;
  // DateInitiated: boolean;
  // RMARequired: boolean;
  // SCARRequired: boolean;
  // CRLevel: boolean;
  // CARequired: boolean;
  // InitialComments: boolean;
  // QCManagerSignOff: boolean;
  // ActionAssigned: boolean;
  // ActionAssignedDate: boolean;
  // InitialAnalysis: boolean;
  // ProductDisposition: boolean;
  // DispositionInstruction: boolean;
  // FinalConclusion: boolean;
  // TrendCode1: boolean;
  // TrendCode2: boolean;
  // TrendCode3: boolean;
  // TrendCode4: boolean;
  // TrendCode5: boolean;
  // DateCompleted: boolean;
  // QCManagerApprove: boolean;
  // QCDateApprove: boolean;
  // InitiatorApprove: boolean;
  // InitiatorApproveDate: boolean;
  // Approve3: boolean;
  // Approve3Date: boolean;
  // Units: boolean;
  // Location: boolean;
  // ReturnCategory: boolean;
  // DidNotFollowProcedure: boolean;
  // ProcessChange: boolean;
  // MISC: boolean;
  // OpportunityForImprovement: boolean;
  // SCARIssued: boolean;
  // InternalAuditFinding: boolean;
  // SerialNumber: boolean;
  // MagniDetroitCR: boolean;
  // ResearchProject: boolean;
  // Department: boolean;
  // CustomerFeedbackCategory: boolean;
  // CustomerComplaint: boolean;
  // AnalyticalReport: boolean;
  // AuditFinding: boolean;
  // ProposedAuditDate: boolean;
  // DidAssigneeCompleteSatisfactorily: boolean;
  // MeasurableFinding: boolean;
  // Level1: boolean;
  // Level2: boolean;
  // Level3: boolean;
  // ContinuousImprovement: boolean;
  // LastUpdate: boolean;
  // Visc: boolean;
  // WPG: boolean;
  // WtSlds: boolean;
  // Color: boolean;
  // ReclaimfromToteReturn: boolean;
  // Sales: boolean;
  // ResearchSupported: boolean;
  // Quarantine: boolean;
}

export interface KeyPairType {
  Username: string;
  UserID: number;
}

export interface UserObject {
  UserID: number;
  UserName: string;
  FirstName: string;
  LastName: string;
  Email: string;
  ApprovalID: number;
  ApprovalType: string;
  UserRole: string;
}

export class UserType {
  public UserID: number = 0;
  public UserName: string = "";
  public FirstName: string = "";
  public LastName: string = "";
}

export interface KeyPairValues {
  type: string;
  typeId: string;
}

export const renderDate = (props: any, { index }: { index: any }) => {
  const dateName = props.cellProps.computedHeader.toString().split(' ')[0];
  const i = index == 1 ? 2 : 1;
  const filterName = props.filterValue.operator.toString();
  const firstResult = filterName.replace(/([A-Z])/g, " $1");
  const finalResult = firstResult.charAt(0).toUpperCase() + firstResult.slice(1);
  return {
    dateFormat: 'MM-DD-YYYY',
    cancelButton: false,
    highlightWeekends: false,
    placeholder: index == 1 ? dateName + ' date is before...' : dateName + ' ' + (finalResult === 'Neq' ? 'Not Equal' : finalResult) + '...',
  }
}

export interface CheckboxProps {
  id: string;
  name: string;
  label: string;
  checked: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

export interface CheckboxGroupProps {
  checkboxes: CheckboxProps[];
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  isDisabled?: boolean;
}

interface CheckboxOption {
  id: string;
  name: string;
  label: string;
}
