import React from "react";
import { useState, useEffect } from 'react';
import axios, { AxiosResponse } from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Input, Col, Row, Label, Button, Spinner } from "reactstrap";
import qs from "qs";
import { UserObject } from "./MagniTypes";

class APICalls {



  //private baseURL = "https://localhost:7214";
  private baseURL = "https://api.themagnigroup.com"

  public LoginUser = async (formData: any): Promise<boolean> => {
    const response = await axios.post(
      this.baseURL + '/authorize',
      qs.stringify({
        grant_type: 'password',
        username: formData.userName,
        password: formData.password,
      }),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );

    console.log(response);
    // Extract the access token and refresh token from the response
    const { access_token, refresh_token } = response.data;

    if (access_token === undefined || refresh_token === undefined) {
      return false;
    }
    localStorage.setItem('access_token', access_token);
    localStorage.setItem('refresh_token', refresh_token);
    localStorage.setItem('userName', formData.userName);

    return true;

  }

  public GetUser = async (): Promise<UserObject> => {
    const response = await axios.get(
      this.baseURL + '/api/User/GetCQSUserObject')
    const data = JSON.parse(response.data);
    localStorage.setItem('user', JSON.stringify(data));
    return data as UserObject;
  }


  public GetAllReports = async (loc?: string) => {
    const url = loc 
      ? `${this.baseURL}/api/ConditionReports/GetAllReports?loc=${loc}`
      : `${this.baseURL}/api/ConditionReports/GetAllReports`;
      
    const response = await axios.get(url);
    console.log(response);
    return response;
  }

  public GetActiveReports = async (loc?: string) => {
    const url = loc 
      ? `${this.baseURL}/api/ConditionReports/GetActiveReports?loc=${loc}`
      : `${this.baseURL}/api/ConditionReports/GetActiveReports`;
      
    const response = await axios.get(url);
    console.log(response);
    return response;
  }

  public GetClosedReports = async (loc?: string) => {
    const url = loc 
      ? `${this.baseURL}/api/ConditionReports/GetClosedReports?loc=${loc}`
      : `${this.baseURL}/api/ConditionReports/GetClosedReports`;
      
    const response = await axios.get(url);
    console.log(response);
    return response;
  }

  public GetReportByID = async (data: string) => {
    const response = await axios.get(
      `${this.baseURL}/api/ConditionReports/GetReportByID?id=${data}`)
    return response;
  }

  public GetProducts = async () => {
    const response = await axios.get(
      `${this.baseURL}/api/ConditionReports/GetCRProducts`)
    return response;
  }

  public GetCRUsers = async () => {
    const response = await axios.get(
      `${this.baseURL}/api/ConditionReports/GetCRUsers`)
    return response;
  }

  public GetAssignedToUsers = async () => {
    const response = await axios.get(
      `${this.baseURL}/api/ConditionReports/GetActAssignUsers`)
    return response;
  }

  public GetCustomers = async () => {
    const response = await axios.get(
      `${this.baseURL}/api/Task/GetCustomers`)
    return response;
  }

  public AddReport = async (data: any) => {
    const response = await axios.post(
      `${this.baseURL}/api/ConditionReports/AddReport`, data
    )
    return response;
  }

  public EditReport = async (data: any) => {
    const response = await axios.post(
      `${this.baseURL}/api/ConditionReports/EditReport`, data
    )
    return response;
  }

  public GetCQSUser = async () => {
    const response = await axios.get(
      `${this.baseURL}/api/User/GetCQSUserObject`
    )
    return response
  }

  public AddItem = async (data: any) => {
    const response = await axios.post(
      `${this.baseURL}/api/ConditionReports/AddItem`, data
    )
    return response;
  }

  public EditItem = async (data: any) => {
    const response = await axios.put(
      `${this.baseURL}/api/ConditionReports/EditItem`, data
    )
    return response;
  }

  public DeleteItem = async (costID: number) => {
    const response = await axios.delete(
      `${this.baseURL}/api/ConditionReports/DeleteItem?costID=${costID}`
    )
    return response;
  }

  public GetItems = async (CRNumber: number) => {
    const response = await axios.get(
      `${this.baseURL}/api/ConditionReports/GetItems?crNumber=${CRNumber}`
    )
    return response;
  }

  public AddRMA = async (data: any) => {
    const response = await axios.post(
      `${this.baseURL}/api/ConditionReports/AddRMA`, data
    )
    console.log(data)
    return response;
  }

  public EditRMA = async (data: any) => {
    const response = await axios.put(
      `${this.baseURL}/api/ConditionReports/EditRMA`, data
    )
    console.log(data)
    return response;
  }

  public DeleteRMA = async (RMAID: number) => {
    const response = await axios.delete(
      `${this.baseURL}/api/ConditionReports/DeleteRMA?id=${RMAID}`
    )
    console.log(RMAID)
    return response;
  }

  public GetRMA = async (CRNumber: number) => {
    const response = await axios.get(
      `${this.baseURL}/api/ConditionReports/GetRMA?CRNum=${CRNumber}`
    )
    console.log(response.data)
    return response;
  }

  public AddProcess = async (data: any) => {
    const response = await axios.post(
      `${this.baseURL}/api/ConditionReports/AddProcess`, data
    )
    console.log(data)
    return response;
  }

  public EditProcess = async (data: any) => {
    const response = await axios.put(
      `${this.baseURL}/api/ConditionReports/EditProcess`, data
    )
    console.log(data)
    return response;
  }







}


export default APICalls;