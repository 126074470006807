import React from "react";
import { useState, useEffect } from 'react';
import { Input, Button, Label, FormGroup, ModalHeader, Modal, ModalBody, ModalFooter, Row, Col, Table } from "reactstrap";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Menu from './Menu';
import Header2 from './Header2';
import { BounceLoader } from "react-spinners";
import { TextField } from "@mui/material";
import { useNavigate } from "react-router";
import moment from "moment";
import { KeyPairType, ReportObject, UserObject, reportErrors, UserType, ReportCA, User } from './MagniTypes';
import APICalls from "./APICalls";

function AddCR() {
  const dateRef = React.useRef<HTMLInputElement>(null);
  const nav = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  //const [taskTypeoptions, setOptions] = useState([]);
  //const [priorityoptions, setOptions1] = useState([]);
  const [customerOptions, setCustomerOptions] = useState([]);
  //const [taskCategoryoptions, setOptions3] = useState([]);
  const [user, setUser] = useState<UserType>(new UserType());
  const [productoptions, setProductOptions] = useState([]);
  //const [assignedToUsers, setAssignedToUsers] = useState([]);
  //const [systemoptions, setOptions5] = useState([]);
  //const [managerOptions, setManagerOptions] = useState<KeyPairType[]>([]);
  //const [assignUsers, setAssignUsers] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);
  //const [nextUpdate, setNextUpdate] = useState([]);
  const [CRUsers, setCRUsers] = useState<User[]>([]);
  //const navigate = useNavigate();
  const apiCalls = new APICalls();


  const [showModal, setShowModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [RMAshowModal, setRMAShowModal] = useState(false);
  const [processChangeModal, setProcessChangeModal] = useState(false);

  const [costItem, setCostItem] = useState("");
  const [cost, setCost] = useState("");
  //const [costs, setCosts] = useState<{ item: string; cost: string }[]>([]);
  const [items, setItems] = useState<{ CostID: number; CRNumber: number; Item: string; Cost: string }[]>([]);
  const [selectedEntry, setSelectedEntry] = useState<{ CostID: number; Item: string; Cost: string } | null>(null);

  const [deleteEntry, setDeleteEntry] = useState<{ CostID: number; Item: string } | null>(null);


  const [rmaid, setrmaID] = useState(0);
  const [containerNum, setContainerNum] = useState(0);
  const [containerType, setContainerType] = useState('');
  const [gallonNum, setGallonNum] = useState(0);
  const [weight, setWeight] = useState(0);
  const [lot, setLot] = useState('');
  //const [ReturnCondition, setReturnCondition] = useState('');
  //const [ReturnPlant, setReturnPlant] = useState('');
  const [RMAitems, setRMAItems] = useState<{ rmaID: number; CRNumber: number; ContainerNum: number; ContainerType: string; GallonNum: number; Weight: number; Lot: string; }[]>([]);
  const [RMAselectedEntry, setRMASelectedEntry] = useState<{ rmaID: number; CRNumber: number; ContainerNum: number; ContainerType: string; GallonNum: number; Weight: number; Lot: string; } | null>(null);

  const [RMAdeleteModal, setRMAdeleteModal] = useState(false);
  const [RMAdeleteEntry, setRMAdeleteEntry] = useState<{ rmaID: number; CRNumber: number; ContainerNum: number; ContainerType: string; GallonNum: number; Weight: number; Lot: string; } | null>(null);








  // Add these at the top of your component with other state variables
  const [localItemCounter, setLocalItemCounter] = useState(1);
  const [localRMACounter, setLocalRMACounter] = useState(1);







  const initialFormData: ReportObject = {
    CRNumber: 0,
    CRTitle: '',
    CRDescription: '',
    PossibleCause: '',
    Company: '',
    Product: '',
    TotalQuantityAffected: 0,
    Initiator: '',
    DateInitiated: null,
    RMARequired: false,
    SCARRequired: false,
    CRLevel: 0,
    CARequired: false,
    InitialComments: '',
    QCManagerSignOff: 0,
    ActionAssigned: 0,
    ActionAssignedDate: moment().format('YYYY-MM-DD'),
    InitialAnalysis: '',
    ProductDisposition: '',
    DispositionInstruction: '',
    FinalConclusion: '',
    TrendCode1: '',
    TrendCode2: '',
    TrendCode3: '',
    TrendCode4: '',
    TrendCode5: '',
    DateCompleted: null,
    QCManagerApprove: 0,
    QCDateApprove: null,
    InitiatorApprove: 0,
    InitiatorApproveDate: null,
    Approve3: 0,
    Approve3Date: null,
    Units: '',
    Location: '',
    ReturnCategory: '',
    DidNotFollowProcedure: false,
    ProcessChange: false,
    MISC: false,
    OpportunityForImprovement: false,
    SCARIssued: false,
    InternalAuditFinding: false,
    SerialNumber: "",
    MagniDetroitCR: "",
    ResearchProject: "",
    Department: "",
    CustomerFeedbackCategory: '',
    CustomerComplaint: '',
    AnalyticalReport: '',
    AuditFinding: false,
    ProposedAuditDate: null,
    DidAssigneeCompleteSatisfactorily: false,
    MeasurableFinding: false,
    Level1: false,
    Level2: false,
    Level3: false,
    ContinuousImprovement: false,
    LastUpdate: null,
    Visc: "",
    WPG: "",
    WtSlds: "",
    Color: "",
    ReclaimfromToteReturn: false,
    Sales: 0,
    ResearchSupported: false,
    Quarantine: false,
    ReturnCondition: "",
    ReturnPlant: "",
    Complaint: false,
    CorrectiveAction: {
      CPAInterimAction: '',
      CPARootCause: '',
      CPAActionTaken: '',
      CPAResponsibleSign: 0,
      CPAImplementDate: null,
      CPAFollowUP: '',
      CPAPrevent: '',
      CPACompletionDate: null,
      CPAQCManagerApprove: 0,
      CPAQCManagerDate: null,
      CPAInitiatorApprove: 0,
      CPAInitiatorDate: null,
      CPAApprove3: 0,
      CPAApprove3Date: null,
      IntOrExt: ''
    },
    ProcessChangeObject: {
      ID: 0,
      CRNumber: 0,
      ProcessChangeDescription: '',
      EquipmentImpacted: '',
      PartNumbersImpacted: '',
      StaffApproval: false,
      StaffApprovalDate: null,
      CustomerNotificationRequired: false,
      DateNotified: null,
      ProcessChangeLaunchDate: null,
      CustomerApprovalReceived: false,
      DateSent: null,
      DateApproved: null,
      LaunchFeedback: ''
    }
  };

  // const initialCAData: ReportCA = {
  //     CPAInterimAction: '',
  //     CPARootCause: '',
  //     CPAActionTaken: '',
  //     CPAResponsibleSign: 0,
  //     CPAImplementDate: null,
  //     CPAFollowUP: '',
  //     CPAPrevent: '',
  //     CPACompletionDate: null,
  //     CPAQCManagerApprove: 0,
  //     CPAQCManagerDate: null,
  //     CPAInitiatorApprove: 0,
  //     CPAInitiatorDate: null,
  //     CPAApprove3: 0,
  //     CPAApprove3Date: null,
  //     IntOrExt: '',
  // };




  const [reportErrors, setReportErrors] = useState<reportErrors>({
    //CRNumber: false,
    CRTitle: false,
  });


  const [formData, setFormData] = useState<ReportObject>(initialFormData);
  //const [caData, setcaData] = useState<ReportCA>(initialCAData);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);


  const setInit = async () => {
    const userobj: any = JSON.parse(localStorage.getItem('user') as string);
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      Createdby: userobj ? userobj.UserID : 0,
    }));
    // setcaData((prevCAData: any) => ({
    //     ...prevCAData,
    // }));
  }


  useEffect(() => {

    const fetchData = async () => {
      setTimeout(() => {
        setPageLoading(false);
      }, 1500);
      //await GetInitiators();
      await setInit();
      await GetProducts();
      await GetCRUsers();
      await GetCustomers();
      setUser(JSON.parse(localStorage.getItem('user') || '{}'));
      setFormData({ ...formData, Initiator: user ? user.FirstName + ' ' + user.LastName : '' })
      //setcaData({ ...caData })
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
        setWindowWidth(window.innerWidth);
      };
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };


    }

    fetchData();

  }, []);


  const HandleSubmit = async (e: any) => {
    e.preventDefault();
    formData.Initiator = user ? user.FirstName + ' ' + user.LastName : '';

    // Create the submission data with local items and RMAs
    const submitData = {
      ...formData,
      CRItems: items.map(item => ({
        Item: item.Item,
        Cost: item.Cost
      })),
      RMAs: RMAitems.map(rma => ({
        ContainerNum: rma.ContainerNum,
        ContainerType: rma.ContainerType,
        GallonNum: rma.GallonNum,
        Weight: rma.Weight,
        Lot: rma.Lot,

      })),
      CorrectiveAction: formData.CARequired
        ? formData.CorrectiveAction || {
          CRNumber: formData.CRNumber,
          CPAInterimAction: '',
          CPARootCause: '',
          CPAActionTaken: '',
          CPAResponsibleSign: 0,
          CPAImplementDate: null,
          CPAFollowUP: '',
          CPAPrevent: '',
          CPACompletionDate: null,
          CPAQCManagerApprove: 0,
          CPAQCManagerDate: null,
          CPAInitiatorApprove: 0,
          CPAInitiatorDate: null,
          CPAApprove3: 0,
          CPAApprove3Date: null,
          IntOrExt: '',
        }
        : null,
    };

    try {
      console.log("Submitting data:", submitData);
      const response = await apiCalls.AddReport(submitData);

      if (response) {
        nav('/conditionreports/');

      } else {
        toast.error("Failed to add report");
      }
    } catch (error) {
      console.error('Failed to add:', error);
      toast.error("Error submitting report: " + (error instanceof Error ? error.message : "Unknown error"));
    }
  };


  const [isDisabled, setIsDisabled] = useState(false);

  const handleChange = (event: any) => {
    const { name, value, type, checked } = event.target;
    const keys = name.split('.'); // Split the name into parts (e.g., ['CorrectiveAction', 'CPAInterimAction'])
    let updatedValue = type === 'checkbox' ? (checked ? true : false) : value.trim() === '' ? null : value;

    setFormData((prevFormData: any) => {
      if (keys.length > 1) {
        // If it's a nested field, update the nested object correctly
        return {
          ...prevFormData,
          [keys[0]]: {
            ...(prevFormData[keys[0]]),  // Type assertion to CorrectiveAction
            [keys[1]]: updatedValue,
          },
        };
      } else {
        // Otherwise, just update the direct field
        return {
          ...prevFormData,
          [name]: updatedValue,
        };
      }
    });
  };







  // const GetItems = async () => {
  //     // if (!formData.CRNumber) {
  //     //     console.log("CRNumber is required");
  //     //     // Optionally set some error state here to display a message
  //     //     return;
  //     // }
  //     const crnum = Number(data)
  //     try {
  //       // Fetch items from the API based on CRNumber
  //       const response = await apiCalls.GetItems(crnum);
  //       setItems(response.data); // Update the state with the returned data
  //       console.log("Items data:", response.data);
  //       console.log("Items data:", items);

  //     } catch (error) {
  //       console.error("Error retrieving items:", error);
  //       // Optionally set an error state to show an error message to the user
  //       // setError("Failed to retrieve items. Please try again later.");
  //     }
  //   };



  const HandleAddItem = async (e: any) => {
    e.preventDefault();

    // Check if the required fields are empty
    if (!costItem || !cost) {
      toast.error("Item and cost are required", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    // Create new item with a temporary local ID
    const newItem = {
      CostID: localItemCounter,
      CRNumber: 0, // This will be set on the backend when submitting
      Item: costItem,
      Cost: cost
    };

    // Add to local items array
    setItems([...items, newItem]);

    // Increment local counter
    setLocalItemCounter(localItemCounter + 1);

    // Close the modal after adding
    setShowModal(false);

    // Clear the form data after adding
    setCostItem('');
    setCost('');

  };











  const handleDeleteClick = (entry: { CostID: number; Item: string }) => {
    setDeleteEntry(entry);  // Store entry to delete
    setDeleteModal(true);  // Open delete confirmation modal
  };


  const HandleDeleteItem = async () => {
    if (!deleteEntry) return;

    // Filter out the item to delete from local array
    const updatedItems = items.filter(item => item.CostID !== deleteEntry.CostID);

    setItems(updatedItems);
    setDeleteModal(false);

  };
















  //adding RMA



  // const GetRMA = async () => {
  //   // if (!formData.CRNumber) {
  //   //     console.log("CRNumber is required");
  //   //     // Optionally set some error state here to display a message
  //   //     return;
  //   // }
  //   const crnum = Number(data)
  //   try {
  //     // Fetch items from the API based on CRNumber
  //     const response = await apiCalls.GetRMA(crnum);
  //     setRMAItems(response.data); // Update the state with the returned data
  //     console.log("RMA data:", response.data);
  //     console.log("RMA data:", RMAitems);

  //   } catch (error) {
  //     console.error("Error retrieving RMA:", error);
  //     // Optionally set an error state to show an error message to the user
  //     // setError("Failed to retrieve items. Please try again later.");
  //   }
  // };



  const HandleRMAAddItem = async (e: any) => {
    e.preventDefault();

    // Validate required fields
    if (!containerType) {
      toast.error("Container type is required");
      return;
    }

    // Create new RMA entry with a temporary local ID
    const newRMA = {
      rmaID: localRMACounter,
      CRNumber: 0, // This will be set on the backend when submitting
      ContainerNum: containerNum,
      ContainerType: containerType,
      GallonNum: gallonNum,
      Weight: weight,
      Lot: lot

    };

    // Add to local RMA items array
    setRMAItems([...RMAitems, newRMA]);

    // Increment local counter
    setLocalRMACounter(localRMACounter + 1);

    // Close the modal after adding
    setRMAShowModal(false);

    // Clear the form data
    setContainerNum(0);
    setContainerType('');
    setGallonNum(0);
    setWeight(0);
    setLot('');


  };










  const handleRMADeleteClick = (entry: { rmaID: number; CRNumber: number; ContainerNum: number; ContainerType: string; GallonNum: number; Weight: number; Lot: string; }) => {
    setRMAdeleteEntry(entry);  // Store entry to delete
    setRMAdeleteModal(true);  // Open delete confirmation modal
  };


  const HandleRMADeleteItem = async () => {
    if (!RMAdeleteEntry) return;

    // Filter out the RMA to delete from local array
    const updatedRMAs = RMAitems.filter(rma => rma.rmaID !== RMAdeleteEntry.rmaID);

    setRMAItems(updatedRMAs);
    setRMAdeleteModal(false);

  };

  const HandleProcessChangeAddItem = () => {
    // Close the modal after adding
    setProcessChangeModal(false);
    
    // The process change data is already being updated through the handleChange function
    // as the user types in the form fields, so we just need to close the modal
    
  };

  const GetProducts = async () => {
    //console.log(formData);
    const response = await apiCalls.GetProducts();
    const data = response.data;
    setProductOptions(data);
    console.log(productoptions);
  }

  const GetCRUsers = async () => {
    const response = await apiCalls.GetCRUsers();
    console.log(response.data); // Check the format of the data
    const data = response.data;
    setCRUsers(data); // Store list of { userID, FullName } objects
  };


  // const GetAssignedToUsers = async () => {
  //     //console.log(formData);
  //     const response = await apiCalls.GetAssignedToUsers();
  //     const data = response.data;
  //     setAssignedToUsers(data);
  //     console.log(assignedToUsers);
  // }

  const GetCustomers = async () => {
    try {
      const response = await apiCalls.GetCustomers(); // Wait for the Promise to resolve
      const data = response.data;
      setCustomerOptions(data);
      console.log(customerOptions);
    } catch (error) {
      console.error('Error fetching customers:', error);
    }
  };










  const containerStyle = {
    display: 'flex',
    justifyContent: '',
    //alignItems: 'center',
  };



  const textStyle = {
    //borderRadius: "5px",
    //padding: '0px',
    //justifyContent: 'center',
    //alignItems: 'center',
    width: '50%'

  };

  const dateStyle = {
    borderRadius: "5px",
    padding: '5px',
    //justifyContent: 'center'
    //alignItems: 'center'


  };

  const textareaStyle = {
    //borderRadius: "5px",
    //padding: '5px',
    //justifyContent: 'center'
    //alignItems: 'center'


  };


  const dropDownStyle = {
    borderRadius: "5px",
    padding: '5px',
    borderColor: 'black',
    border: '2px'
  }



  return (

    <div style={{ minHeight: "100vh", backgroundColor: '#0e226a', padding: "2rem", }}>
      <Header2 />


      <div style={{ width: "100%", margin: "0 auto", backgroundColor: "#efefef", padding: "3rem", borderRadius: "8px", color: "#ECF0F1" }}>
        <div className="row row-cols-1 row-cols-md-1 g-4" style={{ backgroundColor: "#efefef", }}>
          <div className="col" style={{
            backgroundColor: '#efefef'
          }}>
            <div className="card" style={{
              backgroundColor: '#efefef'
            }}>
              <div style={{ textAlign: "center", }}>
                <Button onClick={() => nav('/conditionreports/')} style={{ ...dropDownStyle, backgroundColor: "#0e226a", fontSize: "18px", border: "none", color: "#fff" }}>
                  Return to Condition Reports
                </Button>
              </div>
              <div className="card-body" style={{ backgroundColor: "#efefef", }}>
                <div className="container text-center response-text" style={{ marginBottom: 0, backgroundColor: "#efefef", }}>
                  <div className="mt-3" style={{ backgroundColor: "#efefef", }} >

                    <form onSubmit={HandleSubmit} style={{ backgroundColor: "#efefef", }}>
                      <ModalBody style={{ paddingLeft: '0px', maxHeight: '90%', backgroundColor: "#efefef", }}>
                        <h5 style={{ textAlign: "center", fontSize: 30 }}>  New Report </h5>
                        <h5 style={{ textAlign: "center", fontSize: 24 }}>  Initiator </h5>
                        <FormGroup
                                className="input-item"
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  width: '100%',
                                  justifyContent: 'flex-end',
                                  paddingRight: '150px',
                                  marginBottom: '20px'
                                }}
                              >
                                <Label
                                  for="Location"
                                  style={{
                                    width: '150px',
                                    textAlign: 'right',
                                    marginRight: '10px',
                                  }}
                                >
                                  Location
                                </Label>
                                <select
                                  value={formData.Location || ''}
                                  className="form-select"
                                  id="Location"
                                  name="Location"
                                  onChange={handleChange}
                                  disabled={isDisabled}
                                  style={{
                                    ...dropDownStyle,
                                    marginLeft: '20px',
                                    width: '250px',
                                  }}
                                >
                                  <option value="">Select an option</option>
                                  <option value="D">
                                    Detroit
                                  </option>
                                  <option value="K">
                                    Kentucky
                                  </option>

                                </select>
                              </FormGroup>

                        <Row className="mx-auto justify-content-center align-items-center" style={{ marginBottom: '25px', }}>




                          <div
                            className="form-section-container"
                            style={{

                              padding: '10px',
                              borderRadius: '8px',
                              maxWidth: '300px',
                              marginLeft: '00px',

                            }}
                          >

                            <FormGroup style={{ display: 'flex', flexDirection: 'column' }}>
                              <Label for="CRTitle" style={{}}>Report Name *</Label>
                              <Input
                                id="CRTitle"
                                name="CRTitle"
                                type="text"
                                value={formData.CRTitle === null ? '' : formData.CRTitle}
                                onChange={handleChange}
                                style={{ width: '100%', }}
                              />
                            </FormGroup>

                            <FormGroup style={{ display: 'flex', flexDirection: 'column' }}>
                              <Label for="DateCreated" style={{}}>Date Created</Label>
                              <Input
                                id="DateCreated"
                                name="DateCreated"
                                type="date"
                                value={moment().format('YYYY-MM-DD')}
                                onChange={handleChange}
                                disabled={true}
                                style={{ width: '100%' }}
                              />
                            </FormGroup>

                            <FormGroup style={{ display: 'flex', flexDirection: 'column' }}>
                              <Label for="SerialNumber" style={{}}>Serial Number</Label>
                              <Input
                                id="SerialNumber"
                                name="SerialNumber"
                                type="text"
                                value={formData.SerialNumber === null ? '' : formData.SerialNumber}
                                onChange={handleChange}
                                disabled={isDisabled}
                                style={{ width: '100%', }}
                              />
                            </FormGroup>
                          </div>







                          <div className="form-section-container" style={{ padding: '15px', borderRadius: '8px', maxWidth: '300px', marginLeft: '00px', }}>

                            <FormGroup style={{ display: 'flex', flexDirection: 'column', marginBottom: '0px' }}>
                              <Label for="MagniDetroitCR">Magni Detroit CR</Label>
                              <Input
                                id="MagniDetroitCR"
                                name="MagniDetroitCR"
                                type="text"
                                value={formData.MagniDetroitCR === null ? '' : formData.MagniDetroitCR}
                                onChange={handleChange}
                                disabled={isDisabled}
                                style={{ width: '100%' }}
                              />
                            </FormGroup>

                            <FormGroup style={{ display: 'flex', flexDirection: 'column', marginBottom: '0px' }}>
                              <Label for="AnalyticalReport">Analytical Report</Label>
                              <Input
                                id="AnalyticalReport"
                                name="AnalyticalReport"
                                type="text"
                                value={formData.AnalyticalReport === null ? '' : formData.AnalyticalReport}
                                onChange={handleChange}
                                disabled={isDisabled}
                                style={{ width: '100%' }}
                              />
                            </FormGroup>

                            <FormGroup style={{ display: 'flex', flexDirection: 'column' }}>
                              <Label for="Department">Department</Label>
                              <Input
                                id="Department"
                                name="Department"
                                type="text"
                                value={formData.Department === null ? '' : formData.Department}
                                onChange={handleChange}
                                disabled={isDisabled}
                                style={{ width: '100%' }}
                              />
                            </FormGroup>
                          </div>


                          <div
                            className="form-section-container"
                            style={{

                              padding: '10px',
                              borderRadius: '8px',
                              maxWidth: '160px',
                              marginLeft: '00px',
                              /*transform: 'scale(0.9)',  Shrinks everything proportionally */
                              //transformOrigin: 'top left'
                            }}
                          >
                            <h5 style={{ textAlign: 'center', marginBottom: '10px', }}>Non-Conformance Level</h5>

                            <FormGroup style={{ display: 'flex', flexDirection: 'column' }}>

                              <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <input
                                    id="Level1"
                                    name="Level1"
                                    type="checkbox"
                                    checked={formData.Level1}
                                    onChange={handleChange}
                                    style={{ width: '20px', height: '20px' }}
                                  />
                                  <label htmlFor="Level1" style={{ marginLeft: '8px' }}>Level 1</label>
                                </div>

                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <input
                                    id="Level2"
                                    name="Level2"
                                    type="checkbox"
                                    checked={formData.Level2}
                                    onChange={handleChange}
                                    style={{ width: '20px', height: '20px' }}
                                  />
                                  <label htmlFor="Level2" style={{ marginLeft: '8px' }}>Level 2</label>
                                </div>

                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <input
                                    id="Level3"
                                    name="Level3"
                                    type="checkbox"
                                    checked={formData.Level3}
                                    onChange={handleChange}
                                    style={{ width: '20px', height: '20px' }}
                                  />
                                  <label htmlFor="Level3" style={{ marginLeft: '8px' }}>Level 3</label>
                                </div>
                              </div>
                            </FormGroup>
                          </div>



                          <div className="checkbox-container" style={{ border: '1px solid #000000', padding: '15px', borderRadius: '5px', maxWidth: '530px', transform: 'scale(0.9)', }}>
                            <Row>
                              <Col md={6}>
                                <FormGroup check className="checkbox-item" style={{ display: 'flex', alignItems: 'center' }}>
                                  <Label check>
                                    <Input type="checkbox" id="DidNotFollowProcedure" name="DidNotFollowProcedure" checked={formData.DidNotFollowProcedure} onChange={handleChange} disabled={isDisabled} />
                                    {' '}Did Not Follow Procedure
                                  </Label>
                                </FormGroup>
                                <FormGroup check className="checkbox-item" style={{ display: 'flex', alignItems: 'center' }}>
                                  <Label check>
                                    <Input type="checkbox" id="AuditFinding" name="AuditFinding" checked={formData.AuditFinding} onChange={handleChange} disabled={isDisabled} />
                                    {' '}Audit Finding
                                  </Label>
                                </FormGroup>
                                <FormGroup check className="checkbox-item" style={{ display: 'flex', alignItems: 'center' }}>
                                  <Label check>
                                    <Input type="checkbox" id="SCARIssued" name="SCARIssued" checked={formData.SCARIssued} onChange={handleChange} disabled={isDisabled} />
                                    {' '}SCAR Issued
                                  </Label>
                                </FormGroup>
                                <FormGroup check className="checkbox-item" style={{ display: 'flex', alignItems: 'center' }}>
                                  <Label check>
                                    <Input type="checkbox" id="OpportunityForImprovement" name="OpportunityForImprovement" checked={formData.OpportunityForImprovement} onChange={handleChange} disabled={isDisabled} />
                                    {' '}Opportunity for Improvement
                                  </Label>
                                </FormGroup>

                                <FormGroup check className="checkbox-item" style={{ display: 'flex', alignItems: 'center' }}>
                                  <Label check>
                                    <Input type="checkbox" id="ProcessChange" name="ProcessChange" checked={formData.ProcessChange} onChange={handleChange} disabled={isDisabled} />
                                    {' '}Process Change
                                  </Label>

                                </FormGroup>
                                <Button 
                                    color="primary" 
                                    size="sm" 
                                    onClick={() => setProcessChangeModal(true)}
                                    style={{ marginLeft: '10px', backgroundColor: '#0e226a' }}
                                    disabled={!formData.ProcessChange}
                                  >
                                    Process Change
                                  </Button>
                              </Col>
                              <Col md={6}>
                              <FormGroup check className="checkbox-item" style={{ display: 'flex', alignItems: 'center' }}>
                                  <Label check>
                                    <Input type="checkbox" id="InternalAuditFinding" name="InternalAuditFinding" checked={formData.InternalAuditFinding} onChange={handleChange} disabled={isDisabled} />
                                    {' '}Internal Audit Finding
                                  </Label>
                                </FormGroup>
                                <FormGroup check className="checkbox-item" style={{ display: 'flex', alignItems: 'center' }}>
                                  <Label check>
                                    <Input type="checkbox" id="MISC" name="MISC" checked={formData.MISC} onChange={handleChange} disabled={isDisabled} />
                                    {' '}MISC
                                  </Label>
                                </FormGroup>
                                <FormGroup check className="checkbox-item" style={{ display: 'flex', alignItems: 'center' }}>
                                  <Label check>
                                    <Input type="checkbox" id="MeasurableFinding" name="MeasurableFinding" checked={formData.MeasurableFinding} onChange={handleChange} disabled={isDisabled} />
                                    {' '}Measurable Finding
                                  </Label>
                                </FormGroup>
                                <FormGroup check className="checkbox-item" style={{ display: 'flex', alignItems: 'center' }}>
                                  <Label check>
                                    <Input type="checkbox" id="Quarantine" name="Quarantine" checked={formData.Quarantine} onChange={handleChange} disabled={isDisabled} />
                                    {' '}Quarantine
                                  </Label>
                                </FormGroup>
                                <FormGroup check className="checkbox-item" style={{ display: 'flex', alignItems: 'center' }}>
                                  <Label check>
                                    <Input type="checkbox" id="ReclaimfromToteReturn" name="ReclaimfromToteReturn" checked={formData.ReclaimfromToteReturn} onChange={handleChange} disabled={isDisabled} />
                                    {' '}Reclaim from Tote Return
                                  </Label>
                                </FormGroup>
                                <FormGroup check className="checkbox-item" style={{ display: 'flex', alignItems: 'center' }}>
                                  <Label check>
                                    <Input type="checkbox" id="ResearchSupported" name="ResearchSupported" checked={formData.ResearchSupported} onChange={handleChange} disabled={isDisabled} />
                                    {' '}Research Supported
                                  </Label>
                                </FormGroup>
                              </Col>
                            </Row>
                          </div>

                        </Row>



















                        <Row className="mt-3" style={{ display: 'flex', flexWrap: 'wrap' }}>
                          <Col sm={4}>
                            <FormGroup>
                              <Label for="Desc">Description *</Label>
                              <Input
                                id="CRDescription"
                                type="textarea"
                                name="CRDescription"
                                value={formData.CRDescription || ''}
                                onChange={handleChange}
                                disabled={isDisabled}
                                style={{ width: '100%', height: '200px' }}
                              />
                            </FormGroup>
                          </Col>

                          <Col sm={4}>
                            <FormGroup>
                              <Label for="CFdesc">List the Possible Causes/Failure Mechanisms *</Label>
                              <Input
                                id="PossibleCause"
                                type="textarea"
                                name="PossibleCause"
                                value={formData.PossibleCause || ''}
                                onChange={handleChange}
                                disabled={isDisabled}
                                style={{ width: '100%', height: '200px' }}
                              />
                            </FormGroup>
                          </Col>

                          <Col sm={4}>
                            <div
                              className="checkbox-container"
                              style={{

                                padding: '15px',
                                borderRadius: '8px',
                                width: '100%',
                                marginTop: '-32px'
                              }}
                            >
                              <h5 style={{ textAlign: 'center', marginBottom: '15px' }}>
                                Only Required for External Reject or Customer Concern
                              </h5>

                              <FormGroup check style={{ marginBottom: '15px', display: 'flex', justifyContent: 'center' }}>
                                <Label check>
                                  <Input
                                    type="checkbox"
                                    id="Complaint"
                                    name="Complaint"
                                    checked={formData.Complaint}
                                    onChange={handleChange}
                                    disabled={isDisabled}
                                  />
                                  {' '}Formal Complaint Submitted?
                                </Label>
                              </FormGroup>

                              <FormGroup
                                className="input-item"
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  width: '100%',
                                }}
                              >
                                <Label
                                  for="CustomerFeedbackCategory"
                                  style={{
                                    width: '150px',
                                    textAlign: 'right',
                                    marginRight: '10px',
                                  }}
                                >
                                  Customer Feedback Category
                                </Label>
                                <select
                                  value={formData.CustomerFeedbackCategory || ''}
                                  className="form-select"
                                  id="CustomerFeedbackCategory"
                                  name="CustomerFeedbackCategory"
                                  onChange={handleChange}
                                  disabled={isDisabled}
                                  style={{
                                    ...dropDownStyle,
                                    marginLeft: '20px',
                                    width: '250px',
                                  }}
                                >
                                  <option value="">Select an option</option>
                                  <option value="Prod's performance, and other design features">
                                    Prod's performance, and other design features
                                  </option>
                                  <option value="Reliability and dependability of product">
                                    Reliability and dependability of product
                                  </option>
                                  <option value="Packaging">Packaging</option>
                                  <option value="Distribution and Delivery">
                                    Distribution and Delivery
                                  </option>
                                  <option value="Product Information">Product Information</option>
                                  <option value="Availability">Availability</option>
                                  <option value="Sales and Order Processing">
                                    Sales and Order Processing
                                  </option>
                                  <option value="After-Sales Support and Service">
                                    After-Sales Support and Service
                                  </option>
                                  <option value="Price">Price</option>
                                  <option value="Customer damaged at their site asked for repair">
                                    Customer damaged at their site asked for repair
                                  </option>
                                </select>
                              </FormGroup>

                              <FormGroup
                                className="input-item"
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  width: '100%',
                                }}
                              >
                                <Label
                                  for="ReturnCategory"
                                  style={{
                                    width: '150px',
                                    textAlign: 'right',
                                    marginRight: '10px',
                                  }}
                                >
                                  Return Category
                                </Label>
                                <select
                                  className="form-select"
                                  id="ReturnCategory"
                                  name="ReturnCategory"
                                  value={formData.ReturnCategory || ''}
                                  onChange={handleChange}
                                  disabled={isDisabled}
                                  style={{
                                    ...dropDownStyle,
                                    marginLeft: '20px',
                                    width: '250px',
                                  }}
                                >
                                  <option value="">Select an option</option>
                                  <option value="Unsatisfactory Product">
                                    Unsatisfactory Product
                                  </option>
                                  <option value="Customer Accommodation">
                                    Customer Accommodation
                                  </option>
                                  <option value="Obsolete Inventory">Obsolete Inventory</option>
                                  <option value="Completion of Trial">Completion of Trial</option>
                                  <option value="Customer Feedback">Customer Feedback</option>
                                  <option value="Customer Complaint">Customer Complaint</option>
                                </select>
                              </FormGroup>

                              <FormGroup
                                className="input-item"
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  width: '100%',
                                }}
                              >
                                <Label
                                  for="CustomerComplaint"
                                  style={{
                                    width: '150px',
                                    textAlign: 'right',
                                    marginRight: '10px',
                                  }}
                                >
                                  Customer Complaint
                                </Label>
                                <select
                                  value={formData.CustomerComplaint || ''}
                                  className="form-select"
                                  id="CustomerComplaint"
                                  name="CustomerComplaint"
                                  onChange={handleChange}
                                  disabled={isDisabled}
                                  style={{
                                    ...dropDownStyle,
                                    marginLeft: '20px',
                                    width: '250px',
                                  }}
                                >
                                  <option value="">Select an option</option>
                                  <option value="Non conforming/ Unsatisfactory Product">
                                    Non conforming/ Unsatisfactory Product
                                  </option>
                                  <option value="Product Labeling Problem">
                                    Product Labeling Problem
                                  </option>
                                  <option value="Product Damaged in Delivery">
                                    Product Damaged in Delivery
                                  </option>
                                  <option value="Insufficient or Inadequate Quality Records">
                                    Insufficient or Inadequate Quality Records
                                  </option>
                                  <option value="Wrong Product or Quantity Shipped">
                                    Wrong Product or Quantity Shipped
                                  </option>
                                  <option value="Late Delivery to Customer">
                                    Late Delivery to Customer
                                  </option>
                                  <option value="Complaints Regarding Productivity">
                                    Complaints Regarding Productivity
                                  </option>
                                  <option value="Problems with Communication and Responsiveness">
                                    Problems with Communication and Responsiveness
                                  </option>
                                  <option value="Complaints Regarding Business Practices">
                                    Complaints Regarding Business Practices
                                  </option>
                                </select>
                              </FormGroup>
                            </div>
                          </Col>

                        </Row>









                        <Row className="mt-3" style={{ width: '1500px' }}>


                          <div className="form-section-container" style={{ padding: '15px', borderRadius: '8px', maxWidth: '320px', marginLeft: '100px' }}>

                            <FormGroup style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
                              <Label for="Company">Company Name *</Label>
                              <select
                                value={formData.Company}
                                className="form-select"
                                id="Company"
                                name="Company"
                                onChange={handleChange}
                                style={{ width: '100%' }}
                              >
                                <option value="">Select an option</option>
                                {customerOptions.map((option: any, index) => (
                                  <option key={index} value={option.type}>
                                    {option.type}
                                  </option>
                                ))}
                              </select>
                            </FormGroup>

                            <FormGroup style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
                              <Label for="Product">Product ID *</Label>
                              <select
                                value={formData.Product}
                                className="form-select"
                                id="Product"
                                name="Product"
                                onChange={handleChange}
                                style={{ width: '100%' }}
                              >
                                <option value="">Select an option</option>
                                <option value="NA">NA</option>
                                {productoptions.map((option: any, index) => (
                                  <option key={index} value={option.type}>
                                    {option.type}
                                  </option>
                                ))}
                              </select>
                            </FormGroup>

                            <FormGroup style={{ display: 'flex', flexDirection: 'column' }}>
                              <Label for="Initiator">Initiator *</Label>
                              <Input
                                value={user ? user.FirstName + ' ' + user.LastName : ''}
                                type="text"
                                id="Initiator"
                                name="Initiator"
                                onChange={handleChange}
                                disabled={true}
                                style={{ width: '100%' }}
                              />
                            </FormGroup>
                          </div>









                          {/* Right Column - Table & Add Button */}
                          <Col md={8} style={{ marginTop: '50px', marginLeft: '80px' }}>
                            <Table striped bordered hover className="mt-2" style={{ width: '500px' }}>
                              <thead>
                                <tr>
                                  <th>Item</th>
                                  <th>Cost</th>
                                  <th>Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {items.map((entry, index) => (
                                  <tr key={index}>
                                    <td>{entry.Item}</td>
                                    <td>{entry.Cost}</td>

                                    <td>

                                      <Button
                                        color="danger"
                                        size="sm"
                                        onClick={() => {
                                          setDeleteEntry(entry);
                                          setDeleteModal(true);
                                        }}
                                      >
                                        Delete
                                      </Button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                            <Button
                              color="primary"
                              style={{ backgroundColor: '#0e226a', marginRight: '500px' }}
                              onClick={() => setShowModal(true)}
                            >
                              Add Item
                            </Button>
                          </Col>

                          <Modal isOpen={deleteModal} toggle={() => setDeleteModal(false)} centered>
                            <ModalHeader toggle={() => setDeleteModal(false)} style={{backgroundColor: '#efefef'}}>Confirm Deletion</ModalHeader>
                            <ModalBody style={{backgroundColor: '#efefef'}}>
                              {deleteEntry && <p>Are you sure you want to delete "{deleteEntry.Item}"?</p>}
                            </ModalBody>
                            <ModalFooter>
                              <Button color="secondary" onClick={() => setDeleteModal(false)}>Cancel</Button>
                              <Button color="danger" onClick={HandleDeleteItem}>Yes, Delete</Button>
                            </ModalFooter>
                          </Modal>




                          {/* Modal for Adding Cost */}
                          <Modal isOpen={showModal} toggle={() => setShowModal(false)} centered>
                            <ModalHeader toggle={() => setShowModal(false)} style={{backgroundColor: '#efefef'}}>Add Cost</ModalHeader>
                            <ModalBody style={{backgroundColor: '#efefef'}}>
                              <Row className="mb-3">
                                <Col>
                                  <Input
                                    type="text"
                                    placeholder="Item"
                                    value={costItem}
                                    onChange={(e) => setCostItem(e.target.value)}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <Input
                                    type="text"
                                    placeholder="Cost"
                                    value={cost}
                                    onChange={(e) => setCost(e.target.value)}
                                  />
                                </Col>
                              </Row>
                            </ModalBody>
                            <ModalFooter>
                              <Button color="secondary" onClick={() => setShowModal(false)}>Cancel</Button>
                              <Button color="primary" onClick={HandleAddItem}>Add</Button>
                            </ModalFooter>
                          </Modal>















                          {/* Right Column - Table & Add Button */}
                          <Col md={7} style={{ marginTop: '50px', marginLeft: '0px' }}>
                            <h4 style={{ color: "#0e226a", marginBottom: "15px" }}>Return Material Authorization</h4>

                            <Table striped bordered hover className="mt-2" style={{ width: '100%' }}>
                              <thead>
                                <tr>
                                  <th># Containers</th>
                                  <th>Container Type</th>
                                  <th># Gallons</th>
                                  <th>Weight (LB)</th>
                                  <th>Lot Number</th>
                                  <th>Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {RMAitems.map((entry, index) => (
                                  <tr key={index}>
                                    <td>{entry.ContainerNum}</td>
                                    <td>{entry.ContainerType}</td>
                                    <td>{entry.GallonNum}</td>
                                    <td>{entry.Weight}</td>
                                    <td>{entry.Lot}</td>

                                    <td>
                                      <Button color="danger" size="sm" onClick={() => handleRMADeleteClick(entry)}>Delete</Button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                            <Button color="primary" style={{ backgroundColor: '#0e226a', marginRight: '00px' }} onClick={() => setRMAShowModal(true)}>Add RMA</Button>
                          </Col>






                          <Modal isOpen={RMAshowModal} toggle={() => setRMAShowModal(false)} centered>
                            <ModalHeader toggle={() => setRMAShowModal(false)} style={{backgroundColor: '#efefef'}}>Add RMA</ModalHeader>
                            <ModalBody style={{backgroundColor: '#efefef'}}>
                              <Row className="mb-3">
                                <Col>
                                  <Label># Containers</Label>
                                  <Input
                                    type="number"
                                    value={containerNum}
                                    onChange={(e) => setContainerNum(Number(e.target.value))}
                                  />
                                </Col>
                                <Col>
                                  <Label>Container Type</Label>
                                  <Input
                                    type="select"
                                    value={containerType}
                                    onChange={(e) => setContainerType(e.target.value)}
                                  >
                                    <option value="">Select type...</option>
                                    <option value="Drum">Drum</option>
                                    <option value="Tote">Tote</option>
                                    <option value="Pail">Pail</option>
                                  </Input>
                                </Col>
                              </Row>
                              <Row className="mb-3">
                                <Col>
                                  <Label># Gallons</Label>
                                  <Input
                                    type="number"
                                    value={gallonNum}
                                    onChange={(e) => setGallonNum(Number(e.target.value))}
                                  />
                                </Col>
                                <Col>
                                  <Label>Weight (LB)</Label>
                                  <Input
                                    type="number"
                                    value={weight}
                                    onChange={(e) => setWeight(Number(e.target.value))}
                                  />
                                </Col>
                              </Row>
                              <Row className="mb-3">
                                <Col>
                                  <Label>Lot Number</Label>
                                  <Input
                                    type="text"
                                    value={lot}
                                    onChange={(e) => setLot(e.target.value)}
                                  />
                                </Col>
                              </Row>
                            </ModalBody>
                            <ModalFooter>
                              <Button color="secondary" onClick={() => setRMAShowModal(false)}>Cancel</Button>
                              <Button color="primary" onClick={HandleRMAAddItem}>Add</Button>
                            </ModalFooter>
                          </Modal>



                          <Modal isOpen={RMAdeleteModal} toggle={() => setRMAdeleteModal(false)} centered>
                            <ModalHeader toggle={() => setRMAdeleteModal(false)} style={{backgroundColor: '#efefef'}}>Confirm Deletion</ModalHeader>
                            <ModalBody style={{backgroundColor: '#efefef'}}>
                              {RMAdeleteEntry && <p>Are you sure you want to delete this RMA entry?</p>}
                            </ModalBody>
                            <ModalFooter>
                              <Button color="secondary" onClick={() => setRMAdeleteModal(false)}>Cancel</Button>
                              <Button color="danger" onClick={HandleRMADeleteItem}>Yes, Delete</Button>
                            </ModalFooter>
                          </Modal>

                          {/* Process Change Modal */}
                          <Modal isOpen={processChangeModal} toggle={() => setProcessChangeModal(false)} centered size="lg">
                            <ModalHeader toggle={() => setProcessChangeModal(false)} style={{backgroundColor: '#efefef'}}>Process Change Details</ModalHeader>
                            <ModalBody style={{backgroundColor: '#efefef'}}>
                              <Row>
                                <Col md={12}>
                                  <FormGroup>
                                    <Label for="ProcessChangeDescription">Process Change Description</Label>
                                    <Input
                                      type="textarea"
                                      id="ProcessChangeObject.ProcessChangeDescription"
                                      name="ProcessChangeObject.ProcessChangeDescription"
                                      value={formData.ProcessChangeObject?.ProcessChangeDescription || ''}
                                      onChange={handleChange}
                                      style={{ height: '100px' }}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={6}>
                                  <FormGroup>
                                    <Label for="EquipmentImpacted">Equipment Impacted</Label>
                                    <Input
                                      type="text"
                                      id="ProcessChangeObject.EquipmentImpacted"
                                      name="ProcessChangeObject.EquipmentImpacted"
                                      value={formData.ProcessChangeObject?.EquipmentImpacted || ''}
                                      onChange={handleChange}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md={6}>
                                  <FormGroup>
                                    <Label for="PartNumbersImpacted">Part Numbers Impacted</Label>
                                    <Input
                                      type="text"
                                      id="ProcessChangeObject.PartNumbersImpacted"
                                      name="ProcessChangeObject.PartNumbersImpacted"
                                      value={formData.ProcessChangeObject?.PartNumbersImpacted || ''}
                                      onChange={handleChange}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={6}>
                                  <FormGroup>
                                    <Label for="StaffApproval">Staff Approval</Label>
                                    <div className="d-flex align-items-center">
                                      <Input
                                        type="checkbox"
                                        id="ProcessChangeObject.StaffApproval"
                                        name="ProcessChangeObject.StaffApproval"
                                        checked={formData.ProcessChangeObject?.StaffApproval || false}
                                        onChange={handleChange}
                                      />
                                    </div>
                                  </FormGroup>
                                </Col>
                                <Col md={6}>
                                  <FormGroup>
                                    <Label for="StaffApprovalDate">Staff Approval Date</Label>
                                    <Input
                                      type="date"
                                      id="ProcessChangeObject.StaffApprovalDate"
                                      name="ProcessChangeObject.StaffApprovalDate"
                                      value={formData.ProcessChangeObject?.StaffApprovalDate ? moment(formData.ProcessChangeObject.StaffApprovalDate).format('YYYY-MM-DD') : ''}
                                      onChange={handleChange}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={6}>
                                  <FormGroup>
                                    <Label for="CustomerNotificationRequired">Customer Notification Required</Label>
                                    <div className="d-flex align-items-center">
                                      <Input
                                        type="checkbox"
                                        id="ProcessChangeObject.CustomerNotificationRequired"
                                        name="ProcessChangeObject.CustomerNotificationRequired"
                                        checked={formData.ProcessChangeObject?.CustomerNotificationRequired || false}
                                        onChange={handleChange}
                                      />
                                    </div>
                                  </FormGroup>
                                </Col>
                                <Col md={6}>
                                  <FormGroup>
                                    <Label for="DateNotified">Date Notified</Label>
                                    <Input
                                      type="date"
                                      id="ProcessChangeObject.DateNotified"
                                      name="ProcessChangeObject.DateNotified"
                                      value={formData.ProcessChangeObject?.DateNotified ? moment(formData.ProcessChangeObject.DateNotified).format('YYYY-MM-DD') : ''}
                                      onChange={handleChange}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={6}>
                                  <FormGroup>
                                    <Label for="ProcessChangeLaunchDate">Process Change Launch Date</Label>
                                    <Input
                                      type="date"
                                      id="ProcessChangeObject.ProcessChangeLaunchDate"
                                      name="ProcessChangeObject.ProcessChangeLaunchDate"
                                      value={formData.ProcessChangeObject?.ProcessChangeLaunchDate ? moment(formData.ProcessChangeObject.ProcessChangeLaunchDate).format('YYYY-MM-DD') : ''}
                                      onChange={handleChange}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md={6}>
                                  <FormGroup>
                                    <Label for="CustomerApprovalReceived">Customer Approval Received</Label>
                                    <div className="d-flex align-items-center">
                                      <Input
                                        type="checkbox"
                                        id="ProcessChangeObject.CustomerApprovalReceived"
                                        name="ProcessChangeObject.CustomerApprovalReceived"
                                        checked={formData.ProcessChangeObject?.CustomerApprovalReceived || false}
                                        onChange={handleChange}
                                      />
                                    </div>
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={6}>
                                  <FormGroup>
                                    <Label for="DateSent">Date Sent</Label>
                                    <Input
                                      type="date"
                                      id="ProcessChangeObject.DateSent"
                                      name="ProcessChangeObject.DateSent"
                                      value={formData.ProcessChangeObject?.DateSent ? moment(formData.ProcessChangeObject.DateSent).format('YYYY-MM-DD') : ''}
                                      onChange={handleChange}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md={6}>
                                  <FormGroup>
                                    <Label for="DateApproved">Date Approved</Label>
                                    <Input
                                      type="date"
                                      id="ProcessChangeObject.DateApproved"
                                      name="ProcessChangeObject.DateApproved"
                                      value={formData.ProcessChangeObject?.DateApproved ? moment(formData.ProcessChangeObject.DateApproved).format('YYYY-MM-DD') : ''}
                                      onChange={handleChange}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={12}>
                                  <FormGroup>
                                    <Label for="LaunchFeedback">Launch Feedback</Label>
                                    <Input
                                      type="textarea"
                                      id="ProcessChangeObject.LaunchFeedback"
                                      name="ProcessChangeObject.LaunchFeedback"
                                      value={formData.ProcessChangeObject?.LaunchFeedback || ''}
                                      onChange={handleChange}
                                      style={{ height: '100px' }}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                            </ModalBody>
                            <ModalFooter>
                              <Button color="secondary" onClick={() => setProcessChangeModal(false)}>Close</Button>
                              <Button color="primary" onClick={HandleProcessChangeAddItem}>Add</Button>
                            </ModalFooter>
                          </Modal>

                          <div className="form-section-container" style={{ padding: '15px', borderRadius: '8px', maxWidth: '320px', marginLeft: '100px', marginTop: '50px' }}>

                            <FormGroup style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
                              <Label for="Company">Return Condition</Label>
                              <select
                                value={formData.ReturnCondition === null ? '' : formData.ReturnCondition} // Set the value of the dropdown to the value in state
                                className="form-select"
                                id="ReturnCondition"
                                name="ReturnCondition"
                                onChange={handleChange}
                                style={{ width: '100%' }}
                              >
                                <option value="">Select an option</option>
                                <option value="EVAL">EVAL</option>
                                <option value="CREDIT">CREDIT</option>
                                <option value="NOCREDIT">NOCREDIT</option>
                                <option value="REMIX">REMIX</option>
                                <option value="UNKNOWN">UNKNOWN</option>

                              </select>
                            </FormGroup>

                            <FormGroup style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
                              <Label for="Product">Return Plant</Label>
                              <select
                                value={formData.ReturnPlant === null ? '' : formData.ReturnPlant}
                                className="form-select"
                                id="ReturnPlant"
                                name="ReturnPlant"
                                onChange={handleChange}
                                style={{ width: '100%' }}
                              >
                                <option value="">Select an option</option>
                                <option value="Detroit">Detroit</option>
                                <option value="Kentucky">Kentucky</option>
                              </select>
                            </FormGroup>


                          </div>






                        </Row>














                        <h5 style={{ textAlign: "center", fontSize: 24, marginTop: '80px' }}>  Q.A. Manager </h5>

                        <Row className="mt-3 justify-content-center" style={{ marginBottom: '25px' }}>
                          <Col sm={3} className="d-flex justify-content-center">
                            <FormGroup style={{ width: '100%' }}>
                              <Label for="Level" className="text-center">Level *</Label>
                              <select
                                value={formData.CRLevel}
                                className="form-select"
                                id="CRLevel"
                                name="CRLevel"
                                onChange={handleChange}
                              >
                                <option value="0">Select an option</option>
                                <option value="1">Level 1 - Multidisciplinary Corrective Action</option>
                                <option value="2">Level 2 - Single Evaluator Corrective Action</option>
                                <option value="3">Level 3 - Disposition Determination</option>
                                <option value="4">Level 4 - Trend Determination</option>
                                <option value="5">Level 5 - Customer Accommodation</option>
                                <option value="6">Level 6 - unknown</option>
                                <option value="7">S.C.A.R.</option>
                              </select>
                            </FormGroup>
                          </Col>

                          <Col sm={3} className="d-flex justify-content-center">
                            <FormGroup style={{ width: '100%' }}>
                              <Label for="QAAuthSign" className="text-center">Signature</Label>
                              <select
                                id="QCManagerSignOff"
                                name="QCManagerSignOff"
                                value={formData.QCManagerSignOff === null ? '' : formData.QCManagerSignOff}
                                onChange={handleChange}
                                disabled={isDisabled}
                                className="form-select"
                              >
                                <option value="">Select an option</option>
                                {CRUsers.map((option, index) => (
                                  <option key={index} value={option.userID}>
                                    {option.FullName}
                                  </option>
                                ))}
                              </select>
                            </FormGroup>
                          </Col>

                          <Col sm={3} className="d-flex justify-content-center">
                            <FormGroup style={{ width: '100%' }}>
                              <Label for="Sales" className="text-center">Sales</Label>
                              <Input
                                type="text"
                                id="Sales"
                                name="Sales"
                                value={formData.Sales === null ? '' : formData.Sales}
                                onChange={handleChange}
                                disabled={isDisabled}
                                className="form-control"
                              />
                            </FormGroup>
                          </Col>
                        </Row>


                        <Row className="mx-auto justify-content-center" style={{ marginTop: '20px' }}>
                          <Col sm={3} className="d-flex flex-column align-items-center">
                            <FormGroup style={{ width: '100%', textAlign: 'center' }}>
                              <Label for="AssignedTo">Action Assigned To *</Label>
                              <select
                                value={formData.ActionAssigned}
                                className="form-select"
                                id="ActionAssigned"
                                name="ActionAssigned"
                                onChange={handleChange}
                                style={{ ...dropDownStyle, margin: '0 auto' }}
                              >
                                <option value="">Select an option</option>
                                {CRUsers.map((option, index) => (
                                  <option key={index} value={option.userID}>
                                    {option.FullName}
                                  </option>
                                ))}
                              </select>
                            </FormGroup>
                          </Col>

                          <Col sm={3} className="d-flex flex-column align-items-center">
                            <FormGroup style={{ width: '100%', textAlign: 'center' }}>
                              <Label for="ActAssignDate">Assigned Date *</Label>
                              <Input
                                id="ActionAssignedDate"
                                name="ActionAssignedDate"
                                value={formData.ActionAssignedDate === null ? '' : moment(formData.ActionAssignedDate).format('YYYY-MM-DD')}
                                onChange={handleChange}
                                type="date"
                                //disabled={true}
                                style={{ margin: '0 auto' }}
                              />
                            </FormGroup>
                          </Col>

                          <Col sm={3} className="d-flex flex-column align-items-center">
                            <FormGroup style={{ width: '100%', textAlign: 'center' }}>
                              <Label for="ResearchProject">Research Project #</Label>
                              <Input
                                id="ResearchProject"
                                name="ResearchProject"
                                value={formData.ResearchProject === null ? '' : formData.ResearchProject}
                                onChange={handleChange}
                                type="text"
                                disabled={isDisabled}
                                style={{ margin: '0 auto' }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>


                        <Row className="mx-auto justify-content-center" style={{ marginTop: '20px' }}>
                          <Col sm={3}>
                            <FormGroup>
                              <Label for="Basis" style={{ marginTop: '20px', marginLeft: '-0px' }}>Comments *</Label>
                              <Input
                                id="InitialComments"
                                name="InitialComments"
                                value={formData.InitialComments}
                                onChange={handleChange}
                                type="textarea"
                                disabled={isDisabled}
                                style={{ ...textareaStyle, height: '150px', width: '300px', marginTop: '28px', marginLeft: '-00px' }}
                              />
                            </FormGroup>
                          </Col>

                          <Col sm={3}>
                            <FormGroup>
                              <Label for="AnalysisNM" style={{ marginLeft: '100px' }}>Analysis of Nonconformance/Condition (Include results)</Label>
                              <Input
                                id="InitialAnalysis"
                                name="InitialAnalysis"
                                value={formData.InitialAnalysis === null ? '' : formData.InitialAnalysis}
                                onChange={handleChange}
                                type="textarea"
                                disabled={isDisabled}
                                style={{ ...textareaStyle, marginLeft: '50px', height: '150px', width: '300px', }}
                              />
                            </FormGroup>
                          </Col>

                          <div className="checkbox-container" style={{ padding: '15px', borderRadius: '5px', maxWidth: '400px', marginLeft: '100px' }}>
                            <Row>
                              <Col md={5} style={{ paddingRight: '10px' }}>
                                <FormGroup className="input-item" style={{ display: 'flex', alignItems: 'center', width: '300px' }}>
                                  <Label for="Visc" style={{ width: '80px', textAlign: 'right', marginRight: '10px' }}>Visc</Label>
                                  <Input id="Visc" name="Visc" value={formData.Visc || ''} onChange={handleChange} type="text" disabled={isDisabled} />
                                </FormGroup>
                                <FormGroup className="input-item" style={{ display: 'flex', alignItems: 'center', width: '300px' }}>
                                  <Label for="WPG" style={{ width: '80px', textAlign: 'right', marginRight: '10px' }}>WPG</Label>
                                  <Input id="WPG" name="WPG" value={formData.WPG || ''} onChange={handleChange} type="text" disabled={isDisabled} />
                                </FormGroup>

                                <FormGroup className="input-item" style={{ display: 'flex', alignItems: 'center', width: '300px' }}>
                                  <Label for="WtSlds" style={{ width: '80px', textAlign: 'right', marginRight: '10px' }}>WT Slds</Label>
                                  <Input id="WtSlds" name="WtSlds" value={formData.WtSlds || ''} onChange={handleChange} type="text" disabled={isDisabled} />
                                </FormGroup>
                                <FormGroup className="input-item" style={{ display: 'flex', alignItems: 'center', width: '300px' }}>
                                  <Label for="Color" style={{ width: '80px', textAlign: 'right', marginRight: '10px' }}>Color</Label>
                                  <Input id="Color" name="Color" value={formData.Color || ''} onChange={handleChange} type="text" disabled={isDisabled} />
                                </FormGroup>
                              </Col>
                            </Row>
                          </div>
                        </Row>






























                        <h5 style={{ textAlign: "center", fontSize: 24, marginTop: '100px', marginBottom: '30px' }}>  Evaluator </h5>





                        <Row className="mx-auto" style={{ marginTop: '40px' }}>
                          <Col sm={3}>
                            <Label for="InstDisp" style={{ marginLeft: '20px' }}>Instruction for Disposition</Label>
                            <Input
                              id="DispositionInstruction"
                              name="DispositionInstruction"
                              value={formData.DispositionInstruction === null ? '' : formData.DispositionInstruction}
                              onChange={handleChange}
                              type="textarea"
                              disabled={isDisabled}
                              style={{ ...textareaStyle, width: '300px', height: '200px', marginTop: '10px' }}
                            />
                          </Col>
                          <Col sm={3}>
                            <Label for="FinalInsp" style={{ marginLeft: '0px' }}>Final Inspection / Conclusion</Label>
                            <Input
                              id="FinalConclusion"
                              name="FinalConclusion"
                              value={formData.FinalConclusion === null ? '' : formData.FinalConclusion}
                              onChange={handleChange}
                              type="textarea"
                              disabled={isDisabled}
                              style={{ ...textareaStyle, width: '300px', height: '200px', marginTop: '10px', marginLeft: '0px' }}
                            />
                          </Col>
                          <Col sm={3}>
                            <Label for="CustomerFeedbackCategory" style={{ marginLeft: '30px' }}>Product Disposition</Label>
                            <select
                              value={formData.ProductDisposition === null ? '' : formData.ProductDisposition}
                              className="form-select"
                              id="ProductDisposition"
                              name="ProductDisposition"
                              onChange={handleChange}
                              disabled={isDisabled}
                              style={{ ...dropDownStyle, width: '170px', marginLeft: '80px', marginTop: '10px' }}
                            >
                              <option value="">Select an option</option>
                              <option value="Rework">Rework</option>
                              <option value="Repair">Repair</option>
                              <option value="Scrap">Scrap</option>
                              <option value="Use As Is">Use As Is</option>
                              <option value="Reject">Reject</option>
                              <option value="N/A">N/A</option>
                            </select>
                            <div style={{ marginTop: '70px' }}>
                              <Input
                                id="SCARRequired"
                                name="SCARRequired"
                                checked={formData.SCARRequired}
                                onChange={handleChange}
                                type="checkbox"
                                disabled={isDisabled}
                                style={{ verticalAlign: 'middle', marginRight: '5px' }}
                              />
                              <Label for="SCARRequired" style={{ verticalAlign: 'middle' }}>SCAR</Label>
                            </div>
                          </Col>
                          <Col sm={3} style={{ alignSelf: 'flex-start' }}>
                            <div className="form-section-container" style={{
                              padding: '10px',
                              borderRadius: '8px',
                              marginTop: '-30px',
                              marginBottom: '0px',
                              transform: 'scale(0.9)'
                            }}>
                              <FormGroup style={{ display: 'flex', flexDirection: 'column', marginBottom: '0px' }}>
                                <Label for="Company">Trend Code 1</Label>
                                <select
                                  value={formData.TrendCode1 === null ? '' : formData.TrendCode1}
                                  className="form-select"
                                  id="TrendCode1"
                                  name="TrendCode1"
                                  onChange={handleChange}
                                  style={{ width: '100%' }}
                                >
                                  <option value="">Select an option</option>
                                  <option value="01 - DET">Performed by Magni Detroit</option>
                                  <option value="02 - KY">Performed by Magni KY</option>
                                </select>
                              </FormGroup>

                              {/* Rest of trend code selections remain the same */}
                              <FormGroup style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
                                <Label for="Product">Trend Code 2</Label>
                                <select
                                  value={formData.TrendCode2 === null ? '' : formData.TrendCode2}
                                  className="form-select"
                                  id="TrendCode2"
                                  name="TrendCode2"
                                  onChange={handleChange}
                                  style={{ width: '100%' }}
                                >
                                  <option value="">Select an option</option>
                                  <option value="01 - RM">Received (raw material)</option>
                                  <option value="02 - Isp">Incoming Inspection/Testing (raw material)</option>
                                  <option value="03 - RMStor">Storage (raw material)</option>
                                  <option value="04 - InProc">In-process testing</option>
                                  <option value="05 - Final">Final testing</option>
                                  <option value="06 - FGStor">Storage (finished good)</option>
                                  <option value="07 - TranShip">Transit or Shipping</option>
                                  <option value="08 - CustStor">Storage (applicator)</option>
                                  <option value="09 - App">Application</option>
                                  <option value="10 - Test">Testing</option>
                                  <option value="11 - CoatStor">Storage (coated articles)</option>
                                  <option value="12 - Inst">Installation</option>
                                  <option value="13 - Field">Field failure</option>
                                  <option value="14 - Other">Other Issues</option>
                                </select>
                              </FormGroup>

                              {/* Rest of the trend code fields... */}
                              <FormGroup style={{ display: 'flex', flexDirection: 'column' }}>
                                <Label for="Initiator">Trend Code 3</Label>
                                <select
                                  value={formData.TrendCode3 === null ? '' : formData.TrendCode3}
                                  className="form-select"
                                  id="TrendCode3"
                                  name="TrendCode3"
                                  onChange={handleChange}
                                  style={{ width: '100%' }}
                                >
                                  <option value="">Select an option</option>
                                  <option value="01 - Ext">External</option>
                                  <option value="02 - Int">Internal</option>
                                </select>
                              </FormGroup>
                              <FormGroup style={{ display: 'flex', flexDirection: 'column' }}>
                                <Label for="Initiator">Trend Code 4</Label>
                                <select
                                  value={formData.TrendCode4 === null ? '' : formData.TrendCode4}
                                  className="form-select"
                                  id="TrendCode4"
                                  name="TrendCode4"
                                  onChange={handleChange}
                                  style={{ width: '100%' }}
                                >
                                  <option value="">Select an option</option>
                                  <option value="01 - DO">Documentation Error</option>
                                  <option value="02 - HU">Human Error</option>
                                  <option value="03 - PR">Nonconforming Products</option>
                                  <option value="04 - SA">Safety Concern</option>
                                  <option value="05 - SUP">Supplier Issue</option>
                                </select>
                              </FormGroup>
                              <FormGroup style={{ display: 'flex', flexDirection: 'column' }}>
                                <Label for="Initiator">Trend Code 5</Label>
                                <select
                                  value={formData.TrendCode5 === null ? '' : formData.TrendCode5}
                                  className="form-select"
                                  id="TrendCode5"
                                  name="TrendCode5"
                                  onChange={handleChange}
                                  style={{ width: '100%' }}
                                >
                                  <option value="">Select an option</option>
                                  <option value="01 - SPEC">Out of Specification</option>
                                  <option value="02 - PURE">Purity-Contamination of Product</option>
                                  <option value="03 - FORM">Formulation error, Physical content error</option>
                                  <option value="04 - RHE">Bad Rheology</option>
                                  <option value="05 - LIFE">Shelf Life violation</option>
                                  <option value="06 - SHIP">Shipping (Container Integrity, Leakage, Labeling, etc.)</option>
                                  <option value="07 - DEF">Defective Paint</option>
                                  <option value="08 - CUST">Customer accomodation</option>
                                  <option value="09 - SB">Skill Based Human error</option>
                                  <option value="10 - RB">Rule Based Human error</option>
                                  <option value="11 - KB">Knowledge Based Human error</option>
                                  <option value="12 - ADH">Adhesion problems</option>
                                  <option value="13 - CHEM">Chemical Resistance</option>
                                  <option value="14 - ENV">Performance in Env. Testing (SAE J2334, B117, etc.)</option>
                                  <option value="15 - NORET">Internal issue, no return</option>
                                </select>
                              </FormGroup>
                            </div>
                          </Col>
                        </Row>









                        <h5 style={{ textAlign: "center", fontSize: 24, marginTop: '100px', marginBottom: '50px' }}>  Report Status </h5>

                        <Row className="mx-auto justify-content-center align-items-end">
                          {/* QC Manager Column */}
                          <Col sm={2}>
                            <FormGroup>
                              <Label for="QCManagerApprove">QA Manager</Label>
                              <select
                                id="QCManagerApprove"
                                name="QCManagerApprove"
                                value={formData.QCManagerApprove ?? ''}
                                onChange={handleChange}
                                disabled={isDisabled}
                                style={{ ...dropDownStyle, width: '100%' }}
                              >
                                <option value="">Select an option</option>
                                {CRUsers.map((option, index) => (
                                  <option key={index} value={option.userID}>
                                    {option.FullName}
                                  </option>
                                ))}
                              </select>
                            </FormGroup>
                            <FormGroup>
                              <Label for="QCDateApprove">Date</Label>
                              <Input
                                type="date"
                                id="QCDateApprove"
                                name="QCDateApprove"
                                value={formData.QCDateApprove === null ? '' : moment(formData.QCDateApprove).format('YYYY-MM-DD')}
                                onChange={handleChange}
                                disabled={isDisabled}
                                style={{ ...dateStyle }}
                              />
                            </FormGroup>
                          </Col>

                          {/* Initiator Column */}
                          <Col sm={2}>
                            <FormGroup>
                              <Label for="InitiatorApprove">Assignee</Label>
                              <select
                                id="InitiatorApprove"
                                name="InitiatorApprove"
                                value={formData.InitiatorApprove ?? ''}
                                onChange={handleChange}
                                disabled={isDisabled}
                                style={{ ...dropDownStyle, width: '100%' }}
                              >
                                <option value="">Select an option</option>
                                {CRUsers.map((option, index) => (
                                  <option key={index} value={option.userID}>
                                    {option.FullName}
                                  </option>
                                ))}
                              </select>
                            </FormGroup>
                            <FormGroup>
                              <Label for="InitiatorApproveDate">Date</Label>
                              <Input
                                type="date"
                                id="InitiatorApproveDate"
                                name="InitiatorApproveDate"
                                value={formData.InitiatorApproveDate === null ? '' : moment(formData.InitiatorApproveDate).format('YYYY-MM-DD')}
                                onChange={handleChange}
                                disabled={isDisabled}
                                style={{ ...dateStyle }}
                              />
                            </FormGroup>
                          </Col>

                          {/* Approve3 Column */}
                          <Col sm={2}>
                            <FormGroup>
                              <Label for="Approve3">Other</Label>
                              <select
                                id="Approve3"
                                name="Approve3"
                                value={formData.Approve3 ?? ''}
                                onChange={handleChange}
                                disabled={isDisabled}
                                style={{ ...dropDownStyle, width: '100%' }}
                              >
                                <option value="">Select an option</option>
                                {CRUsers.map((option, index) => (
                                  <option key={index} value={option.userID}>
                                    {option.FullName}
                                  </option>
                                ))}
                              </select>
                            </FormGroup>
                            <FormGroup>
                              <Label for="Approve3Date">Date</Label>
                              <Input
                                type="date"
                                id="Approve3Date"
                                name="Approve3Date"
                                value={formData.Approve3Date === null ? '' : moment(formData.Approve3Date).format('YYYY-MM-DD')}
                                onChange={handleChange}
                                disabled={isDisabled}
                                style={{ ...dateStyle }}
                              />
                            </FormGroup>
                          </Col>

                          {/* Completion Date (Separate Column) */}
                          {/* <Col sm={2}>
                                  <FormGroup>
                                    <Label for="CompleteDate">Completion Date</Label>
                                    <Input
                                      id="DateCompleted"
                                      name="DateCompleted"
                                      value={formData.DateCompleted === null ? '' : moment(formData.DateCompleted).format('YYYY-MM-DD')}
                                      onChange={handleChange}
                                      type="date"
                                      disabled={isDisabled}
                                      style={{ ...dateStyle }}
                                    />
                                  </FormGroup>
                                </Col> */}
                        </Row>






                        <h5 style={{ textAlign: "center", fontSize: 24, marginTop: '100px' }}>

                          <FormGroup check style={{ marginLeft: '0px' }}>
                            <Label check>
                              <Input
                                type="checkbox"
                                id="CARequired"
                                name="CARequired"
                                checked={formData.CARequired}
                                onChange={handleChange}
                                disabled={isDisabled}
                              />
                              {' '}Corrective Action Required?
                            </Label>
                          </FormGroup>

                        </h5>

                        <Row className="justify-content-center" style={{ marginTop: '10px' }}>
                          <Col sm={3} className="d-flex flex-column align-items-center">
                            <FormGroup>
                              <Label for="InterimAction" className="text-center">Interim Action Taken</Label>
                              <Input
                                id="CorrectiveAction.CPAInterimAction"
                                name="CorrectiveAction.CPAInterimAction"
                                value={formData.CorrectiveAction?.CPAInterimAction ?? ''}
                                onChange={handleChange}
                                type="textarea"
                                disabled={!formData.CARequired}
                                style={{ ...textareaStyle, height: '200px', width: '220px' }}
                              />
                            </FormGroup>
                          </Col>
                          <Col sm={3} className="d-flex flex-column align-items-center">
                            <FormGroup>
                              <Label for="RootCause" className="text-center">Root Cause Determination</Label>
                              <Input
                                id="CorrectiveAction.CPARootCause"
                                name="CorrectiveAction.CPARootCause"
                                value={formData.CorrectiveAction?.CPARootCause ?? ''}
                                onChange={handleChange}
                                type="textarea"
                                disabled={!formData.CARequired}
                                style={{ ...textareaStyle, height: '200px', width: '220px' }}
                              />
                            </FormGroup>
                          </Col>
                          <Col sm={3} className="d-flex flex-column align-items-center">
                            <FormGroup>
                              <Label for="CPA" className="text-center">Correct/Prevent Action</Label>
                              <Input
                                id="CorrectiveAction.CPAActionTaken"
                                name="CorrectiveAction.CPAActionTaken"
                                value={formData.CorrectiveAction?.CPAActionTaken ?? ''}
                                onChange={handleChange}
                                type="textarea"
                                disabled={!formData.CARequired}
                                style={{ ...textareaStyle, height: '200px', width: '220px' }}
                              />
                            </FormGroup>
                          </Col>
                          <Col sm={3} className="d-flex flex-column align-items-center">
                            <FormGroup>
                              <Label for="CPAFollowUP" className="text-center">Follow Up</Label>
                              <Input
                                id="CorrectiveAction.CPAFollowUP"
                                name="CorrectiveAction.CPAFollowUP"
                                value={formData.CorrectiveAction?.CPAFollowUP ?? ''}
                                onChange={handleChange}
                                type="textarea"
                                disabled={!formData.CARequired}
                                style={{ ...textareaStyle, height: '200px', width: '220px' }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>


                        <Row className="mx-auto justify-content-center align-items-center" style={{ marginTop: '20px' }}>
                          <Col sm={3} className="d-flex flex-column align-items-center">
                            <FormGroup>
                              <Label for="CPAResponsibleSign">Signature</Label>
                              <select
                                className="form-select"
                                id="CorrectiveAction.CPAResponsibleSign"
                                name="CorrectiveAction.CPAResponsibleSign"
                                value={formData.CorrectiveAction?.CPAResponsibleSign ?? ''}
                                onChange={handleChange}
                                disabled={!formData.CARequired}
                                style={{ width: '150px' }}
                              >
                                <option value="">Select an option</option>
                                {CRUsers.map((option, index) => (
                                  <option key={index} value={option.userID}>
                                    {option.FullName}
                                  </option>
                                ))}
                              </select>
                            </FormGroup>
                          </Col>

                          <Col sm={3} className="d-flex flex-column align-items-center">
                            <FormGroup check>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  id="ContinuousImprovement"
                                  name="ContinuousImprovement"
                                  checked={formData.ContinuousImprovement}
                                  onChange={handleChange}
                                  disabled={!formData.CARequired}
                                />
                                {' '}Continuous Improvement Result from this CR?
                              </Label>
                            </FormGroup>
                          </Col>

                          <Col sm={3} className="d-flex flex-column align-items-center">
                            <FormGroup check>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  id="DidAssigneeCompleteSatisfactorily"
                                  name="DidAssigneeCompleteSatisfactorily"
                                  checked={formData.DidAssigneeCompleteSatisfactorily}
                                  onChange={handleChange}
                                  disabled={!formData.CARequired}
                                />
                                {' '}Did Assignee Complete Satisfactorily?
                              </Label>
                            </FormGroup>
                          </Col>

                          <Col sm={3} className="d-flex flex-column align-items-center">
                            <FormGroup>
                              <Label for="Approve1CA">Internal/External Reject</Label>
                              <select
                                id="CorrectiveAction.IntOrExt"
                                name="CorrectiveAction.IntOrExt"
                                value={formData.CorrectiveAction?.IntOrExt ?? ''}
                                onChange={handleChange}
                                disabled={!formData.CARequired}
                                className="form-select"
                                style={{ width: '170px' }}
                              >
                                <option value="">Select an option</option>
                                <option value="Internal">Internal</option>
                                <option value="External">External</option>
                              </select>
                            </FormGroup>
                          </Col>
                        </Row>


                        <h5 style={{ textAlign: "center", fontSize: 24, marginTop: '100px', marginBottom: '20px' }}>  CA Report Status </h5>

                        <Row className="mx-auto justify-content-center align-items-end">
                          {/* QC Manager Column */}
                          <Col sm={2}>
                            <FormGroup>
                              <Label for="CPAQCManagerApprove">QA Manager</Label>
                              <select
                                id="CorrectiveAction.CPAQCManagerApprove"
                                name="CorrectiveAction.CPAQCManagerApprove"
                                value={formData.CorrectiveAction?.CPAQCManagerApprove ?? ''}
                                onChange={handleChange}
                                disabled={!formData.CARequired}
                                style={{ ...dropDownStyle, width: '100%' }}
                              >
                                <option value="">Select an option</option>
                                {CRUsers.map((option, index) => (
                                  <option key={index} value={option.userID}>{option.FullName}</option>
                                ))}
                              </select>
                            </FormGroup>
                            <FormGroup>
                              <Label for="CPAQCManagerDate">Date</Label>
                              <Input
                                type="date"
                                id="CorrectiveAction.CPAQCManagerDate"
                                name="CorrectiveAction.CPAQCManagerDate"
                                value={formData.CorrectiveAction?.CPAQCManagerDate ? moment(formData.CorrectiveAction.CPAQCManagerDate).format('YYYY-MM-DD') : ''}
                                onChange={handleChange}
                                disabled={!formData.CARequired}
                                style={{ ...dateStyle }}
                              />
                            </FormGroup>
                          </Col>

                          {/* Initiator Column */}
                          <Col sm={2}>
                            <FormGroup>
                              <Label for="CPAInitiatorApprove">Assignee</Label>
                              <select
                                id="CorrectiveAction.CPAInitiatorApprove"
                                name="CorrectiveAction.CPAInitiatorApprove"
                                value={formData.CorrectiveAction?.CPAInitiatorApprove ?? ''}
                                onChange={handleChange}
                                disabled={!formData.CARequired}
                                style={{ ...dropDownStyle, width: '100%' }}
                              >
                                <option value="">Select an option</option>
                                {CRUsers.map((option, index) => (
                                  <option key={index} value={option.userID}>{option.FullName}</option>
                                ))}
                              </select>
                            </FormGroup>
                            <FormGroup>
                              <Label for="CPAInitiatorDate">Date</Label>
                              <Input
                                type="date"
                                id="CorrectiveAction.CPAInitiatorDate"
                                name="CorrectiveAction.CPAInitiatorDate"
                                value={formData.CorrectiveAction?.CPAInitiatorDate ? moment(formData.CorrectiveAction.CPAInitiatorDate).format('YYYY-MM-DD') : ''}
                                onChange={handleChange}
                                disabled={!formData.CARequired}
                                style={{ ...dateStyle }}
                              />
                            </FormGroup>
                          </Col>

                          {/* Approve3 Column */}
                          <Col sm={2}>
                            <FormGroup>
                              <Label for="CPAApprove3">Other</Label>
                              <select
                                id="CorrectiveAction.CPAApprove3"
                                name="CorrectiveAction.CPAApprove3"
                                value={formData.CorrectiveAction?.CPAApprove3 ?? ''}
                                onChange={handleChange}
                                disabled={!formData.CARequired}
                                style={{ ...dropDownStyle, width: '100%' }}
                              >
                                <option value="">Select an option</option>
                                {CRUsers.map((option, index) => (
                                  <option key={index} value={option.userID}>{option.FullName}</option>
                                ))}
                              </select>
                            </FormGroup>
                            <FormGroup>
                              <Label for="CPAApprove3Date">Date</Label>
                              <Input
                                type="date"
                                id="CorrectiveAction.CPAApprove3Date"
                                name="CorrectiveAction.CPAApprove3Date"
                                value={formData.CorrectiveAction?.CPAApprove3Date ? moment(formData.CorrectiveAction.CPAApprove3Date).format('YYYY-MM-DD') : ''}
                                onChange={handleChange}
                                disabled={!formData.CARequired}
                                style={{ ...dateStyle }}
                              />
                            </FormGroup>
                          </Col>

                          {/* Completion Date (Separate Column) */}
                          {/* <Col sm={2}>
                                  <FormGroup>
                                    <Label for="CPACompletionDate">Completion Date</Label>
                                    <Input
                                      id="CorrectiveAction.CPACompletionDate"
                                      name="CorrectiveAction.CPACompletionDate"
                                      value={formData.CorrectiveAction?.CPACompletionDate ? moment(formData.CorrectiveAction.CPACompletionDate).format('YYYY-MM-DD') : ''}
                                      onChange={handleChange}
                                      type="date"
                                      disabled={!formData.CARequired}
                                      style={{ ...dateStyle }}
                                    />
                                  </FormGroup>
                                </Col> */}
                        </Row>



                      </ModalBody>
                      <div style={{ textAlign: "center", marginTop: "2rem" }}>
                        <Button onClick={HandleSubmit} style={{ ...dropDownStyle, backgroundColor: "#0e226a", fontSize: "18px", padding: "10px 20px", border: "none", color: "#fff" }}>
                          Submit Changes
                        </Button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>

    </div >
  );
}
export default AddCR