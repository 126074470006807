import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FormGroup, ModalHeader, Modal, ModalBody, ModalFooter, Row, Col, Label, Input, Button, Table } from "reactstrap";
import { useState, useEffect, useRef } from 'react';
import axios from "axios";
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { parse } from "path";
import { BounceLoader } from 'react-spinners';
//import Menu from './Menu';
import Header2 from './Header2';
import { ReportObject,  reportErrors, UserType, User, ProcessChangeOBJ } from "./MagniTypes";
import APICalls from './APICalls';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';



var moment = require('moment');

interface FileItemProps {
  fileName: string;
  description: string;
  onDownload: () => void;
  onDelete: () => void;
}

const sectionContainerStyles = {
  backgroundColor: "#3D566E",
  border: "1px solid #576F8B",
  borderRadius: "8px",
  padding: "1.5rem",
  marginBottom: "1.5rem"
};

const sectionTitleStyles = {
  fontSize: "1.5rem",
  fontWeight: 600,
  color: "#ECF0F1",
  textAlign: "center" as React.CSSProperties["textAlign"],
  marginBottom: "1rem"
};

const textStyle = {
  width: "100%",
  fontSize: "12px",
  padding: "5px",
  borderRadius: "6px",
  border: "1px solid #576F8B",
  backgroundColor: "#BDC3C7",
  color: "#2C3E50"
};

const dropDownStyle = {
  borderRadius: "6px",
  padding: "8px",
  border: "1px solid #576F8B",
  backgroundColor: "#BDC3C7",
  color: "#2C3E50"
};

const dateStyle = {
  ...textStyle
};



export default function CRDetails() {
  const location = useLocation();
  const [formData, setFormData] = useState<ReportObject>(new ReportObject());
  //const [modalData, setModalData] = useState<ReportObject>(new ReportObject());
  const nav = useNavigate();
  const [followed, setFollowed] = useState(false);
  const [user, setUser] = useState<UserType>(new UserType());
  const [CRUsers, setCRUsers] = useState<User[]>([]);

  const FileItem: React.FC<FileItemProps> = ({ fileName, description, onDownload, onDelete }) => (
    <tr>
      <td style={{ color: "#0E226A" }}>{fileName}</td>
      <td style={{ color: "#0E226A" }}>{description}</td>
      <td>
        <Button color="primary" onClick={onDownload}>Download</Button>
      </td>
      <td style={{ color: "#0E226A" }}>
        <Button color="danger" onClick={onDelete}>Delete File</Button>
      </td>
    </tr>
  );


  const [loadingPage, setPageLoading] = useState(true);
  const { data } = useParams();
  //const [modalCommentData, setModalCommentData] = useState<CommentDataStuct>(initialFormData);
  const [modal, setModal] = useState(false);
  const apiCalls = new APICalls();
  const [customerOptions, setCustomerOptions] = useState([]);

  const rmaTableRef = useRef(null);



  const toggle = () => {
    setModal(!modal);
    //console.log("formData before setting modal" + formData);
    //setModalData(formData);
    console.log(data);




    setIsDisabled(false)
  }


  const [productoptions, setProductOptions] = useState([]);

  const [showModal, setShowModal] = useState(false);

  const [costItem, setCostItem] = useState("");
  const [cost, setCost] = useState("");
  //const [costs, setCosts] = useState<{ item: string; cost: string }[]>([]);
  const [items, setItems] = useState<{ CostID: number; CRNumber: number; Item: string; Cost: string }[]>([]);
  const [editModal, setEditModal] = useState(false);
  const [selectedEntry, setSelectedEntry] = useState<{ CostID: number; Item: string; Cost: string } | null>(null);

  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteEntry, setDeleteEntry] = useState<{ CostID: number; Item: string } | null>(null);


  const [RMAshowModal, setRMAShowModal] = useState(false);
  const [rmaid, setrmaID] = useState(0);
  const [containerNum, setContainerNum] = useState(0);
  const [containerType, setContainerType] = useState('');
  const [gallonNum, setGallonNum] = useState(0);
  const [weight, setWeight] = useState(0);
  const [lot, setLot] = useState('');
  const [RMAitems, setRMAItems] = useState<{ rmaID: number; CRNumber: number; ContainerNum: number; ContainerType: string; GallonNum: number; Weight: number; Lot: string; }[]>([]);
  const [RMAeditModal, setRMAEditModal] = useState(false);
  const [RMAselectedEntry, setRMASelectedEntry] = useState<{ rmaID: number; CRNumber: number; ContainerNum: number; ContainerType: string; GallonNum: number; Weight: number; Lot: string; } | null>(null);

  const [RMAdeleteModal, setRMAdeleteModal] = useState(false);
  const [RMAdeleteEntry, setRMAdeleteEntry] = useState<{ rmaID: number; CRNumber: number; ContainerNum: number; ContainerType: string; GallonNum: number; Weight: number; Lot: string; } | null>(null);


  const [processChangeModal, setProcessChangeModal] = useState(false);
  const [processChangeEntry, setProcessChangeEntry] = useState<ProcessChangeOBJ | null>(null);






  const InitialProcessObject = {
    ID: 0,
    CRNumber: 0,
    ProcessChangeDescription: '',
    EquipmentImpacted: '',
    PartNumbersImpacted: '',
    StaffApproval: false,
    StaffApprovalDate: null,
    CustomerNotificationRequired: false,
    DateNotified: null,
    ProcessChangeLaunchDate: null,
    CustomerApprovalReceived: false,
    DateSent: null,
    DateApproved: null,
    LaunchFeedback: '',
    
  };


  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem('user') || '{}'));
    const SetupPage = async () => {

      const fetchData = async () => {
        await GetReportByID();
      };
      await fetchData(); 

      toggle();

      console.log(formData)
      //await GetItems();
      //await GetRMA();
      await GetCustomers();
      await GetCRUsers();
      await GetProducts();
      //await GetAssignedToUsers();





    }

    setTimeout(() => {
      SetupPage();
    }, 2000);

  }, []);

  const HandleSubmit = async (e: any) => {
    e.preventDefault();
    formData.Initiator = ''
    // let errors: reportErrors = {
    //     CRTitle: formData.CRTitle === null ? true : false,
    // };

    // setReportErrors(errors);
    // console.log(errors)


    if (data) {
      try {
        //if (!Object.values(errors).some(field => field === true)) {
        console.log(formData)
        const response = await apiCalls.EditReport(formData);
        console.log(response);
        if (response) {
          toggle();
          setFormData(formData);
          // setTimeout(() => {
          nav('/conditionreports/');
          // }, 5000);
        } else {
          toast.error("Failed to edit report");
          toggle();
        }
        // }
        // else {
        //     toast.error("Please fill all the required fields that are highlighted in red");

        // }
      } catch (error) {
        console.error('Failed to edit:', error);
      }
    } else {
      console.error('Data is undefined');
    }
  }





  const GetItems = async () => {
    // if (!formData.CRNumber) {
    //     console.log("CRNumber is required");
    //     // Optionally set some error state here to display a message
    //     return;
    // }
    const crnum = Number(data)
    try {
      // Fetch items from the API based on CRNumber
      const response = await apiCalls.GetItems(crnum);
      setItems(response.data); // Update the state with the returned data
      console.log("Items data:", response.data);
      console.log("Items data:", items);

    } catch (error) {
      console.error("Error retrieving items:", error);
      // Optionally set an error state to show an error message to the user
      // setError("Failed to retrieve items. Please try again later.");
    }
  };



  const HandleAddItem = async (e: any) => {
    e.preventDefault();

    if (!costItem || !cost) {
      toast.error("Item and cost are required");
      return;
    }

    const newItem = {
      CostID: 0,  // Will be assigned by the backend
      CRNumber: Number(data),
      Item: costItem,
      Cost: cost
    };

    try {
      // Make API call to add the item
      const response = await apiCalls.AddItem(newItem);

      // Get the actual CostID from the response (assuming your API returns the created item)
      // If your API doesn't return the created item with ID, you may need to adjust this
      const createdItem = response.data;

      // Update local state with the new item including its proper ID
      setItems([...items, {
        CostID: createdItem.CostID || Math.floor(Math.random() * -1000), // Use returned ID or temporary one
        CRNumber: Number(data),
        Item: costItem,
        Cost: cost
      }]);

      // Close the modal after adding
      setShowModal(false);

      // Clear the form data
      setCostItem('');
      setCost('');

    } catch (error) {
      console.error("Error adding item:", error);
      toast.error("Failed to add item");
    }
  };





  const handleEditClick = (entry: { CostID: number; Item: string; Cost: string }) => {
    setSelectedEntry({ ...entry }); // Set the selected entry for editing
    setEditModal(true); // Open the modal
  };


  const HandleEditItem = async (entry: { CostID: number; Item: string; Cost: string }) => {
    if (!entry.Item || !entry.Cost) {
      toast.error("Item and cost cannot be empty.");
      return;
    }

    try {
      const data = {
        CostID: entry.CostID,
        Item: entry.Item,
        Cost: parseFloat(entry.Cost),
      };

      await apiCalls.EditItem(data);

      // Update the local state with the edited item
      setItems(items.map(item =>
        item.CostID === entry.CostID ? { ...item, Item: entry.Item, Cost: entry.Cost } : item
      ));

      setEditModal(false);
    } catch (error) {
      console.error("Error editing item:", error);
      toast.error("Failed to update item.");
    }
  };


  const handleDeleteClick = (entry: { CostID: number; Item: string }) => {
    setDeleteEntry(entry);  // Store entry to delete
    setDeleteModal(true);  // Open delete confirmation modal
  };


  const HandleDeleteItem = async () => {
    if (!deleteEntry) return;

    try {
      await apiCalls.DeleteItem(deleteEntry.CostID);

      // Update local state by filtering out the deleted item
      setItems(items.filter(item => item.CostID !== deleteEntry.CostID));

      setDeleteModal(false);
    } catch (error) {
      console.error("Error deleting item:", error);
      toast.error("Failed to delete item");
    }
  };



















  //adding RMA



  const GetRMA = async () => {
    // if (!formData.CRNumber) {
    //     console.log("CRNumber is required");
    //     // Optionally set some error state here to display a message
    //     return;
    // }
    const crnum = Number(data)
    try {
      // Fetch items from the API based on CRNumber
      const response = await apiCalls.GetRMA(crnum);
      setRMAItems(response.data); // Update the state with the returned data
      console.log("RMA data:", response.data);
      console.log("RMA data:", RMAitems);

    } catch (error) {
      console.error("Error retrieving RMA:", error);
      // Optionally set an error state to show an error message to the user
      // setError("Failed to retrieve items. Please try again later.");
    }
  };



  const HandleRMAAddItem = async (e: any) => {
    e.preventDefault();

    if (!containerType) {
      toast.error("Container type is required");
      return;
    }

    const newRMA = {
      rmaID: 0,  // Will be assigned by the backend
      CRNumber: Number(data),
      ContainerNum: containerNum,
      ContainerType: containerType,
      GallonNum: gallonNum,
      Weight: weight,
      Lot: lot
    };

    try {
      // Make API call to add the RMA
      const response = await apiCalls.AddRMA(newRMA);

      // Get the actual rmaID from the response (assuming your API returns the created RMA)
      // If your API doesn't return the created RMA with ID, you may need to adjust this
      const createdRMA = response.data;

      // Update local state with the new RMA including its proper ID
      setRMAItems([...RMAitems, {
        rmaID: createdRMA.rmaID || Math.floor(Math.random() * -1000), // Use returned ID or temporary one
        CRNumber: Number(data),
        ContainerNum: containerNum,
        ContainerType: containerType,
        GallonNum: gallonNum,
        Weight: weight,
        Lot: lot
      }]);

      // Close the modal after adding
      setRMAShowModal(false);

      // Clear the form data
      setContainerNum(0);
      setContainerType('');
      setGallonNum(0);
      setWeight(0);
      setLot('');

    } catch (error) {
      console.error("Error adding RMA:", error);
      toast.error("Failed to add RMA");
    }
  };





  const handleRMAEditClick = (entry: { rmaID: number; CRNumber: number; ContainerNum: number; ContainerType: string; GallonNum: number; Weight: number; Lot: string; }) => {
    setRMASelectedEntry({ ...entry }); // Set the selected entry for editing
    setRMAEditModal(true); // Open the modal
  };


  const HandleRMAEditItem = async (entry: { rmaID: number; CRNumber: number; ContainerNum: number; ContainerType: string; GallonNum: number; Weight: number; Lot: string; }) => {
    try {
      const data = {
        rmaID: entry.rmaID,
        CRNumber: entry.CRNumber,
        ContainerNum: entry.ContainerNum,
        ContainerType: entry.ContainerType,
        GallonNum: entry.GallonNum,
        Weight: entry.Weight,
        Lot: entry.Lot
      };

      await apiCalls.EditRMA(data);

      // Update the local state with the edited RMA
      setRMAItems(RMAitems.map(rma =>
        rma.rmaID === entry.rmaID ? { ...entry } : rma
      ));

      setRMAEditModal(false);
    } catch (error) {
      console.error("Error editing RMA:", error);
      toast.error("Failed to update RMA.");
    }
  };


  const handleRMADeleteClick = (entry: { rmaID: number; CRNumber: number; ContainerNum: number; ContainerType: string; GallonNum: number; Weight: number; Lot: string; }) => {
    setRMAdeleteEntry(entry);  // Store entry to delete
    setRMAdeleteModal(true);  // Open delete confirmation modal
  };


  const HandleRMADeleteItem = async () => {
    if (!RMAdeleteEntry) return;

    try {
      await apiCalls.DeleteRMA(RMAdeleteEntry.rmaID);

      // Update local state by filtering out the deleted RMA
      setRMAItems(RMAitems.filter(rma => rma.rmaID !== RMAdeleteEntry.rmaID));

      setRMAdeleteModal(false);
    } catch (error) {
      console.error("Error deleting RMA:", error);
      toast.error("Failed to delete RMA");
    }
  };















  const GetReportByID = async () => {
    try {
      setPageLoading(true);
      const response = await apiCalls.GetReportByID(data || "0");

      // Set the main form data
      setFormData(response.data);

      // Set the RMA items and cost items from the response
      if (response.data.RMAs) {
        setRMAItems(response.data.RMAs);
      } else {
        setRMAItems([]);
      }

      if (response.data.CRItems) {
        setItems(response.data.CRItems);
      } else {
        setItems([]);
      }

      setPageLoading(false);
    } catch (error) {
      console.error("Error retrieving report details:", error);
      setPageLoading(false);
      // Optional: Show error toast
      toast.error("Failed to load report details");
    }
  };



  const handleChange = (event: any) => {
    const { name, value, type, checked } = event.target;
    const keys = name.split('.'); 
    let updatedValue = type === 'checkbox' ? (checked ? true : false) : value.trim() === '' ? null : value;

    setFormData((prevFormData: any) => {
      if (keys.length > 1) {
        // If it's a nested field, update the nested object correctly
        return {
          ...prevFormData,
          [keys[0]]: {
            ...(prevFormData[keys[0]]),  // Type assertion to CorrectiveAction
            [keys[1]]: updatedValue,
          },
        };
      } else {
        // Otherwise, just update the direct field
        return {
          ...prevFormData,
          [name]: updatedValue,
        };
      }
    });
  };



  const GetProducts = async () => {
    const response = await apiCalls.GetProducts();
    const data = response.data;
    setProductOptions(data);
    console.log(productoptions);
  }

  const GetCRUsers = async () => {
    const response = await apiCalls.GetCRUsers();
    const data = response.data;
    setCRUsers(data);
    console.log(CRUsers);
  }


  // const GetAssignedToUsers = async () => {
  //     const response = await apiCalls.GetAssignedToUsers();
  //     const data = response.data;
  //     setAssignedToUsers(data);
  //     console.log(assignedToUsers);
  // }

  const GetCustomers = async () => {
    const response = await apiCalls.GetCustomers();
    const data = response.data;
    setCustomerOptions(data);
    console.log(customerOptions);
  }



  // const [reportErrors, setReportErrors] = useState<reportErrors>({
  //     CRNumber: false,
  //     CRTitle: false,

  // });



  const containerStyle = {
    display: 'flex',
    justifyContent: '',
    //alignItems: 'center',
  };



  const textStyle = {
    //borderRadius: "5px",
    //padding: '0px',
    //justifyContent: 'center',
    //alignItems: 'center',
    width: '50%'

  };

  const dateStyle = {
    borderRadius: "5px",
    padding: '5px',
    //justifyContent: 'center'
    //alignItems: 'center'


  };

  const textareaStyle = {
    //borderRadius: "5px",
    //padding: '5px',
    //justifyContent: 'center'
    //alignItems: 'center'
    //width:'200px'

  };


  const dropDownStyle = {
    borderRadius: "5px",
    padding: '5px',
    borderColor: 'black',
    border: '2px'
  }


  const [isDisabled, setIsDisabled] = useState(false);









  const generateRMAPDF = async () => {
    try {
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
        format: 'a4'
      });

      const pageWidth = pdf.internal.pageSize.getWidth();
      const margin = 5;
      const textColor = [0, 0, 0];

      // Helper functions for positioning
      const centerX = pageWidth / 2;

      // Set up fonts and styles
      pdf.setFont('helvetica', 'bold');
      pdf.setTextColor(textColor[0], textColor[1], textColor[2]);

      // Draw header rectangle
      pdf.setDrawColor(textColor[0], textColor[1], textColor[2]);
      pdf.setLineWidth(0.5);
      pdf.rect(margin, margin, pageWidth - margin * 2, 20);

      // Add header text
      pdf.setFontSize(16);
      pdf.text("Return Material Authorization", centerX, margin + 8, { align: 'center' });
      pdf.setFontSize(12);
      pdf.text("Magni Industries, Inc.", centerX, margin + 16, { align: 'center' });

      // Add CR Number and Location
      let y = margin + 30;
      pdf.setFontSize(10);
      pdf.text(`CR Number: ${data || 'N/A'}`, pageWidth - 65, y);

      // Location based on plant
      const location = formData.Location === "Kentucky" ? "KY" : "DET";
      pdf.text(location, pageWidth - 30, y);

      // Add "Issued To" and Company
      pdf.text("Issued To:", margin, y);

      // Draw line under company name
      y += 6;
      pdf.setFont('helvetica', 'normal');
      pdf.text(formData.Company, margin, y);
      pdf.line(margin, y + 2, margin + 60, y + 2);

      // "To Authorize Return Of" section
      y += 15;
      pdf.setFont('helvetica', 'bold');
      pdf.text("To Authorize Return Of:", margin, y);

      // Product ID and Lot Number
      y += 10;
      pdf.setFont('helvetica', 'bold');
      pdf.text("Product ID:", margin, y);
      pdf.setFont('helvetica', 'normal');
      const productValue = formData.Product || "________________";
      pdf.text(productValue, margin + 25, y);
      pdf.line(margin + 25, y + 2, margin + 85, y + 2);

      // pdf.setFont('helvetica', 'bold');
      // pdf.text("Lot Number:", margin + 100, y);
      // pdf.setFont('helvetica', 'normal');

      // // Find lot from RMA items if available
      // let lotNumber = "";
      // if (RMAitems && RMAitems.length > 0) {
      //   lotNumber = RMAitems[0].Lot || "";
      // }
      // pdf.text(lotNumber || "________________", margin + 130, y);
      // pdf.line(margin + 130, y + 2, margin + 180, y + 2);

      // Drums and Totes table
      y += 10;
      pdf.setFont('helvetica', 'bold');
      pdf.text("Container(s):", margin, y);

      // Table headers
      y += 10;
      pdf.setFontSize(10);
      pdf.setFont('helvetica', 'bold');
      pdf.text("Container Type", margin, y);
      pdf.text("Container Num", margin + 40, y);
      pdf.text("Gallon Num", margin + 80, y);
      pdf.text("Weight", margin + 120, y);
      pdf.text("Lot", margin + 160, y);

      // Table rows
      y += 5;
      pdf.setFont('helvetica', 'normal');
      RMAitems.forEach(item => {
        pdf.text(item.ContainerType || "N/A", margin, y);
        pdf.text(item.ContainerNum.toString() || "N/A", margin + 40, y);
        pdf.text(item.GallonNum.toString() || "N/A", margin + 80, y);
        pdf.text(item.Weight.toString() || "N/A", margin + 120, y);
        pdf.text(item.Lot || "N/A", margin + 160, y);
        y += 5;
      });

      // Shipping information rectangle
      y += 10;
      pdf.setLineWidth(0.5);
      pdf.rect(margin, y, pageWidth - margin * 2, 30);

      pdf.setFont('helvetica', 'bold');
      pdf.text("Please ship material to:", margin + 10, y + 10);

      // Plant location for shipping
      pdf.setFont('helvetica', 'normal');
      const returnPlant = formData.ReturnPlant || "";
      if (returnPlant === "Detroit") {
        pdf.text("Magni Industries, Inc.", margin + 60, y + 10);
        pdf.text("2771 Hammond St - Detroit, MI 48209", margin + 60, y + 17);
      } else if (returnPlant === "Kentucky") {
        pdf.text("Magni Industries, Inc.", margin + 60, y + 10);
        pdf.text("10250 Toebben Drive - Independence, KY 41051", margin + 60, y + 17);
      } else {
        pdf.text("[Please specify return location]", margin + 60, y + 10);
      }

      pdf.setFont('helvetica', 'bold');
      pdf.text("Please sign and fax this form to:", margin + 10, y + 25);
      pdf.setFont('helvetica', 'normal');
      if (returnPlant === "Detroit") {
        pdf.text("(123)-456-7890", margin + 90, y + 25);
      }
      else if (returnPlant === "Kentucky") {
        pdf.text("(123)-456-7890", margin + 90, y + 25);
      }
      else {
        pdf.text("", margin + 90, y + 25);
      }

      // Return conditions section
      y += 40;
      pdf.setFont('helvetica', 'bold');
      pdf.text("This return is being authorized under the following conditions:", margin, y);

      // Determine return condition based on RMA data
      let returnCondition = "";
      if (RMAitems && RMAitems.length > 0) {
        returnCondition = formData.ReturnCondition || "";
      }

      // Checkbox for "Return Material for Credit"
      y += 10;
      pdf.rect(margin, y - 4, 5, 5);
      if (returnCondition === "CREDIT") {
        pdf.text("X", margin + 1, y);
      }
      pdf.setFont('helvetica', 'normal');
      pdf.text("Return Material for Credit", margin + 10, y);

      // Checkbox for "Return for Evaluation"
      y += 8;
      pdf.rect(margin, y - 4, 5, 5);
      if (returnCondition === "EVAL") {
        pdf.text("X", margin + 1, y);
      }
      pdf.text("Return for Evaluation-- Credit determined on basis of evaluation", margin + 10, y);

      // Checkbox for "Material to be Remixed"
      y += 8;
      pdf.rect(margin, y - 4, 5, 5);
      if (returnCondition === "REMIX") {
        pdf.text("X", margin + 1, y);
      }
      pdf.text("Material to be Remixed and Returned", margin + 10, y);

      // Remix charges
      y += 5;
      pdf.text("* Charge of $50.00 per tote, $25.00 per drum for Remix", margin + 15, y);

      y += 5;
      pdf.text("* No Charge for material returned within 60 days of shipment", margin + 15, y);

      // Restocking charge
      y += 10;
      pdf.setFont('helvetica', 'bold');
      pdf.text("Restocking Charge if Applicable:", margin, y);
      pdf.rect(margin + 80, y - 5, 20, 7);

      // Freight information
      y += 10;
      pdf.setFont('helvetica', 'normal');
      pdf.text("* Freight charges for return materials are to be prepaid.", margin, y);

      y += 5;
      pdf.text("* Any materials returned to applicator are sent freight collect.", margin, y);

      // Signature lines
      y += 15;
      pdf.line(margin, y, margin + 70, y);
      pdf.line(pageWidth - margin - 70, y, pageWidth - margin, y);

      y += 5;
      pdf.setFont('helvetica', 'normal');
      pdf.setFontSize(8);
      pdf.text("Authorized Magni Signature", margin + 10, y);
      pdf.text("Authorized Customer Signature", pageWidth - margin - 60, y);

      // Comments section rectangle
      y += 10;
      pdf.setLineWidth(0.5);
      pdf.rect(margin, y, pageWidth - margin * 2, 35);

      pdf.setFont('helvetica', 'bold');
      pdf.setFontSize(10);
      pdf.text("Comments (Magni Receiving): " + "RMA COMMENTS HERE", margin + 5, y + 7);

      // Add any comments from RMA
      pdf.setFont('helvetica', 'normal');
      pdf.setFontSize(9);

      // Inside the rectangle, leave space for comments

      // Signature line at bottom of comments section
      y += 30;
      pdf.setFontSize(10);
      pdf.setFont('helvetica', 'bold');
      pdf.text("Magni Signature:", margin + 5, y);
      pdf.line(margin + 40, y, margin + 100, y);

      // Date rectangle at bottom
      y += 10;
      pdf.rect(margin, y, pageWidth - margin * 2, 10);

      // Add today's date
      pdf.setFont('helvetica', 'normal');
      pdf.text(`Date: ${moment().format('MM/DD/YYYY')}`, margin + 5, y + 7);

      // Open PDF in a new tab instead of downloading
      const pdfData = pdf.output('datauristring');
      const newWindow = window.open();
      if (newWindow) {
        newWindow.document.write(`
        <html>
          <head>
            <title>RMA Form - CR${data}</title>
          </head>
          <body style="margin:0;padding:0;">
            <embed width="100%" height="100%" src="${pdfData}" type="application/pdf" />
          </body>
        </html>
      `);
      } else {
        // If popup was blocked, fall back to download
        toast.warning("Pop-up blocked. Please allow pop-ups or use the download option.");
        pdf.save(`RMA_Form_CR${data}_${moment().format('YYYYMMDD')}.pdf`);
      }

    } catch (error) {
      console.error("Error generating PDF:", error);
      toast.error("Failed to generate PDF");
    }
  };






  const generateCRPDF = async () => {
    try {
      //toast.info("Generating Condition Report PDF, please wait...");

      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
        format: 'a4'
      });

      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();
      const margin = 5;
      const textColor = [0, 0, 0];

      // Helper functions for positioning
      const centerX = pageWidth / 2;

      // Set up fonts and styles
      pdf.setFont('helvetica', 'bold');
      pdf.setTextColor(textColor[0], textColor[1], textColor[2]);

      // Header section - centered using the align option
      pdf.setFontSize(18);
      pdf.text("Condition Report", centerX, margin + 8, { align: 'center' });
      pdf.setFontSize(14);
      pdf.text("Magni Industries, Inc.", pageWidth - margin - 50, margin + 8);

      // CR Title (larger and bold) - centered using the align option
      let y = margin + 20;
      pdf.setFontSize(14);
      pdf.setFont('helvetica', 'bold');
      const titleText = `Title: ${formData.CRTitle || 'N/A'}`;
      pdf.text(titleText, centerX, y, { align: 'center' });

      // Section 1 heading
      y += 10;
      pdf.setFontSize(14);
      pdf.text("1. Condition Adverse to Quality or Safety", margin, y);

      // CR Number box on right side
      pdf.setDrawColor(0, 0, 0);
      pdf.setLineWidth(0.5);
      pdf.rect(pageWidth - 65, y - 5, 40, 18);
      pdf.text("CR Number", pageWidth - 60, y);
      pdf.setFontSize(14);
      pdf.text(`${data || 'N/A'}`, pageWidth - 60, y + 8);

      // Location identifier
      const location = formData.Location === "Kentucky" ? "KY" : formData.Location === "Detroit" ? "DET" : "";
      pdf.setFontSize(10);
      pdf.text(location, pageWidth - 30, y);

      // Description section
      y += 10;
      pdf.setFontSize(11);
      pdf.text("Analysis of Nonconformance/Condition:", margin, y);

      // Add the analysis text with proper spacing
      y += 7;
      pdf.setFontSize(10);
      pdf.setFont('helvetica', 'normal');
      const analysisText = formData.InitialAnalysis || "";
      const splitAnalysis = pdf.splitTextToSize(analysisText, 100); // Reduced width to prevent overlap
      pdf.text(splitAnalysis, margin, y);

      // Calculate proper spacing after analysis text
      const analysisHeight = splitAnalysis.length * 5;
      y += analysisHeight + 15; // Add extra spacing after analysis

      // Fixed position for return category box - regardless of analysis length
      const fixedBoxY = margin + 55; // Moved up 25mm from original position of 80
      pdf.rect(pageWidth - 95, fixedBoxY - 10, 85, 50);
      pdf.text("Return Category:", pageWidth - 90, fixedBoxY - 1);
      pdf.setFontSize(9);
      pdf.setFont('helvetica', 'normal');
      pdf.text(formData.ReturnCategory || "N/A", pageWidth - 50, fixedBoxY - 1);

      // Formal complaint checkbox - fixed position relative to box
      const complaintY = fixedBoxY + 5;
      pdf.setFont('helvetica', 'bold');
      pdf.setFontSize(10);
      pdf.rect(pageWidth - 90, complaintY - 4, 5, 5);
      if (formData.CustomerComplaint) {
        pdf.text("X", pageWidth - 89, complaintY);
      }
      pdf.text("Formal complaint submitted?", pageWidth - 80, complaintY);

      // Customer feedback category
      const feedbackY = complaintY + 10;
      pdf.setFont('helvetica', 'bold');
      pdf.setFontSize(10);
      pdf.text("Customer Feedback Category:", pageWidth - 90, feedbackY);
      pdf.setFont('helvetica', 'normal');
      pdf.setFontSize(9);

      // Handle multiline text for potentially long feedback category
      const feedbackCategory = formData.CustomerFeedbackCategory || "N/A";
      const splitFeedback = pdf.splitTextToSize(feedbackCategory, 60);
      pdf.text(splitFeedback, pageWidth - 90, feedbackY + 5);

      // Customer complaint
      const customerComplaintY = feedbackY + 15;
      pdf.setFont('helvetica', 'bold');
      pdf.setFontSize(10);
      pdf.text("Customer Complaint:", pageWidth - 90, customerComplaintY);
      pdf.setFont('helvetica', 'normal');
      pdf.setFontSize(9);

      // Handle multiline text for potentially long complaint
      const complaint = formData.CustomerComplaint || "N/A";
      const splitComplaint = pdf.splitTextToSize(complaint, 60);
      pdf.text(splitComplaint, pageWidth - 90, customerComplaintY + 5);

      // Reset y position for left side content - position after analysis
      y = margin + 30 + splitAnalysis.length * 5 + 15; // Start after analysis text

      // Serial Number - positioned after analysis with proper spacing
      pdf.setFont('helvetica', 'bold');
      pdf.setFontSize(11);
      pdf.text("Serial #:", margin, y);
      pdf.setFont('helvetica', 'normal');
      pdf.text(formData.SerialNumber || "", margin + 20, y);

      // CR Description label
      y += 10;
      pdf.setFont('helvetica', 'bold');
      pdf.setFontSize(11);
      pdf.text("Description:", margin, y);

      // Description text (multiline)
      y += 5;
      pdf.setFontSize(10);
      pdf.setFont('helvetica', 'normal');
      const crDescription = formData.CRDescription || "";
      const splitDescription = pdf.splitTextToSize(crDescription, 100);
      pdf.text(splitDescription, margin, y);

      // Adjust y position based on description length
      y += Math.min(splitDescription.length * 5, 40);

      // Check if we need to add a new page
      if (y > pageHeight - 60) {
        pdf.addPage();
        y = margin + 10;
        // Add header to new page
        pdf.setFontSize(16);
        pdf.setFont('helvetica', 'bold');
        pdf.text("Nonconformance/Condition Report (Continued)", margin, margin + 8);
        pdf.setFontSize(10);
        pdf.text(`CR ${data || 'N/A'}`, pageWidth - margin - 20, margin + 8, { align: 'right' });
      }

      // List of possible causes
      y += 10;
      pdf.setFont('helvetica', 'bold');
      pdf.setFontSize(11);
      pdf.text("Possible Causes/Failure Mechanisms:", margin, y);

      // Causes text (multiline)
      y += 5;
      pdf.setFontSize(10);
      pdf.setFont('helvetica', 'normal');
      const possibleCause = formData.PossibleCause || "";
      const splitCauses = pdf.splitTextToSize(possibleCause, 100); // Reduced width to prevent overlap
      pdf.text(splitCauses, margin, y);

      // Adjust y position based on causes text length
      y += Math.min(splitCauses.length * 5, 40);

      // Check if we need to add a new page
      if (y > pageHeight - 80) {
        pdf.addPage();
        y = margin + 10;
        // Add header to new page
        pdf.setFontSize(16);
        pdf.setFont('helvetica', 'bold');
        pdf.text("Nonconformance/Condition Report (Continued)", margin, margin + 8);
        pdf.setFontSize(10);
        pdf.text(`CR ${data || 'N/A'}`, pageWidth - margin - 20, margin + 8, { align: 'right' });
      }

      // Company, Product, Initiator section (left side)
      y += 10;
      pdf.setFont('helvetica', 'bold');
      pdf.setFontSize(11);
      pdf.text("Company name:", margin, y);
      pdf.setFont('helvetica', 'normal');
      pdf.text(formData.Company || "N/A", margin + 40, y);



      y += 8;
      pdf.setFont('helvetica', 'bold');
      pdf.text("Initiator:", margin, y);
      pdf.setFont('helvetica', 'normal');
      pdf.text(formData.Initiator || "N/A", margin + 40, y);

      // RMA form needed checkbox
      y += 8;
      pdf.setFont('helvetica', 'bold');
      pdf.rect(margin, y - 4, 5, 5);
      const rmaNeeded = RMAitems && RMAitems.length > 0;
      if (rmaNeeded) {
        pdf.text("X", margin + 1, y);
      }
      pdf.text("RMA form needed?", margin + 10, y);

      // Drums, Gallons, Weight, Lot, Date (right side)
      const rightColumn = pageWidth / 2 + 10;
      y = y - 24; // Reset y to match the first item in left column



      // Count drums from RMA items
      let drumCount = 0;
      let drumGallons = 0;
      let drumWeight = 0;
      let lotNumber = "";

      // Process RMA items to get values
      RMAitems.forEach(item => {
        if (item.ContainerType === "Drum") {
          drumCount += item.ContainerNum;
          drumGallons += item.GallonNum;
          drumWeight += item.Weight;
        }
        if (!lotNumber && item.Lot) {
          lotNumber = item.Lot;
        }
      });




      y += 8;
      pdf.setFont('helvetica', 'bold');
      pdf.text("Product ID:", rightColumn, y);
      pdf.setFont('helvetica', 'normal');
      pdf.text(formData.Product || "N/A", rightColumn + 40, y);

      y += 8;
      pdf.setFont('helvetica', 'bold');
      pdf.text("Date Initiated:", rightColumn, y);
      pdf.setFont('helvetica', 'normal');
      pdf.text(formData.DateInitiated ? moment(formData.DateInitiated).format('MM/DD/YYYY') : "N/A", rightColumn + 40, y);

      // Add RMA table if there are RMA items
      if (rmaNeeded) {
        // Check if we need to add a new page for the RMA table
        if (y > pageHeight - 100) {
          pdf.addPage();
          y = margin + 10;
          // Add header to new page
          pdf.setFontSize(16);
          pdf.setFont('helvetica', 'bold');
          pdf.text("Nonconformance/Condition Report (Continued)", margin, margin + 8);
          pdf.setFontSize(10);
          pdf.text(`CR ${data || 'N/A'}`, pageWidth - margin - 20, margin + 8, { align: 'right' });
        }

        y += 20;
        pdf.setFont('helvetica', 'bold');
        pdf.setFontSize(12);
        pdf.text("Return Material Authorization Items", margin, y);

        // Table headers
        y += 10;
        pdf.setFontSize(10);
        pdf.setLineWidth(0.2);

        // Define column widths based on content
        const colWidths = [30, 25, 25, 25, 40];
        const tableWidth = colWidths.reduce((a, b) => a + b, 0);
        const tableStart = margin;

        // Draw table header background
        pdf.setFillColor(240, 240, 240);
        pdf.rect(tableStart, y - 5, tableWidth, 10, 'F');

        // Draw header cell borders
        pdf.setDrawColor(0);
        pdf.line(tableStart, y - 5, tableStart, y + 5); // left border
        pdf.line(tableStart, y - 5, tableStart + tableWidth, y - 5); // top border
        pdf.line(tableStart + tableWidth, y - 5, tableStart + tableWidth, y + 5); // right border
        pdf.line(tableStart, y + 5, tableStart + tableWidth, y + 5); // bottom border

        // Draw header cell dividers
        let xPos = tableStart;
        for (let i = 0; i < colWidths.length - 1; i++) {
          xPos += colWidths[i];
          pdf.line(xPos, y - 5, xPos, y + 5);
        }

        // Add table headers
        pdf.setFont('helvetica', 'bold');
        pdf.text("Container Type", tableStart + 2, y);
        pdf.text("Container #", tableStart + colWidths[0] + 2, y);
        pdf.text("Gallons", tableStart + colWidths[0] + colWidths[1] + 2, y);
        pdf.text("Weight", tableStart + colWidths[0] + colWidths[1] + colWidths[2] + 2, y);
        pdf.text("Lot Number", tableStart + colWidths[0] + colWidths[1] + colWidths[2] + colWidths[3] + 2, y);

        // Add table rows
        pdf.setFont('helvetica', 'normal');
        y += 10;

        for (const item of RMAitems) {
          // Check if we need a new page for the next row
          if (y > pageHeight - 15) {
            pdf.addPage();
            y = margin + 20;
            // Add header to new page
            pdf.setFontSize(16);
            pdf.setFont('helvetica', 'bold');
            pdf.text("RMA Items (Continued)", margin, margin + 8);
            pdf.setFontSize(10);
            pdf.text(`CR ${data || 'N/A'}`, pageWidth - margin - 20, margin + 8, { align: 'right' });

            // Redraw table headers on new page
            pdf.setFontSize(10);
            pdf.setFont('helvetica', 'bold');
            pdf.setFillColor(240, 240, 240);
            pdf.rect(tableStart, y - 5, tableWidth, 10, 'F');

            // Draw header borders
            pdf.setDrawColor(0);
            pdf.line(tableStart, y - 5, tableStart, y + 5);
            pdf.line(tableStart, y - 5, tableStart + tableWidth, y - 5);
            pdf.line(tableStart + tableWidth, y - 5, tableStart + tableWidth, y + 5);
            pdf.line(tableStart, y + 5, tableStart + tableWidth, y + 5);

            // Draw header dividers
            xPos = tableStart;
            for (let i = 0; i < colWidths.length - 1; i++) {
              xPos += colWidths[i];
              pdf.line(xPos, y - 5, xPos, y + 5);
            }

            // Add table headers again
            pdf.text("Container Type", tableStart + 2, y);
            pdf.text("Container #", tableStart + colWidths[0] + 2, y);
            pdf.text("Gallons", tableStart + colWidths[0] + colWidths[1] + 2, y);
            pdf.text("Weight", tableStart + colWidths[0] + colWidths[1] + colWidths[2] + 2, y);
            pdf.text("Lot Number", tableStart + colWidths[0] + colWidths[1] + colWidths[2] + colWidths[3] + 2, y);

            pdf.setFont('helvetica', 'normal');
            y += 10;
          }

          // Draw row borders
          pdf.setDrawColor(200, 200, 200);
          pdf.line(tableStart, y + 5, tableStart + tableWidth, y + 5); // bottom border
          pdf.line(tableStart, y - 5, tableStart, y + 5); // left border
          pdf.line(tableStart + tableWidth, y - 5, tableStart + tableWidth, y + 5); // right border

          // Draw cell dividers
          xPos = tableStart;
          for (let i = 0; i < colWidths.length - 1; i++) {
            xPos += colWidths[i];
            pdf.line(xPos, y - 5, xPos, y + 5);
          }

          // Add row data
          pdf.text(item.ContainerType || "N/A", tableStart + 2, y);
          pdf.text(item.ContainerNum.toString() || "0", tableStart + colWidths[0] + 2, y);
          pdf.text(item.GallonNum.toString() || "0", tableStart + colWidths[0] + colWidths[1] + 2, y);
          pdf.text(item.Weight.toString() || "0", tableStart + colWidths[0] + colWidths[1] + colWidths[2] + 2, y);
          pdf.text(item.Lot || "N/A", tableStart + colWidths[0] + colWidths[1] + colWidths[2] + colWidths[3] + 2, y);

          y += 10;
        }
      }

      // Add a blank space after the RMA table if it exists
      if (rmaNeeded) {
        y += 5;
      }
      else {
        y += 15;
      }

      // Check if we need to add a new page for Section 2
      if (y > pageHeight - 60) {
        pdf.addPage();
        y = margin + 10;
        // Add header to new page
        pdf.setFontSize(16);
        pdf.setFont('helvetica', 'bold');
        pdf.text("Nonconformance/Condition Report (Continued)", margin, margin + 8);
        pdf.setFontSize(10);
        pdf.text(`CR ${data || 'N/A'}`, pageWidth - margin - 20, margin + 8, { align: 'right' });
      }

      // Line separating sections
      pdf.setDrawColor(0, 0, 0);
      pdf.setLineWidth(0.5);
      pdf.line(margin, y, pageWidth - margin, y);

      // Section 2 - Significance Determination
      y += 10;
      pdf.setFont('helvetica', 'bold');
      pdf.setFontSize(14);
      pdf.text("2. Significance Determination", margin, y);
      pdf.setFontSize(10);
      //pdf.text("Q.A. Manager", margin, y + 7);

      // Level selection
      y += 15;
      pdf.setFont('helvetica', 'bold');
      pdf.text("Level Selected:", margin, y);
      pdf.setFont('helvetica', 'normal');

      let levelText = "N/A";
      switch (formData.CRLevel) {
        case 1:
          levelText = "Level 1 - Multidisciplinary Corrective Action";
          break;
        case 2:
          levelText = "Level 2 - Single Evaluator Corrective Action";
          break;
        case 3:
          levelText = "Level 3 - Disposition Determination";
          break;
        case 4:
          levelText = "Level 4 - Trend Determination";
          break;
        case 5:
          levelText = "Level 5 - Customer Accommodation";
          break;
        case 6:
          levelText = "Level 6 - Unknown";
          break;
        case 7:
          levelText = "S.C.A.R.";
          break;
        default:
          levelText = "Not specified";
      }

      pdf.text(levelText, margin + 40, y);

      // Corrective Action Needed checkbox (right side)
      pdf.setFont('helvetica', 'bold');
      pdf.rect(pageWidth - 70, y - 4, 5, 5);
      if (formData.CARequired) {
        pdf.text("X", pageWidth - 69, y);
      }
      pdf.text("Corrective Action needed?", pageWidth - 60, y);

      // Comments section
      y += 15;
      pdf.setFont('helvetica', 'bold');
      pdf.text("Comments:", margin, y);

      // Check if we need to add a new page for comments
      if (y > pageHeight - 60) {
        pdf.addPage();
        y = margin + 20;
        // Add header to new page
        pdf.setFontSize(16);
        pdf.setFont('helvetica', 'bold');
        pdf.text("Nonconformance/Condition Report (Continued)", margin, margin + 8);
        pdf.setFontSize(10);
        pdf.text(`CR ${data || 'N/A'}`, pageWidth - margin - 20, margin + 8, { align: 'right' });

        pdf.setFont('helvetica', 'bold');
        pdf.setFontSize(11);
        pdf.text("Comments (continued):", margin, y);
      }

      y += 8;
      pdf.setFont('helvetica', 'normal');
      const comments = formData.InitialComments || "N/A";
      const splitComments = pdf.splitTextToSize(comments, 170);
      pdf.text(splitComments, margin, y);

      // Adjust y position based on comments length
      y += Math.min(splitComments.length * 5, 40);

      // Check if we need to add a new page for signature
      if (y > pageHeight - 30) {
        pdf.addPage();
        y = margin + 10;
        // Add header to new page
        pdf.setFontSize(16);
        pdf.setFont('helvetica', 'bold');
        pdf.text("Nonconformance/Condition Report (Continued)", margin, margin + 8);
        pdf.setFontSize(10);
        pdf.text(`CR ${data || 'N/A'}`, pageWidth - margin - 20, margin + 8, { align: 'right' });
      }

      // Signature line
      y += 10;
      pdf.setFont('helvetica', 'bold');
      pdf.text("Signature:", margin, y);
      pdf.line(margin + 30, y, margin + 90, y);

      // Find QC Manager name from user list
      let qcManagerName = "";
      if (formData.QCManagerSignOff && CRUsers) {
        const qcManager = CRUsers.find(user => user.userID === formData.QCManagerSignOff);
        if (qcManager) {
          qcManagerName = qcManager.FullName;
        }
      }
      pdf.setFont('helvetica', 'normal');
      pdf.text(qcManagerName, margin + 35, y - 2);

      // Add footer with date on all pages
      const pageCount = pdf.internal.pages.length - 1; // -1 because jsPDF uses 1-based indexing but array is 0-based
      for (let i = 1; i <= pageCount; i++) {
        pdf.setPage(i);
        pdf.setFont('helvetica', 'italic');
        pdf.setFontSize(8);
        pdf.text(`${moment().format('MM/DD/YYYY')} - Page ${i} of ${pageCount}`, pageWidth - margin, pageHeight - margin, { align: 'right' });
      }

      // Open PDF in a new tab instead of downloading
      const pdfData = pdf.output('datauristring');
      const newWindow = window.open();
      if (newWindow) {
        newWindow.document.write(`
        <html>
          <head>
            <title>Condition Report - CR${data}</title>
          </head>
          <body style="margin:0;padding:0;">
            <embed width="100%" height="100%" src="${pdfData}" type="application/pdf" />
          </body>
        </html>
      `);
      } else {
        // If popup was blocked, fall back to download
        toast.warning("Pop-up blocked. Please allow pop-ups or use the download option.");
        pdf.save(`Condition_Report_CR${data}_${moment().format('YYYYMMDD')}.pdf`);
      }

    } catch (error) {
      console.error("Error generating Condition Report PDF:", error);
      toast.error("Failed to generate Condition Report PDF");
    }
  };

  const HandleProcessChange = async () => {
      try {
        let response;
        let dataToSubmit = { ...formData };
        
        // Ensure ProcessChangeObject is initialized with the CR number if it doesn't exist
        //if (!dataToSubmit.ProcessChangeObject) {
          dataToSubmit = {
            ...dataToSubmit,
            ProcessChangeObject: {
              ID: formData.ProcessChangeObject?.ID || 0,
              CRNumber: dataToSubmit.CRNumber,
              ProcessChangeDescription: formData.ProcessChangeObject?.ProcessChangeDescription || '',
              EquipmentImpacted: formData.ProcessChangeObject?.EquipmentImpacted || '',
              PartNumbersImpacted: formData.ProcessChangeObject?.PartNumbersImpacted || '',
              StaffApproval: formData.ProcessChangeObject?.StaffApproval || false,
              StaffApprovalDate: formData.ProcessChangeObject?.StaffApprovalDate || null,
              CustomerNotificationRequired: formData.ProcessChangeObject?.CustomerNotificationRequired || false,
              DateNotified: formData.ProcessChangeObject?.DateNotified || null,
              ProcessChangeLaunchDate: formData.ProcessChangeObject?.ProcessChangeLaunchDate || null,
              CustomerApprovalReceived: formData.ProcessChangeObject?.CustomerApprovalReceived || false,
              DateSent: formData.ProcessChangeObject?.DateSent || null,
              DateApproved: formData.ProcessChangeObject?.DateApproved || null,
              LaunchFeedback: formData.ProcessChangeObject?.LaunchFeedback || ''
            }
          };
          
          // Update the state with the new ProcessChangeObject
          setFormData(dataToSubmit);
        //}
        
        // Check if ProcessChangeObject already exists and has an ID
        if (dataToSubmit.ProcessChangeObject && dataToSubmit.ProcessChangeObject.ID > 0) {
          console.log(dataToSubmit.ProcessChangeObject);
          response = await apiCalls.EditProcess(dataToSubmit.ProcessChangeObject);
          console.log(response);
        } else {
          console.log(dataToSubmit.ProcessChangeObject);
          response = await apiCalls.AddProcess(dataToSubmit.ProcessChangeObject);
        }
        
        // If successful, close the modal and show success message
        if (response && response.status === 200) {
          setProcessChangeModal(false);
          formData.ProcessChangeObject = dataToSubmit.ProcessChangeObject;
          if (formData.ProcessChangeObject) {
            formData.ProcessChangeObject.ID = 1;
          }
        } else {
          // If not successful, show error message but keep modal open
          toast.error("Failed to update process change details. Please try again.");
        }
      } catch (error) {
        console.error("Error updating process change:", error);
        toast.error("An error occurred while updating process change details.");
      }
    }
  
  const generateProcessPDF = async () => {
    try {
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
        format: 'a4'
      });

      const pageWidth = pdf.internal.pageSize.getWidth();
      const margin = 10;
      const textColor = [0, 0, 0]; // Black color

      // Set up fonts and styles
      pdf.setFont('helvetica', 'bold');
      pdf.setTextColor(textColor[0], textColor[1], textColor[2]);

      // Add title and subtitle
      pdf.setFontSize(16);
      pdf.text('Magni Industries, Inc.', pageWidth / 2, margin + 10, { align: 'center' });
      pdf.setFontSize(14);
      pdf.text('Process Change Approval Report', pageWidth / 2, margin + 20, { align: 'center' });

      // Draw rectangle for main content
      let y = margin + 30;
      pdf.setDrawColor(textColor[0], textColor[1], textColor[2]);
      pdf.setLineWidth(0.5);
      const rectHeight = 150; // Adjusted height
      pdf.rect(margin, y, pageWidth - margin * 2, rectHeight);

      // Add content inside the rectangle
      pdf.setFontSize(10);
      y += 10;
      pdf.text(`CR Number: ${formData.CRNumber || 'N/A'}`, margin + 5, y);
      y += 10;
      pdf.text(`CR Title: ${formData.CRTitle || 'N/A'}`, margin + 5, y);
      y += 10;
      const processDescription = pdf.splitTextToSize(`Process Change Description: ${formData.ProcessChangeObject?.ProcessChangeDescription || 'N/A'}`, pageWidth - margin * 2 - 10);
      pdf.text(processDescription, margin + 5, y);
      y += processDescription.length > 1 ? processDescription.length * 5 : 5;
      pdf.text(`Equipment Impacted: ${formData.ProcessChangeObject?.EquipmentImpacted || 'N/A'}`, margin + 5, y);
      y += 10;
      pdf.text(`Part Numbers Impacted: ${formData.ProcessChangeObject?.PartNumbersImpacted || 'N/A'}`, margin + 5, y);
      y += 10;
      pdf.rect(margin + 5, y - 3, 4, 4);
      if (formData.ProcessChangeObject?.StaffApproval) pdf.text('X', margin + 6, y);
      pdf.text('Staff Approval', margin + 12, y);
      y += 10;
      pdf.text(`Staff Approval Date: ${formData.ProcessChangeObject?.StaffApprovalDate || 'N/A'}`, margin + 5, y);
      y += 10;
      pdf.rect(margin + 5, y - 3, 4, 4);
      if (formData.ProcessChangeObject?.CustomerNotificationRequired) pdf.text('X', margin + 6, y);
      pdf.text('Customer Notification Required', margin + 12, y);
      y += 10;
      pdf.text(`Date Notified: ${formData.ProcessChangeObject?.DateNotified || 'N/A'}`, margin + 5, y);
      y += 10;
      pdf.text(`Process Change Launch Date: ${formData.ProcessChangeObject?.ProcessChangeLaunchDate || 'N/A'}`, margin + 5, y);
      y += 10;
      const launchFeedback = pdf.splitTextToSize(`Launch Feedback: ${formData.ProcessChangeObject?.LaunchFeedback || 'N/A'}`, pageWidth - margin * 2 - 10);
      pdf.text(launchFeedback, margin + 5, y);
      y += launchFeedback.length > 1 ? launchFeedback.length * 5 : 5;
      pdf.rect(margin + 5, y - 3, 4, 4);
      if (formData.ProcessChangeObject?.CustomerApprovalReceived) pdf.text('X', margin + 6, y);
      pdf.text('Customer Approval Received', margin + 12, y);
      y += 10;
      pdf.text(`Date Approved: ${formData.ProcessChangeObject?.DateApproved || 'N/A'}`, margin + 5, y);

      // Add today's date at the bottom
      y += 20;
      pdf.setFontSize(10);
      pdf.text(`Date: ${new Date().toLocaleDateString()}`, margin + 5, y);

      // Open PDF in a new tab instead of downloading
      const pdfData = pdf.output('datauristring');
      const newWindow = window.open();
      if (newWindow) {
        newWindow.document.write(`
        <html>
          <head>
            <title>Process Change Approval Report</title>
          </head>
          <body style="margin:0;padding:0;">
            <embed width="100%" height="100%" src="${pdfData}" type="application/pdf" />
          </body>
        </html>
      `);
      } else {
        // If popup was blocked, fall back to download
        toast.warning("Pop-up blocked. Please allow pop-ups or use the download option.");
        pdf.save(`Process_Change_Approval_Report_${new Date().toISOString().slice(0, 10)}.pdf`);
      }

    } catch (error) {
      console.error("Error generating PDF:", error);
      toast.error("Failed to generate PDF");
    }
  };








  return (

    <div style={{ minHeight: "100vh", backgroundColor: '#0e226a', padding: "2rem", }}>
      <Header2 />
      {loadingPage ?
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <BounceLoader color={"#123abc"} loading={true} size={100} />
        </div>
        :

        <div style={{ width: "100%", margin: "0 auto", backgroundColor: "#efefef", padding: "3rem", borderRadius: "8px", color: "#ECF0F1" }}>
          <div className="row row-cols-1 row-cols-md-1 g-4" style={{ backgroundColor: "#efefef", }}>
            <div className="col" style={{
              backgroundColor: '#efefef'
            }}>
              <div className="card" style={{
                backgroundColor: '#efefef'
              }}>
                <div style={{ textAlign: "center", }}>
                  <Button onClick={() => nav('/conditionreports/')} style={{ ...dropDownStyle, backgroundColor: "#0e226a", fontSize: "18px", border: "none", color: "#fff", marginRight: "10px" }}>
                    Return to Condition Reports
                  </Button>
                  <Button onClick={generateRMAPDF} style={{ ...dropDownStyle, backgroundColor: "#0e226a", fontSize: "18px", border: "none", color: "#fff", marginRight: "10px" }}>
                    RMA Report
                  </Button>
                  <Button onClick={generateCRPDF} style={{ ...dropDownStyle, backgroundColor: "#0e226a", fontSize: "18px", border: "none", color: "#fff", marginRight: "10px" }}>
                    Condition Report
                  </Button>
                  <Button onClick={generateProcessPDF} style={{ ...dropDownStyle, backgroundColor: "#0e226a", fontSize: "18px", border: "none", color: "#fff" }}>
                    Process Change Report
                  </Button> 
                </div>
                <div className="card-body" style={{ backgroundColor: "#efefef", }}>
                  <div className="container text-center response-text" style={{ marginBottom: 0, backgroundColor: "#efefef", }}>
                    <div className="mt-3" style={{ backgroundColor: "#efefef", }} >

                      <form onSubmit={HandleSubmit} style={{ backgroundColor: "#efefef", }}>
                        <ModalBody style={{ paddingLeft: '0px', maxHeight: '90%', backgroundColor: "#efefef", }}>
                          <h5 style={{ textAlign: "center", fontSize: 30 }}>  Details for Report {data} </h5>
                          <h5 style={{ textAlign: "center", fontSize: 24 }}>  Initiator </h5>

                          <FormGroup
                                className="input-item"
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  width: '100%',
                                  justifyContent: 'flex-end',
                                  paddingRight: '150px',
                                  marginBottom: '20px'
                                }}
                              >
                                <Label
                                  for="Location"
                                  style={{
                                    width: '150px',
                                    textAlign: 'right',
                                    marginRight: '10px',
                                  }}
                                >
                                  Location
                                </Label>
                                <select
                                  value={formData.Location || ''}
                                  className="form-select"
                                  id="Location"
                                  name="Location"
                                  onChange={handleChange}
                                  disabled={true}
                                  style={{
                                    ...dropDownStyle,
                                    marginLeft: '20px',
                                    width: '250px',
                                  }}
                                >
                                  <option value="">Select an option</option>
                                  <option value="D">
                                    Detroit
                                  </option>
                                  <option value="K">
                                    Kentucky
                                  </option>

                                </select>
                              </FormGroup>

                          <Row className="mx-auto justify-content-center align-items-center" style={{ marginBottom: '25px', }}>




                            <div
                              className="form-section-container"
                               style={{

                                padding: '10px',
                                borderRadius: '8px',
                                maxWidth: '300px',
                                marginLeft: '00px',

                               }}
                            >

                              <FormGroup style={{ display: 'flex', flexDirection: 'column' }}>
                                <Label for="CRTitle" style={{}}>Report Name *</Label>
                                <Input
                                  id="CRTitle"
                                  name="CRTitle"
                                  type="text"
                                  value={formData.CRTitle === null ? '' : formData.CRTitle}
                                  onChange={handleChange}
                                  style={{ width: '100%', }}
                                />
                              </FormGroup>

                              <FormGroup style={{ display: 'flex', flexDirection: 'column' }}>
                                <Label for="DateCreated" style={{}}>Date Created</Label>
                                <Input
                                  id="DateCreated"
                                  name="DateCreated"
                                  type="date"
                                  value={moment(formData.DateInitiated).format('YYYY-MM-DD')}
                                  onChange={handleChange}
                                  disabled={true}
                                  style={{ width: '100%', }}
                                />
                              </FormGroup>

                              <FormGroup style={{ display: 'flex', flexDirection: 'column' }}>
                                <Label for="SerialNumber" style={{}}>Serial Number</Label>
                                <Input
                                  id="SerialNumber"
                                  name="SerialNumber"
                                  type="text"
                                  value={formData.SerialNumber === null ? '' : formData.SerialNumber}
                                  onChange={handleChange}
                                  disabled={isDisabled}
                                  style={{ width: '100%', }}
                                />
                              </FormGroup>
                            </div>







                            <div className="form-section-container" style={{ padding: '15px', borderRadius: '8px', maxWidth: '300px', marginLeft: '00px', }}>

                              <FormGroup style={{ display: 'flex', flexDirection: 'column', marginBottom: '0px' }}>
                                <Label for="MagniDetroitCR">Magni Detroit CR</Label>
                                <Input
                                  id="MagniDetroitCR"
                                  name="MagniDetroitCR"
                                  type="text"
                                  value={formData.MagniDetroitCR === null ? '' : formData.MagniDetroitCR}
                                  onChange={handleChange}
                                  disabled={isDisabled}
                                  style={{ width: '100%' }}
                                />
                              </FormGroup>

                              <FormGroup style={{ display: 'flex', flexDirection: 'column', marginBottom: '0px' }}>
                                <Label for="AnalyticalReport">Analytical Report</Label>
                                <Input
                                  id="AnalyticalReport"
                                  name="AnalyticalReport"
                                  type="text"
                                  value={formData.AnalyticalReport === null ? '' : formData.AnalyticalReport}
                                  onChange={handleChange}
                                  disabled={isDisabled}
                                  style={{ width: '100%' }}
                                />
                              </FormGroup>

                              <FormGroup style={{ display: 'flex', flexDirection: 'column' }}>
                                <Label for="Department">Department</Label>
                                <Input
                                  id="Department"
                                  name="Department"
                                  type="text"
                                  value={formData.Department === null ? '' : formData.Department}
                                  onChange={handleChange}
                                  disabled={isDisabled}
                                  style={{ width: '100%' }}
                                />
                              </FormGroup>
                            </div>


                            <div
                              className="form-section-container"
                              style={{

                                padding: '10px',
                                borderRadius: '8px',
                                maxWidth: '160px',
                                marginLeft: '00px',
                                /*transform: 'scale(0.9)',  Shrinks everything proportionally */
                                //transformOrigin: 'top left'
                              }}
                            >
                              <h5 style={{ textAlign: 'center', marginBottom: '10px', }}>Non-Conformance Level</h5>

                              <FormGroup style={{ display: 'flex', flexDirection: 'column' }}>

                                <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <input
                                      id="Level1"
                                      name="Level1"
                                      type="checkbox"
                                      checked={formData.Level1}
                                      onChange={handleChange}
                                      style={{ width: '20px', height: '20px' }}
                                    />
                                    <label htmlFor="Level1" style={{ marginLeft: '8px' }}>Level 1</label>
                                  </div>

                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <input
                                      id="Level2"
                                      name="Level2"
                                      type="checkbox"
                                      checked={formData.Level2}
                                      onChange={handleChange}
                                      style={{ width: '20px', height: '20px' }}
                                    />
                                    <label htmlFor="Level2" style={{ marginLeft: '8px' }}>Level 2</label>
                                  </div>

                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <input
                                      id="Level3"
                                      name="Level3"
                                      type="checkbox"
                                      checked={formData.Level3}
                                      onChange={handleChange}
                                      style={{ width: '20px', height: '20px' }}
                                    />
                                    <label htmlFor="Level3" style={{ marginLeft: '8px' }}>Level 3</label>
                                  </div>
                                </div>
                              </FormGroup>
                            </div>



                            <div className="checkbox-container" style={{ border: '1px solid #000000', padding: '15px', borderRadius: '5px', maxWidth: '530px', transform: 'scale(0.9)', }}>
                              <Row>
                                <Col md={6}>
                                  <FormGroup check className="checkbox-item" style={{ display: 'flex', alignItems: 'center' }}>
                                    <Label check>
                                      <Input type="checkbox" id="DidNotFollowProcedure" name="DidNotFollowProcedure" checked={formData.DidNotFollowProcedure} onChange={handleChange} disabled={isDisabled} />
                                      {' '}Did Not Follow Procedure
                                    </Label>
                                  </FormGroup>
                                  <FormGroup check className="checkbox-item" style={{ display: 'flex', alignItems: 'center' }}>
                                    <Label check>
                                      <Input type="checkbox" id="AuditFinding" name="AuditFinding" checked={formData.AuditFinding} onChange={handleChange} disabled={isDisabled} />
                                      {' '}Audit Finding
                                    </Label>
                                  </FormGroup>
                                  <FormGroup check className="checkbox-item" style={{ display: 'flex', alignItems: 'center' }}>
                                    <Label check>
                                      <Input type="checkbox" id="SCARIssued" name="SCARIssued" checked={formData.SCARIssued} onChange={handleChange} disabled={isDisabled} />
                                      {' '}SCAR Issued
                                    </Label>
                                  </FormGroup>
                                  <FormGroup check className="checkbox-item" style={{ display: 'flex', alignItems: 'center' }}>
                                    <Label check>
                                      <Input type="checkbox" id="OpportunityForImprovement" name="OpportunityForImprovement" checked={formData.OpportunityForImprovement} onChange={handleChange} disabled={isDisabled} />
                                      {' '}Opportunity for Improvement
                                    </Label>
                                  </FormGroup>

                                  <FormGroup check className="checkbox-item" style={{ display: 'flex', alignItems: 'center' }}>
                                    <Label check>
                                      <Input type="checkbox" id="ProcessChange" name="ProcessChange" checked={formData.ProcessChange} onChange={handleChange} disabled={isDisabled} />
                                      {' '}Process Change
                                    </Label>
                                  </FormGroup>
                                  <Button onClick={() => setProcessChangeModal(true)} style={{ ...dropDownStyle, backgroundColor: "#0e226a", fontSize: "18px", border: "none", color: "#fff" }} disabled={!formData.ProcessChange}>
                                    Process Change
                                  </Button>
                                </Col>
                                <Col md={6}>
                                <FormGroup check className="checkbox-item" style={{ display: 'flex', alignItems: 'center' }}>
                                    <Label check>
                                      <Input type="checkbox" id="InternalAuditFinding" name="InternalAuditFinding" checked={formData.InternalAuditFinding} onChange={handleChange} disabled={isDisabled} />
                                      {' '}Internal Audit Finding
                                    </Label>
                                  </FormGroup>
                                  <FormGroup check className="checkbox-item" style={{ display: 'flex', alignItems: 'center' }}>
                                    <Label check>
                                      <Input type="checkbox" id="MISC" name="MISC" checked={formData.MISC} onChange={handleChange} disabled={isDisabled} />
                                      {' '}MISC
                                    </Label>
                                  </FormGroup>
                                  <FormGroup check className="checkbox-item" style={{ display: 'flex', alignItems: 'center' }}>
                                    <Label check>
                                      <Input type="checkbox" id="MeasurableFinding" name="MeasurableFinding" checked={formData.MeasurableFinding} onChange={handleChange} disabled={isDisabled} />
                                      {' '}Measurable Finding
                                    </Label>
                                  </FormGroup>
                                  <FormGroup check className="checkbox-item" style={{ display: 'flex', alignItems: 'center' }}>
                                    <Label check>
                                      <Input type="checkbox" id="Quarantine" name="Quarantine" checked={formData.Quarantine} onChange={handleChange} disabled={isDisabled} />
                                      {' '}Quarantine
                                    </Label>
                                  </FormGroup>
                                  <FormGroup check className="checkbox-item" style={{ display: 'flex', alignItems: 'center' }}>
                                    <Label check>
                                      <Input type="checkbox" id="ReclaimfromToteReturn" name="ReclaimfromToteReturn" checked={formData.ReclaimfromToteReturn} onChange={handleChange} disabled={isDisabled} />
                                      {' '}Reclaim from Tote Return
                                    </Label>
                                  </FormGroup>
                                  <FormGroup check className="checkbox-item" style={{ display: 'flex', alignItems: 'center' }}>
                                    <Label check>
                                      <Input type="checkbox" id="ResearchSupported" name="ResearchSupported" checked={formData.ResearchSupported} onChange={handleChange} disabled={isDisabled} />
                                      {' '}Research Supported
                                    </Label>
                                  </FormGroup>
                                </Col>
                              </Row>
                            </div>

                          </Row>



















                          <Row className="mt-3" style={{ display: 'flex', flexWrap: 'wrap' }}>
                            <Col sm={4}>
                              <FormGroup>
                                <Label for="Desc">Description *</Label>
                                <Input
                                  id="CRDescription"
                                  type="textarea"
                                  name="CRDescription"
                                  value={formData.CRDescription || ''}
                                  onChange={handleChange}
                                  disabled={isDisabled}
                                  style={{ width: '100%', height: '200px' }}
                                />
                              </FormGroup>
                            </Col>

                            <Col sm={4}>
                              <FormGroup>
                                <Label for="CFdesc">List the Possible Causes/Failure Mechanisms *</Label>
                                <Input
                                  id="PossibleCause"
                                  type="textarea"
                                  name="PossibleCause"
                                  value={formData.PossibleCause || ''}
                                  onChange={handleChange}
                                  disabled={isDisabled}
                                  style={{ width: '100%', height: '200px' }}
                                />
                              </FormGroup>
                            </Col>

                            <Col sm={4}>
                              <div
                                className="checkbox-container"
                                style={{

                                  padding: '15px',
                                  borderRadius: '8px',
                                  width: '100%',
                                  marginTop: '-32px'
                                }}
                              >
                                <h5 style={{ textAlign: 'center', marginBottom: '15px' }}>
                                  Only Required for External Reject or Customer Concern
                                </h5>

                                <FormGroup check style={{ marginBottom: '15px', display: 'flex', justifyContent: 'center' }}>
                                  <Label check>
                                    <Input
                                      type="checkbox"
                                      id="Complaint"
                                      name="Complaint"
                                      checked={formData.Complaint}
                                      onChange={handleChange}
                                      disabled={isDisabled}
                                    />
                                    {' '}Formal Complaint Submitted?
                                  </Label>
                                </FormGroup>

                                <FormGroup
                                  className="input-item"
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '100%',
                                  }}
                                >
                                  <Label
                                    for="CustomerFeedbackCategory"
                                    style={{
                                      width: '150px',
                                      textAlign: 'right',
                                      marginRight: '10px',
                                    }}
                                  >
                                    Customer Feedback Category
                                  </Label>
                                  <select
                                    value={formData.CustomerFeedbackCategory || ''}
                                    className="form-select"
                                    id="CustomerFeedbackCategory"
                                    name="CustomerFeedbackCategory"
                                    onChange={handleChange}
                                    disabled={isDisabled}
                                    style={{
                                      ...dropDownStyle,
                                      marginLeft: '20px',
                                      width: '250px',
                                    }}
                                  >
                                    <option value="">Select an option</option>
                                    <option value="Prod's performance, and other design features">
                                      Prod's performance, and other design features
                                    </option>
                                    <option value="Reliability and dependability of product">
                                      Reliability and dependability of product
                                    </option>
                                    <option value="Packaging">Packaging</option>
                                    <option value="Distribution and Delivery">
                                      Distribution and Delivery
                                    </option>
                                    <option value="Product Information">Product Information</option>
                                    <option value="Availability">Availability</option>
                                    <option value="Sales and Order Processing">
                                      Sales and Order Processing
                                    </option>
                                    <option value="After-Sales Support and Service">
                                      After-Sales Support and Service
                                    </option>
                                    <option value="Price">Price</option>
                                    <option value="Customer damaged at their site asked for repair">
                                      Customer damaged at their site asked for repair
                                    </option>
                                  </select>
                                </FormGroup>

                                <FormGroup
                                  className="input-item"
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '100%',
                                  }}
                                >
                                  <Label
                                    for="ReturnCategory"
                                    style={{
                                      width: '150px',
                                      textAlign: 'right',
                                      marginRight: '10px',
                                    }}
                                  >
                                    Return Category
                                  </Label>
                                  <select
                                    className="form-select"
                                    id="ReturnCategory"
                                    name="ReturnCategory"
                                    value={formData.ReturnCategory || ''}
                                    onChange={handleChange}
                                    disabled={isDisabled}
                                    style={{
                                      ...dropDownStyle,
                                      marginLeft: '20px',
                                      width: '250px',
                                    }}
                                  >
                                    <option value="">Select an option</option>
                                    <option value="Unsatisfactory Product">
                                      Unsatisfactory Product
                                    </option>
                                    <option value="Customer Accommodation">
                                      Customer Accommodation
                                    </option>
                                    <option value="Obsolete Inventory">Obsolete Inventory</option>
                                    <option value="Completion of Trial">Completion of Trial</option>
                                    <option value="Customer Feedback">Customer Feedback</option>
                                    <option value="Customer Complaint">Customer Complaint</option>
                                  </select>
                                </FormGroup>

                                <FormGroup
                                  className="input-item"
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '100%',
                                  }}
                                >
                                  <Label
                                    for="CustomerComplaint"
                                    style={{
                                      width: '150px',
                                      textAlign: 'right',
                                      marginRight: '10px',
                                    }}
                                  >
                                    Customer Complaint
                                  </Label>
                                  <select
                                    value={formData.CustomerComplaint || ''}
                                    className="form-select"
                                    id="CustomerComplaint"
                                    name="CustomerComplaint"
                                    onChange={handleChange}
                                    disabled={isDisabled}
                                    style={{
                                      ...dropDownStyle,
                                      marginLeft: '20px',
                                      width: '250px',
                                    }}
                                  >
                                    <option value="">Select an option</option>
                                    <option value="Non conforming/ Unsatisfactory Product">
                                      Non conforming/ Unsatisfactory Product
                                    </option>
                                    <option value="Product Labeling Problem">
                                      Product Labeling Problem
                                    </option>
                                    <option value="Product Damaged in Delivery">
                                      Product Damaged in Delivery
                                    </option>
                                    <option value="Insufficient or Inadequate Quality Records">
                                      Insufficient or Inadequate Quality Records
                                    </option>
                                    <option value="Wrong Product or Quantity Shipped">
                                      Wrong Product or Quantity Shipped
                                    </option>
                                    <option value="Late Delivery to Customer">
                                      Late Delivery to Customer
                                    </option>
                                    <option value="Complaints Regarding Productivity">
                                      Complaints Regarding Productivity
                                    </option>
                                    <option value="Problems with Communication and Responsiveness">
                                      Problems with Communication and Responsiveness
                                    </option>
                                    <option value="Complaints Regarding Business Practices">
                                      Complaints Regarding Business Practices
                                    </option>
                                  </select>
                                </FormGroup>
                              </div>
                            </Col>

                          </Row>









                          <Row className="mt-3" style={{ width: '1500px' }}>


                            <div className="form-section-container" style={{ padding: '15px', borderRadius: '8px', maxWidth: '320px', marginLeft: '100px' }}>

                              <FormGroup style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
                                <Label for="Company">Company Name *</Label>
                                <select
                                  value={formData.Company}
                                  className="form-select"
                                  id="Company"
                                  name="Company"
                                  onChange={handleChange}
                                  style={{ width: '100%' }}
                                >
                                  <option value="">Select an option</option>
                                  {customerOptions.map((option: any, index) => (
                                    <option key={index} value={option.type}>
                                      {option.type}
                                    </option>
                                  ))}
                                </select>
                              </FormGroup>

                              <FormGroup style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
                                <Label for="Product">Product ID *</Label>
                                <select
                                  value={formData.Product}
                                  className="form-select"
                                  id="Product"
                                  name="Product"
                                  onChange={handleChange}
                                  style={{ width: '100%' }}
                                >
                                  <option value="">Select an option</option>
                                  <option value="NA">NA</option>
                                  {productoptions.map((option: any, index) => (
                                    <option key={index} value={option.type}>
                                      {option.type}
                                    </option>
                                  ))}
                                </select>
                              </FormGroup>

                              <FormGroup style={{ display: 'flex', flexDirection: 'column' }}>
                                <Label for="Initiator">Initiator *</Label>
                                <Input
                                  value={formData.Initiator === null ? '' : formData.Initiator}
                                  type="text"
                                  id="Initiator"
                                  name="Initiator"
                                  onChange={handleChange}
                                  disabled={true}
                                  style={{ width: '100%' }}
                                />
                              </FormGroup>
                            </div>





                            {/* Right Column - Table & Add Button */}
                            <Col md={8} style={{ marginTop: '50px', marginLeft: '80px' }}>
                              <Table striped bordered hover className="mt-2" style={{ width: '500px' }}>
                                <thead>
                                  <tr>
                                    <th>Item</th>
                                    <th>Cost</th>
                                    <th>Actions</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {items.map((entry, index) => (
                                    <tr key={index}>
                                      <td>{entry.Item}</td>
                                      <td>{entry.Cost}</td>

                                      <td>
                                        <Button
                                          size="sm"
                                          style={{ backgroundColor: '#0e226a' }}
                                          onClick={() => {
                                            setSelectedEntry(entry);
                                            setEditModal(true);
                                          }}
                                        >
                                          Edit
                                        </Button>{' '}
                                        <Button
                                          color="danger"
                                          size="sm"
                                          onClick={() => {
                                            setDeleteEntry(entry);
                                            setDeleteModal(true);
                                          }}
                                        >
                                          Delete
                                        </Button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                              <Button
                                color="primary"
                                style={{ backgroundColor: '#0e226a', marginRight: '500px' }}
                                onClick={() => setShowModal(true)}
                              >
                                Add Item
                              </Button>
                            </Col>

                            <Modal isOpen={deleteModal} toggle={() => setDeleteModal(false)} centered>
                              <ModalHeader toggle={() => setDeleteModal(false)} style={{backgroundColor: '#efefef'}}>Confirm Deletion</ModalHeader>
                              <ModalBody style={{backgroundColor: '#efefef'}}>
                                {deleteEntry && <p>Are you sure you want to delete "{deleteEntry.Item}"?</p>}
                              </ModalBody>
                              <ModalFooter style={{backgroundColor: '#efefef'}}>
                                <Button color="secondary" onClick={() => setDeleteModal(false)}>Cancel</Button>
                                <Button color="danger" onClick={HandleDeleteItem}>Yes, Delete</Button>
                              </ModalFooter>
                            </Modal>




                            {/* Modal for Adding Cost */}
                            <Modal isOpen={showModal} toggle={() => setShowModal(false)} centered>
                              <ModalHeader toggle={() => setShowModal(false)} style={{backgroundColor: '#efefef'}}>Add Cost</ModalHeader>
                              <ModalBody style={{backgroundColor: '#efefef'}}>
                                <Row className="mb-3">
                                  <Col>
                                    <Input
                                      type="text"
                                      placeholder="Item"
                                      value={costItem}
                                      onChange={(e) => setCostItem(e.target.value)}
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <Input
                                      type="text"
                                      placeholder="Cost"
                                      value={cost}
                                      onChange={(e) => setCost(e.target.value)}
                                    />
                                  </Col>
                                </Row>
                              </ModalBody>
                              <ModalFooter style={{backgroundColor: '#efefef'}}>
                                <Button color="secondary" onClick={() => setShowModal(false)}>Cancel</Button>
                                <Button color="primary" onClick={HandleAddItem}>Add</Button>
                              </ModalFooter>
                            </Modal>

                            <Modal isOpen={editModal} toggle={() => setEditModal(false)} centered>
                              <ModalHeader toggle={() => setEditModal(false)}>Edit Item</ModalHeader>
                              <ModalBody>
                                {selectedEntry && (
                                  <>
                                    <Row className="mb-3">
                                      <Col>
                                        <Input
                                          type="text"
                                          placeholder="Item"
                                          value={selectedEntry.Item}
                                          onChange={(e) => setSelectedEntry(prev => ({ ...prev!, Item: e.target.value }))}
                                        />
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <Input
                                          type="text"
                                          placeholder="Cost"
                                          value={selectedEntry.Cost}
                                          onChange={(e) => setSelectedEntry(prev => ({ ...prev!, Cost: e.target.value }))}
                                        />
                                      </Col>
                                    </Row>
                                  </>
                                )}
                              </ModalBody>
                              <ModalFooter>
                                <Button color="secondary" onClick={() => setEditModal(false)}>Cancel</Button>
                                <Button color="primary" onClick={() => selectedEntry && HandleEditItem(selectedEntry)}>Update</Button>
                              </ModalFooter>
                            </Modal>













                            <Col md={8} style={{ marginTop: '50px', marginLeft: '0px', maxWidth: '95%' }}>
                              <div ref={rmaTableRef}>
                                <h4 style={{ color: "#0e226a", marginBottom: "15px" }}>Return Material Authorization</h4>
                                <Table striped bordered hover className="mt-2" style={{ width: '95%' }}>
                                  <thead>
                                    <tr>
                                      <th># Containers</th>
                                      <th>Container Type</th>
                                      <th># Gallons</th>
                                      <th>Weight (LB)</th>
                                      <th>Lot Number</th>
                                      <th>Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {RMAitems.map((entry, index) => (
                                      <tr key={index}>
                                        <td>{entry.ContainerNum}</td>
                                        <td>{entry.ContainerType}</td>
                                        <td>{entry.GallonNum}</td>
                                        <td>{entry.Weight}</td>
                                        <td>{entry.Lot}</td>
                                        <td>
                                          <Button size="sm" style={{ backgroundColor: '#0e226a' }} onClick={() => handleRMAEditClick(entry)}>Edit</Button>{' '}
                                          <Button color="danger" size="sm" onClick={() => handleRMADeleteClick(entry)}>Delete</Button>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>
                              </div>
                              <Button color="primary" style={{ backgroundColor: '#0e226a', marginTop: '10px' }} onClick={() => setRMAShowModal(true)}>Add RMA</Button>
                            </Col>

                            <Modal isOpen={RMAdeleteModal} toggle={() => setRMAdeleteModal(false)} centered>
                              <ModalHeader toggle={() => setRMAdeleteModal(false)} style={{backgroundColor: '#efefef'}}>Confirm Deletion</ModalHeader>
                              <ModalBody style={{backgroundColor: '#efefef'}}>
                                {RMAdeleteEntry && <p>Are you sure you want to delete this RMA entry?</p>}
                              </ModalBody>
                              <ModalFooter style={{backgroundColor: '#efefef'}}>
                                <Button color="secondary" onClick={() => setRMAdeleteModal(false)}>Cancel</Button>
                                <Button color="danger" onClick={HandleRMADeleteItem}>Yes, Delete</Button>
                              </ModalFooter>
                            </Modal>




                            {/* Modal for Adding RMA */}
                            <Modal isOpen={RMAshowModal} toggle={() => setRMAShowModal(false)} centered>
                              <ModalHeader toggle={() => setRMAShowModal(false)} style={{backgroundColor: '#efefef'}}>Add RMA</ModalHeader>
                              <ModalBody style={{backgroundColor: '#efefef'}}>
                                <Row className="mb-3">
                                  <Col>
                                    <Label># Containers</Label>
                                    <Input
                                      type="number"
                                      value={containerNum}
                                      onChange={(e) => setContainerNum(Number(e.target.value))}
                                    />
                                  </Col>
                                  <Col>
                                    <Label>Container Type</Label>
                                    <Input
                                      type="select"
                                      value={containerType}
                                      onChange={(e) => setContainerType(e.target.value)}
                                    >
                                      <option value="">Select type...</option>
                                      <option value="Drum">Drum</option>
                                      <option value="Tote">Tote</option>
                                      <option value="Pail">Pail</option>
                                    </Input>
                                  </Col>
                                </Row>
                                <Row className="mb-3">
                                  <Col>
                                    <Label># Gallons</Label>
                                    <Input
                                      type="number"
                                      value={gallonNum}
                                      onChange={(e) => setGallonNum(Number(e.target.value))}
                                    />
                                  </Col>
                                  <Col>
                                    <Label>Weight (LB)</Label>
                                    <Input
                                      type="number"
                                      value={weight}
                                      onChange={(e) => setWeight(Number(e.target.value))}
                                    />
                                  </Col>
                                </Row>
                                <Row className="mb-3">
                                  <Col>
                                    <Label>Lot Number</Label>
                                    <Input
                                      type="text"
                                      value={lot}
                                      onChange={(e) => setLot(e.target.value)}
                                    />
                                  </Col>
                                </Row>
                              </ModalBody>
                              <ModalFooter style={{backgroundColor: '#efefef'}}>
                                <Button color="secondary" onClick={() => setRMAShowModal(false)}>Cancel</Button>
                                <Button color="primary" onClick={HandleRMAAddItem}>Add</Button>
                              </ModalFooter>
                            </Modal>

                            <Modal isOpen={RMAeditModal} toggle={() => setRMAEditModal(false)} centered>
                              <ModalHeader toggle={() => setRMAEditModal(false)}>Edit RMA</ModalHeader>
                              <ModalBody>
                                {RMAselectedEntry && (
                                  <>
                                    <Row className="mb-3">
                                      <Col>
                                        <Label># Containers</Label>
                                        <Input
                                          type="number"
                                          value={RMAselectedEntry.ContainerNum}
                                          onChange={(e) =>
                                            setRMASelectedEntry({
                                              ...RMAselectedEntry,
                                              ContainerNum: Number(e.target.value),
                                            })
                                          }
                                        />
                                      </Col>
                                      <Col>
                                        <Label>Container Type</Label>
                                        <Input
                                          type="select"
                                          value={RMAselectedEntry.ContainerType}
                                          onChange={(e) =>
                                            setRMASelectedEntry({
                                              ...RMAselectedEntry,
                                              ContainerType: e.target.value,
                                            })
                                          }
                                        >
                                          <option value="">Select type...</option>
                                          <option value="Drum">Drum</option>
                                          <option value="Tote">Tote</option>
                                          <option value="Pail">Pail</option>

                                        </Input>
                                      </Col>
                                    </Row>
                                    <Row className="mb-3">
                                      <Col>
                                        <Label># Gallons</Label>
                                        <Input
                                          type="number"
                                          value={RMAselectedEntry.GallonNum}
                                          onChange={(e) =>
                                            setRMASelectedEntry({
                                              ...RMAselectedEntry,
                                              GallonNum: Number(e.target.value),
                                            })
                                          }
                                        />
                                      </Col>
                                      <Col>
                                        <Label>Weight (LB)</Label>
                                        <Input
                                          type="number"
                                          value={RMAselectedEntry.Weight}
                                          onChange={(e) =>
                                            setRMASelectedEntry({
                                              ...RMAselectedEntry,
                                              Weight: Number(e.target.value),
                                            })
                                          }
                                        />
                                      </Col>
                                    </Row>
                                    <Row className="mb-3">
                                      <Col>
                                        <Label>Lot Number</Label>
                                        <Input
                                          type="text"
                                          value={RMAselectedEntry.Lot}
                                          onChange={(e) =>
                                            setRMASelectedEntry({
                                              ...RMAselectedEntry,
                                              Lot: e.target.value,
                                            })
                                          }
                                        />
                                      </Col>

                                    </Row>
                                  </>
                                )}
                              </ModalBody>
                              <ModalFooter>
                                <Button color="secondary" onClick={() => setRMAEditModal(false)}>
                                  Cancel
                                </Button>
                                <Button
                                  color="primary"
                                  onClick={() => RMAselectedEntry && HandleRMAEditItem(RMAselectedEntry)}
                                >
                                  Update
                                </Button>
                              </ModalFooter>
                            </Modal>

                            <Modal isOpen={RMAdeleteModal} toggle={() => setRMAdeleteModal(false)} centered>
                              <ModalHeader toggle={() => setRMAdeleteModal(false)} style={{backgroundColor: '#efefef'}}>Confirm Deletion</ModalHeader>
                              <ModalBody style={{backgroundColor: '#efefef'}}>
                                {RMAdeleteEntry && <p>Are you sure you want to delete this RMA entry?</p>}
                              </ModalBody>
                              <ModalFooter style={{backgroundColor: '#efefef'}}>
                                <Button color="secondary" onClick={() => setRMAdeleteModal(false)}>Cancel</Button>
                                <Button color="danger" onClick={HandleRMADeleteItem}>Yes, Delete</Button>
                              </ModalFooter>
                            </Modal>








                            <div className="form-section-container" style={{ padding: '15px', borderRadius: '8px', maxWidth: '320px', marginTop: '50px' }}>

                              <FormGroup style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
                                <Label for="Company">Return Condition</Label>
                                <select
                                  value={formData.ReturnCondition === null ? '' : formData.ReturnCondition} // Set the value of the dropdown to the value in state
                                  className="form-select"
                                  id="ReturnCondition"
                                  name="ReturnCondition"
                                  onChange={handleChange}
                                  style={{ width: '100%' }}
                                >
                                  <option value="">Select an option</option>
                                  <option value="EVAL">EVAL</option>
                                  <option value="CREDIT">CREDIT</option>
                                  <option value="NOCREDIT">NOCREDIT</option>
                                  <option value="REMIX">REMIX</option>
                                  <option value="UNKNOWN">UNKNOWN</option>

                                </select>
                              </FormGroup>

                              <FormGroup style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
                                <Label for="Product">Return Plant</Label>
                                <select
                                  value={formData.ReturnPlant === null ? '' : formData.ReturnPlant}
                                  className="form-select"
                                  id="ReturnPlant"
                                  name="ReturnPlant"
                                  onChange={handleChange}
                                  style={{ width: '100%' }}
                                >
                                  <option value="">Select an option</option>
                                  <option value="Detroit">Detroit</option>
                                  <option value="Kentucky">Kentucky</option>
                                </select>
                              </FormGroup>


                            </div>




                          </Row>








                          {/* Process Change Modal */}
                          <Modal isOpen={processChangeModal} toggle={() => setProcessChangeModal(false)} centered size="lg">
                            <ModalHeader toggle={() => setProcessChangeModal(false)} style={{backgroundColor: '#efefef'}}>Process Change Details</ModalHeader>
                            <ModalBody style={{backgroundColor: '#efefef'}}>
                              <Row>
                                <Col md={12}>
                                  <FormGroup>
                                    <Label for="ProcessChangeDescription">Process Change Description</Label>
                                    <Input
                                      type="textarea"
                                      id="ProcessChangeObject.ProcessChangeDescription"
                                      name="ProcessChangeObject.ProcessChangeDescription"
                                      value={formData.ProcessChangeObject?.ProcessChangeDescription || ''}
                                      onChange={handleChange}
                                      style={{ height: '100px' }}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={6}>
                                  <FormGroup>
                                    <Label for="EquipmentImpacted">Equipment Impacted</Label>
                                    <Input
                                      type="text"
                                      id="ProcessChangeObject.EquipmentImpacted"
                                      name="ProcessChangeObject.EquipmentImpacted"
                                      value={formData.ProcessChangeObject?.EquipmentImpacted || ''}
                                      onChange={handleChange}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md={6}>
                                  <FormGroup>
                                    <Label for="PartNumbersImpacted">Part Numbers Impacted</Label>
                                    <Input
                                      type="text"
                                      id="ProcessChangeObject.PartNumbersImpacted"
                                      name="ProcessChangeObject.PartNumbersImpacted"
                                      value={formData.ProcessChangeObject?.PartNumbersImpacted || ''}
                                      onChange={handleChange}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={6}>
                                  <FormGroup>
                                    <Label for="StaffApproval">Staff Approval</Label>
                                    <div className="d-flex align-items-center">
                                      <Input
                                        type="checkbox"
                                        id="ProcessChangeObject.StaffApproval"
                                        name="ProcessChangeObject.StaffApproval"
                                        checked={formData.ProcessChangeObject?.StaffApproval || false}
                                        onChange={handleChange}
                                      />
                                    </div>
                                  </FormGroup>
                                </Col>
                                <Col md={6}>
                                  <FormGroup>
                                    <Label for="StaffApprovalDate">Staff Approval Date</Label>
                                    <Input
                                      type="date"
                                      id="ProcessChangeObject.StaffApprovalDate"
                                      name="ProcessChangeObject.StaffApprovalDate"
                                      value={formData.ProcessChangeObject?.StaffApprovalDate ? moment(formData.ProcessChangeObject.StaffApprovalDate).format('YYYY-MM-DD') : ''}
                                      onChange={handleChange}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={6}>
                                  <FormGroup>
                                    <Label for="CustomerNotificationRequired">Customer Notification Required</Label>
                                    <div className="d-flex align-items-center">
                                      <Input
                                        type="checkbox"
                                        id="ProcessChangeObject.CustomerNotificationRequired"
                                        name="ProcessChangeObject.CustomerNotificationRequired"
                                        checked={formData.ProcessChangeObject?.CustomerNotificationRequired || false}
                                        onChange={handleChange}
                                      />
                                    </div>
                                  </FormGroup>
                                </Col>
                                <Col md={6}>
                                  <FormGroup>
                                    <Label for="DateNotified">Date Notified</Label>
                                    <Input
                                      type="date"
                                      id="ProcessChangeObject.DateNotified"
                                      name="ProcessChangeObject.DateNotified"
                                      value={formData.ProcessChangeObject?.DateNotified ? moment(formData.ProcessChangeObject.DateNotified).format('YYYY-MM-DD') : ''}
                                      onChange={handleChange}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={6}>
                                  <FormGroup>
                                    <Label for="ProcessChangeLaunchDate">Process Change Launch Date</Label>
                                    <Input
                                      type="date"
                                      id="ProcessChangeObject.ProcessChangeLaunchDate"
                                      name="ProcessChangeObject.ProcessChangeLaunchDate"
                                      value={formData.ProcessChangeObject?.ProcessChangeLaunchDate ? moment(formData.ProcessChangeObject.ProcessChangeLaunchDate).format('YYYY-MM-DD') : ''}
                                      onChange={handleChange}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md={6}>
                                  <FormGroup>
                                    <Label for="CustomerApprovalReceived">Customer Approval Received</Label>
                                    <div className="d-flex align-items-center">
                                      <Input
                                        type="checkbox"
                                        id="ProcessChangeObject.CustomerApprovalReceived"
                                        name="ProcessChangeObject.CustomerApprovalReceived"
                                        checked={formData.ProcessChangeObject?.CustomerApprovalReceived || false}
                                        onChange={handleChange}
                                      />
                                    </div>
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={6}>
                                  <FormGroup>
                                    <Label for="DateSent">Date Sent</Label>
                                    <Input
                                      type="date"
                                      id="ProcessChangeObject.DateSent"
                                      name="ProcessChangeObject.DateSent"
                                      value={formData.ProcessChangeObject?.DateSent ? moment(formData.ProcessChangeObject.DateSent).format('YYYY-MM-DD') : ''}
                                      onChange={handleChange}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md={6}>
                                  <FormGroup>
                                    <Label for="DateApproved">Date Approved</Label>
                                    <Input
                                      type="date"
                                      id="ProcessChangeObject.DateApproved"
                                      name="ProcessChangeObject.DateApproved"
                                      value={formData.ProcessChangeObject?.DateApproved ? moment(formData.ProcessChangeObject.DateApproved).format('YYYY-MM-DD') : ''}
                                      onChange={handleChange}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={12}>
                                  <FormGroup>
                                    <Label for="LaunchFeedback">Launch Feedback</Label>
                                    <Input
                                      type="textarea"
                                      id="ProcessChangeObject.LaunchFeedback"
                                      name="ProcessChangeObject.LaunchFeedback"
                                      value={formData.ProcessChangeObject?.LaunchFeedback || ''}
                                      onChange={handleChange}
                                      style={{ height: '100px' }}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                            </ModalBody>
                            <ModalFooter style={{backgroundColor: '#efefef'}}>
                              <Button color="secondary" onClick={() => setProcessChangeModal(false)}>Close</Button>
                              <Button color="primary" onClick={() => HandleProcessChange()}>Update</Button>
                            </ModalFooter>
                          </Modal>












                          <h5 style={{ textAlign: "center", fontSize: 24, marginTop: '80px' }}>  Q.A. Manager </h5>

                          <Row className="mt-3 justify-content-center" style={{ marginBottom: '25px' }}>
                            <Col sm={3} className="d-flex justify-content-center">
                              <FormGroup style={{ width: '100%' }}>
                                <Label for="Level" className="text-center">Level *</Label>
                                <select
                                  value={formData.CRLevel}
                                  className="form-select"
                                  id="CRLevel"
                                  name="CRLevel"
                                  onChange={handleChange}
                                >
                                  <option value="0">Select an option</option>
                                  <option value="1">Level 1 - Multidisciplinary Corrective Action</option>
                                  <option value="2">Level 2 - Single Evaluator Corrective Action</option>
                                  <option value="3">Level 3 - Disposition Determination</option>
                                  <option value="4">Level 4 - Trend Determination</option>
                                  <option value="5">Level 5 - Customer Accommodation</option>
                                  <option value="6">Level 6 - unknown</option>
                                  <option value="7">S.C.A.R.</option>
                                </select>
                              </FormGroup>
                            </Col>

                            <Col sm={3} className="d-flex justify-content-center">
                              <FormGroup style={{ width: '100%' }}>
                                <Label for="QAAuthSign" className="text-center">Signature</Label>
                                <select
                                  id="QCManagerSignOff"
                                  name="QCManagerSignOff"
                                  value={formData.QCManagerSignOff === null ? '' : formData.QCManagerSignOff}
                                  onChange={handleChange}
                                  disabled={isDisabled}
                                  className="form-select"
                                >
                                  <option value="">Select an option</option>
                                  {CRUsers.map((option, index) => (
                                    <option key={index} value={option.userID}>
                                      {option.FullName}
                                    </option>
                                  ))}
                                </select>
                              </FormGroup>
                            </Col>

                            <Col sm={3} className="d-flex justify-content-center">
                              <FormGroup style={{ width: '100%' }}>
                                <Label for="Sales" className="text-center">Sales</Label>
                                <Input
                                  type="text"
                                  id="Sales"
                                  name="Sales"
                                  value={formData.Sales === null ? '' : formData.Sales}
                                  onChange={handleChange}
                                  disabled={isDisabled}
                                  className="form-control"
                                />
                              </FormGroup>
                            </Col>
                          </Row>


                          <Row className="mx-auto justify-content-center" style={{ marginTop: '20px' }}>
                            <Col sm={3} className="d-flex flex-column align-items-center">
                              <FormGroup style={{ width: '100%', textAlign: 'center' }}>
                                <Label for="AssignedTo">Action Assigned To *</Label>
                                <select
                                  value={formData.ActionAssigned}
                                  className="form-select"
                                  id="ActionAssigned"
                                  name="ActionAssigned"
                                  onChange={handleChange}
                                  style={{ ...dropDownStyle, margin: '0 auto' }}
                                >
                                  <option value="">Select an option</option>
                                  {CRUsers.map((option, index) => (
                                    <option key={index} value={option.userID}>
                                      {option.FullName}
                                    </option>
                                  ))}
                                </select>
                              </FormGroup>
                            </Col>

                            <Col sm={3} className="d-flex flex-column align-items-center">
                              <FormGroup style={{ width: '100%', textAlign: 'center' }}>
                                <Label for="ActAssignDate">Assigned Date *</Label>
                                <Input
                                  id="ActionAssignedDate"
                                  name="ActionAssignedDate"
                                  value={formData.ActionAssignedDate === null ? '' : moment(formData.ActionAssignedDate).format('YYYY-MM-DD')}
                                  onChange={handleChange}
                                  type="date"
                                  //disabled={true}
                                  style={{ margin: '0 auto' }}
                                />
                              </FormGroup>
                            </Col>

                            <Col sm={3} className="d-flex flex-column align-items-center">
                              <FormGroup style={{ width: '100%', textAlign: 'center' }}>
                                <Label for="ResearchProject">Research Project #</Label>
                                <Input
                                  id="ResearchProject"
                                  name="ResearchProject"
                                  value={formData.ResearchProject === null ? '' : formData.ResearchProject}
                                  onChange={handleChange}
                                  type="text"
                                  disabled={isDisabled}
                                  style={{ margin: '0 auto' }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>


                          <Row className="mx-auto justify-content-center" style={{ marginTop: '20px' }}>
                            <Col sm={3}>
                              <FormGroup>
                                <Label for="Basis" style={{ marginTop: '20px', marginLeft: '-0px' }}>Comments *</Label>
                                <Input
                                  id="InitialComments"
                                  name="InitialComments"
                                  value={formData.InitialComments}
                                  onChange={handleChange}
                                  type="textarea"
                                  disabled={isDisabled}
                                  style={{ ...textareaStyle, height: '150px', width: '300px', marginTop: '28px', marginLeft: '-00px' }}
                                />
                              </FormGroup>
                            </Col>

                            <Col sm={3}>
                              <FormGroup>
                                <Label for="AnalysisNM" style={{ marginLeft: '100px' }}>Analysis of Nonconformance/Condition (Include results)</Label>
                                <Input
                                  id="InitialAnalysis"
                                  name="InitialAnalysis"
                                  value={formData.InitialAnalysis === null ? '' : formData.InitialAnalysis}
                                  onChange={handleChange}
                                  type="textarea"
                                  disabled={isDisabled}
                                  style={{ ...textareaStyle, marginLeft: '50px', height: '150px', width: '300px', }}
                                />
                              </FormGroup>
                            </Col>

                            <div className="checkbox-container" style={{ padding: '15px', borderRadius: '5px', maxWidth: '400px', marginLeft: '100px' }}>
                              <Row>
                                <Col md={5} style={{ paddingRight: '10px' }}>
                                  <FormGroup className="input-item" style={{ display: 'flex', alignItems: 'center', width: '300px' }}>
                                    <Label for="Visc" style={{ width: '80px', textAlign: 'right', marginRight: '10px' }}>Visc</Label>
                                    <Input id="Visc" name="Visc" value={formData.Visc || ''} onChange={handleChange} type="text" disabled={isDisabled} />
                                  </FormGroup>
                                  <FormGroup className="input-item" style={{ display: 'flex', alignItems: 'center', width: '300px' }}>
                                    <Label for="WPG" style={{ width: '80px', textAlign: 'right', marginRight: '10px' }}>WPG</Label>
                                    <Input id="WPG" name="WPG" value={formData.WPG || ''} onChange={handleChange} type="text" disabled={isDisabled} />
                                  </FormGroup>

                                  <FormGroup className="input-item" style={{ display: 'flex', alignItems: 'center', width: '300px' }}>
                                    <Label for="WtSlds" style={{ width: '80px', textAlign: 'right', marginRight: '10px' }}>WT Slds</Label>
                                    <Input id="WtSlds" name="WtSlds" value={formData.WtSlds || ''} onChange={handleChange} type="text" disabled={isDisabled} />
                                  </FormGroup>
                                  <FormGroup className="input-item" style={{ display: 'flex', alignItems: 'center', width: '300px' }}>
                                    <Label for="Color" style={{ width: '80px', textAlign: 'right', marginRight: '10px' }}>Color</Label>
                                    <Input id="Color" name="Color" value={formData.Color || ''} onChange={handleChange} type="text" disabled={isDisabled} />
                                  </FormGroup>
                                </Col>
                              </Row>
                            </div>
                          </Row>






























                          <h5 style={{ textAlign: "center", fontSize: 24, marginTop: '100px', marginBottom: '30px' }}>  Evaluator </h5>





                          <Row className="mx-auto" style={{ marginTop: '40px' }}>
                            <Col sm={3}>
                              <Label for="InstDisp" style={{ marginLeft: '20px' }}>Instruction for Disposition</Label>
                              <Input
                                id="DispositionInstruction"
                                name="DispositionInstruction"
                                value={formData.DispositionInstruction === null ? '' : formData.DispositionInstruction}
                                onChange={handleChange}
                                type="textarea"
                                disabled={isDisabled}
                                style={{ ...textareaStyle, width: '300px', height: '200px', marginTop: '10px' }}
                              />
                            </Col>
                            <Col sm={3}>
                              <Label for="FinalInsp" style={{ marginLeft: '0px' }}>Final Inspection / Conclusion</Label>
                              <Input
                                id="FinalConclusion"
                                name="FinalConclusion"
                                value={formData.FinalConclusion === null ? '' : formData.FinalConclusion}
                                onChange={handleChange}
                                type="textarea"
                                disabled={isDisabled}
                                style={{ ...textareaStyle, width: '300px', height: '200px', marginTop: '10px', marginLeft: '0px' }}
                              />
                            </Col>
                            <Col sm={3}>
                              <Label for="CustomerFeedbackCategory" style={{ marginLeft: '20px' }}>Product Disposition</Label>
                              <select
                                value={formData.ProductDisposition === null ? '' : formData.ProductDisposition}
                                className="form-select"
                                id="ProductDisposition"
                                name="ProductDisposition"
                                onChange={handleChange}
                                disabled={isDisabled}
                                style={{ ...dropDownStyle, width: '170px', marginLeft: '80px', marginTop: '10px' }}
                              >
                                <option value="">Select an option</option>
                                <option value="Rework">Rework</option>
                                <option value="Repair">Repair</option>
                                <option value="Scrap">Scrap</option>
                                <option value="Use As Is">Use As Is</option>
                                <option value="Reject">Reject</option>
                                <option value="N/A">N/A</option>
                              </select>
                              <div style={{ marginTop: '70px' }}>
                                <Input
                                  id="SCARRequired"
                                  name="SCARRequired"
                                  checked={formData.SCARRequired}
                                  onChange={handleChange}
                                  type="checkbox"
                                  disabled={isDisabled}
                                  style={{ verticalAlign: 'middle', marginRight: '5px' }}
                                />
                                <Label for="SCARRequired" style={{ verticalAlign: 'middle' }}>SCAR</Label>
                              </div>
                            </Col>
                            <Col sm={3} style={{ alignSelf: 'flex-start' }}>
                              <div className="form-section-container" style={{ padding: '0px', borderRadius: '8px', transform: 'scale(0.9)', marginTop: '-20px' }}>
                                <FormGroup style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
                                  <Label for="Company">Trend Code 1</Label>
                                  <select
                                    value={formData.TrendCode1 === null ? '' : formData.TrendCode1}
                                    className="form-select"
                                    id="TrendCode1"
                                    name="TrendCode1"
                                    onChange={handleChange}
                                    style={{ width: '100%' }}
                                  >
                                    <option value="">Select an option</option>
                                    <option value="01 - DET">Performed by Magni Detroit</option>
                                    <option value="02 - KY">Performed by Magni KY</option>
                                  </select>
                                </FormGroup>
                                <FormGroup style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
                                  <Label for="Product">Trend Code 2</Label>
                                  <select
                                    value={formData.TrendCode2 === null ? '' : formData.TrendCode2}
                                    className="form-select"
                                    id="TrendCode2"
                                    name="TrendCode2"
                                    onChange={handleChange}
                                    style={{ width: '100%' }}
                                  >
                                    <option value="">Select an option</option>
                                    <option value="01 - RM">Received (raw material)</option>
                                    <option value="02 - Isp">Incoming Inspection/Testing (raw material)</option>
                                    <option value="03 - RMStor">Storage (raw material)</option>
                                    <option value="04 - InProc">In-process testing</option>
                                    <option value="05 - Final">Final testing</option>
                                    <option value="06 - FGStor">Storage (finished good)</option>
                                    <option value="07 - TranShip">Transit or Shipping</option>
                                    <option value="08 - CustStor">Storage (applicator)</option>
                                    <option value="09 - App">Application</option>
                                    <option value="10 - Test">Testing</option>
                                    <option value="11 - CoatStor">Storage (coated articles)</option>
                                    <option value="12 - Inst">Installation</option>
                                    <option value="13 - Field">Field failure</option>
                                    <option value="14 - Other">Other Issues</option>
                                  </select>
                                </FormGroup>
                                <FormGroup style={{ display: 'flex', flexDirection: 'column' }}>
                                  <Label for="Initiator">Trend Code 3</Label>
                                  <select
                                    value={formData.TrendCode3 === null ? '' : formData.TrendCode3}
                                    className="form-select"
                                    id="TrendCode3"
                                    name="TrendCode3"
                                    onChange={handleChange}
                                    style={{ width: '100%' }}
                                  >
                                    <option value="">Select an option</option>
                                    <option value="01 - Ext">External</option>
                                    <option value="02 - Int">Internal</option>
                                  </select>
                                </FormGroup>
                                <FormGroup style={{ display: 'flex', flexDirection: 'column' }}>
                                  <Label for="Initiator">Trend Code 4</Label>
                                  <select
                                    value={formData.TrendCode4 === null ? '' : formData.TrendCode4}
                                    className="form-select"
                                    id="TrendCode4"
                                    name="TrendCode4"
                                    onChange={handleChange}
                                    style={{ width: '100%' }}
                                  >
                                    <option value="">Select an option</option>
                                    <option value="01 - DO">Documentation Error</option>
                                    <option value="02 - HU">Human Error</option>
                                    <option value="03 - PR">Nonconforming Products</option>
                                    <option value="04 - SA">Safety Concern</option>
                                    <option value="05 - SUP">Supplier Issue</option>
                                  </select>
                                </FormGroup>
                                <FormGroup style={{ display: 'flex', flexDirection: 'column' }}>
                                  <Label for="Initiator">Trend Code 5</Label>
                                  <select
                                    value={formData.TrendCode5 === null ? '' : formData.TrendCode5}
                                    className="form-select"
                                    id="TrendCode5"
                                    name="TrendCode5"
                                    onChange={handleChange}
                                    style={{ width: '100%' }}
                                  >
                                    <option value="">Select an option</option>
                                    <option value="01 - SPEC">Out of Specification</option>
                                    <option value="02 - PURE">Purity-Contamination of Product</option>
                                    <option value="03 - FORM">Formulation error, Physical content error</option>
                                    <option value="04 - RHE">Bad Rheology</option>
                                    <option value="05 - LIFE">Shelf Life violation</option>
                                    <option value="06 - SHIP">Shipping (Container Integrity, Leakage, Labeling, etc.)</option>
                                    <option value="07 - DEF">Defective Paint</option>
                                    <option value="08 - CUST">Customer accomodation</option>
                                    <option value="09 - SB">Skill Based Human error</option>
                                    <option value="10 - RB">Rule Based Human error</option>
                                    <option value="11 - KB">Knowledge Based Human error</option>
                                    <option value="12 - ADH">Adhesion problems</option>
                                    <option value="13 - CHEM">Chemical Resistance</option>
                                    <option value="14 - ENV">Performance in Env. Testing (SAE J2334, B117, etc.)</option>
                                    <option value="15 - NORET">Internal issue, no return</option>
                                  </select>
                                </FormGroup>
                              </div>
                            </Col>
                          </Row>









                          <h5 style={{ textAlign: "center", fontSize: 24, marginTop: '100px', marginBottom: '50px' }}>  Report Status </h5>

                          <Row className="mx-auto justify-content-center align-items-end">
                            {/* QC Manager Column */}
                            <Col sm={2}>
                              <FormGroup>
                                <Label for="QCManagerApprove">QA Manager</Label>
                                <select
                                  id="QCManagerApprove"
                                  name="QCManagerApprove"
                                  value={formData.QCManagerApprove ?? ''}
                                  onChange={handleChange}
                                  disabled={isDisabled}
                                  style={{ ...dropDownStyle, width: '100%' }}
                                >
                                  <option value="">Select an option</option>
                                  {CRUsers.map((option, index) => (
                                    <option key={index} value={option.userID}>
                                      {option.FullName}
                                    </option>
                                  ))}
                                </select>
                              </FormGroup>
                              <FormGroup>
                                <Label for="QCDateApprove">Date</Label>
                                <Input
                                  type="date"
                                  id="QCDateApprove"
                                  name="QCDateApprove"
                                  value={formData.QCDateApprove === null ? '' : moment(formData.QCDateApprove).format('YYYY-MM-DD')}
                                  onChange={handleChange}
                                  disabled={isDisabled}
                                  style={{ ...dateStyle }}
                                />
                              </FormGroup>
                            </Col>

                            {/* Initiator Column */}
                            <Col sm={2}>
                              <FormGroup>
                                <Label for="InitiatorApprove">Assignee</Label>
                                <select
                                  id="InitiatorApprove"
                                  name="InitiatorApprove"
                                  value={formData.InitiatorApprove ?? ''}
                                  onChange={handleChange}
                                  disabled={isDisabled}
                                  style={{ ...dropDownStyle, width: '100%' }}
                                >
                                  <option value="">Select an option</option>
                                  {CRUsers.map((option, index) => (
                                    <option key={index} value={option.userID}>
                                      {option.FullName}
                                    </option>
                                  ))}
                                </select>
                              </FormGroup>
                              <FormGroup>
                                <Label for="InitiatorApproveDate">Date</Label>
                                <Input
                                  type="date"
                                  id="InitiatorApproveDate"
                                  name="InitiatorApproveDate"
                                  value={formData.InitiatorApproveDate === null ? '' : moment(formData.InitiatorApproveDate).format('YYYY-MM-DD')}
                                  onChange={handleChange}
                                  disabled={isDisabled}
                                  style={{ ...dateStyle }}
                                />
                              </FormGroup>
                            </Col>

                            {/* Approve3 Column */}
                            <Col sm={2}>
                              <FormGroup>
                                <Label for="Approve3">Other</Label>
                                <select
                                  id="Approve3"
                                  name="Approve3"
                                  value={formData.Approve3 ?? ''}
                                  onChange={handleChange}
                                  disabled={isDisabled}
                                  style={{ ...dropDownStyle, width: '100%' }}
                                >
                                  <option value="">Select an option</option>
                                  {CRUsers.map((option, index) => (
                                    <option key={index} value={option.userID}>
                                      {option.FullName}
                                    </option>
                                  ))}
                                </select>
                              </FormGroup>
                              <FormGroup>
                                <Label for="Approve3Date">Date</Label>
                                <Input
                                  type="date"
                                  id="Approve3Date"
                                  name="Approve3Date"
                                  value={formData.Approve3Date === null ? '' : moment(formData.Approve3Date).format('YYYY-MM-DD')}
                                  onChange={handleChange}
                                  disabled={isDisabled}
                                  style={{ ...dateStyle }}
                                />
                              </FormGroup>
                            </Col>

                            {/* Completion Date (Separate Column) */}
                            <Col sm={2}>
                              <FormGroup>
                                <Label for="CompleteDate">Completion Date</Label>
                                <Input
                                  id="DateCompleted"
                                  name="DateCompleted"
                                  value={formData.DateCompleted === null ? '' : moment(formData.DateCompleted).format('YYYY-MM-DD')}
                                  onChange={handleChange}
                                  type="date"
                                  disabled={isDisabled}
                                  style={{ ...dateStyle }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>






                          <h5 style={{ textAlign: "center", fontSize: 24, marginTop: '100px' }}>

                            <FormGroup check style={{ marginLeft: '0px' }}>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  id="CARequired"
                                  name="CARequired"
                                  checked={formData.CARequired}
                                  onChange={handleChange}
                                  disabled={isDisabled}
                                />
                                {' '}Corrective Action Required?
                              </Label>
                            </FormGroup>

                          </h5>

                          <Row className="justify-content-center" style={{ marginTop: '10px' }}>
                            <Col sm={3} className="d-flex flex-column align-items-center">
                              <FormGroup>
                                <Label for="InterimAction" className="text-center">Interim Action Taken</Label>
                                <Input
                                  id="CorrectiveAction.CPAInterimAction"
                                  name="CorrectiveAction.CPAInterimAction"
                                  value={formData.CorrectiveAction?.CPAInterimAction ?? ''}
                                  onChange={handleChange}
                                  type="textarea"
                                  disabled={!formData.CARequired}
                                  style={{ ...textareaStyle, height: '200px', width: '220px' }}
                                />
                              </FormGroup>
                            </Col>
                            <Col sm={3} className="d-flex flex-column align-items-center">
                              <FormGroup>
                                <Label for="RootCause" className="text-center">Root Cause Determination</Label>
                                <Input
                                  id="CorrectiveAction.CPARootCause"
                                  name="CorrectiveAction.CPARootCause"
                                  value={formData.CorrectiveAction?.CPARootCause ?? ''}
                                  onChange={handleChange}
                                  type="textarea"
                                  disabled={!formData.CARequired}
                                  style={{ ...textareaStyle, height: '200px', width: '220px' }}
                                />
                              </FormGroup>
                            </Col>
                            <Col sm={3} className="d-flex flex-column align-items-center">
                              <FormGroup>
                                <Label for="CPA" className="text-center">Correct/Prevent Action</Label>
                                <Input
                                  id="CorrectiveAction.CPAActionTaken"
                                  name="CorrectiveAction.CPAActionTaken"
                                  value={formData.CorrectiveAction?.CPAActionTaken ?? ''}
                                  onChange={handleChange}
                                  type="textarea"
                                  disabled={!formData.CARequired}
                                  style={{ ...textareaStyle, height: '200px', width: '220px' }}
                                />
                              </FormGroup>
                            </Col>
                            <Col sm={3} className="d-flex flex-column align-items-center">
                              <FormGroup>
                                <Label for="CPAFollowUP" className="text-center">Follow Up</Label>
                                <Input
                                  id="CorrectiveAction.CPAFollowUP"
                                  name="CorrectiveAction.CPAFollowUP"
                                  value={formData.CorrectiveAction?.CPAFollowUP ?? ''}
                                  onChange={handleChange}
                                  type="textarea"
                                  disabled={!formData.CARequired}
                                  style={{ ...textareaStyle, height: '200px', width: '220px' }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>


                          <Row className="mx-auto justify-content-center align-items-center" style={{ marginTop: '20px' }}>
                            <Col sm={3} className="d-flex flex-column align-items-center">
                              <FormGroup>
                                <Label for="CPAResponsibleSign">Signature</Label>
                                <select
                                  className="form-select"
                                  id="CorrectiveAction.CPAResponsibleSign"
                                  name="CorrectiveAction.CPAResponsibleSign"
                                  value={formData.CorrectiveAction?.CPAResponsibleSign ?? ''}
                                  onChange={handleChange}
                                  disabled={!formData.CARequired}
                                  style={{ width: '150px' }}
                                >
                                  <option value="">Select an option</option>
                                  {CRUsers.map((option, index) => (
                                    <option key={index} value={option.userID}>
                                      {option.FullName}
                                    </option>
                                  ))}
                                </select>
                              </FormGroup>
                            </Col>

                            <Col sm={3} className="d-flex flex-column align-items-center">
                              <FormGroup check>
                                <Label check>
                                  <Input
                                    type="checkbox"
                                    id="ContinuousImprovement"
                                    name="ContinuousImprovement"
                                    checked={formData.ContinuousImprovement}
                                    onChange={handleChange}
                                    disabled={!formData.CARequired}
                                  />
                                  {' '}Continuous Improvement Result from this CR?
                                </Label>
                              </FormGroup>
                            </Col>

                            <Col sm={3} className="d-flex flex-column align-items-center">
                              <FormGroup check>
                                <Label check>
                                  <Input
                                    type="checkbox"
                                    id="DidAssigneeCompleteSatisfactorily"
                                    name="DidAssigneeCompleteSatisfactorily"
                                    checked={formData.DidAssigneeCompleteSatisfactorily}
                                    onChange={handleChange}
                                    disabled={!formData.CARequired}
                                  />
                                  {' '}Did Assignee Complete Satisfactorily?
                                </Label>
                              </FormGroup>
                            </Col>

                            <Col sm={3} className="d-flex flex-column align-items-center">
                              <FormGroup>
                                <Label for="Approve1CA">Internal/External Reject</Label>
                                <select
                                  id="CorrectiveAction.IntOrExt"
                                  name="CorrectiveAction.IntOrExt"
                                  value={formData.CorrectiveAction?.IntOrExt ?? ''}
                                  onChange={handleChange}
                                  disabled={!formData.CARequired}
                                  className="form-select"
                                  style={{ width: '170px' }}
                                >
                                  <option value="">Select an option</option>
                                  <option value="Internal">Internal</option>
                                  <option value="External">External</option>
                                </select>
                              </FormGroup>
                            </Col>
                          </Row>


                          <h5 style={{ textAlign: "center", fontSize: 24, marginTop: '100px', marginBottom: '20px' }}>  CA Report Status </h5>

                          <Row className="mx-auto justify-content-center align-items-end">
                            {/* QC Manager Column */}
                            <Col sm={2}>
                              <FormGroup>
                                <Label for="CPAQCManagerApprove">QA Manager</Label>
                                <select
                                  id="CorrectiveAction.CPAQCManagerApprove"
                                  name="CorrectiveAction.CPAQCManagerApprove"
                                  value={formData.CorrectiveAction?.CPAQCManagerApprove ?? ''}
                                  onChange={handleChange}
                                  disabled={!formData.CARequired}
                                  style={{ ...dropDownStyle, width: '100%' }}
                                >
                                  <option value="">Select an option</option>
                                  {CRUsers.map((option, index) => (
                                    <option key={index} value={option.userID}>{option.FullName}</option>
                                  ))}
                                </select>
                              </FormGroup>
                              <FormGroup>
                                <Label for="CPAQCManagerDate">Date</Label>
                                <Input
                                  type="date"
                                  id="CorrectiveAction.CPAQCManagerDate"
                                  name="CorrectiveAction.CPAQCManagerDate"
                                  value={formData.CorrectiveAction?.CPAQCManagerDate ? moment(formData.CorrectiveAction.CPAQCManagerDate).format('YYYY-MM-DD') : ''}
                                  onChange={handleChange}
                                  disabled={!formData.CARequired}
                                  style={{ ...dateStyle }}
                                />
                              </FormGroup>
                            </Col>

                            {/* Initiator Column */}
                            <Col sm={2}>
                              <FormGroup>
                                <Label for="CPAInitiatorApprove">Assignee</Label>
                                <select
                                  id="CorrectiveAction.CPAInitiatorApprove"
                                  name="CorrectiveAction.CPAInitiatorApprove"
                                  value={formData.CorrectiveAction?.CPAInitiatorApprove ?? ''}
                                  onChange={handleChange}
                                  disabled={!formData.CARequired}
                                  style={{ ...dropDownStyle, width: '100%' }}
                                >
                                  <option value="">Select an option</option>
                                  {CRUsers.map((option, index) => (
                                    <option key={index} value={option.userID}>{option.FullName}</option>
                                  ))}
                                </select>
                              </FormGroup>
                              <FormGroup>
                                <Label for="CPAInitiatorDate">Date</Label>
                                <Input
                                  type="date"
                                  id="CorrectiveAction.CPAInitiatorDate"
                                  name="CorrectiveAction.CPAInitiatorDate"
                                  value={formData.CorrectiveAction?.CPAInitiatorDate ? moment(formData.CorrectiveAction.CPAInitiatorDate).format('YYYY-MM-DD') : ''}
                                  onChange={handleChange}
                                  disabled={!formData.CARequired}
                                  style={{ ...dateStyle }}
                                />
                              </FormGroup>
                            </Col>

                            {/* Approve3 Column */}
                            <Col sm={2}>
                              <FormGroup>
                                <Label for="CPAApprove3">Other</Label>
                                <select
                                  id="CorrectiveAction.CPAApprove3"
                                  name="CorrectiveAction.CPAApprove3"
                                  value={formData.CorrectiveAction?.CPAApprove3 ?? ''}
                                  onChange={handleChange}
                                  disabled={!formData.CARequired}
                                  style={{ ...dropDownStyle, width: '100%' }}
                                >
                                  <option value="">Select an option</option>
                                  {CRUsers.map((option, index) => (
                                    <option key={index} value={option.userID}>{option.FullName}</option>
                                  ))}
                                </select>
                              </FormGroup>
                              <FormGroup>
                                <Label for="CPAApprove3Date">Date</Label>
                                <Input
                                  type="date"
                                  id="CorrectiveAction.CPAApprove3Date"
                                  name="CorrectiveAction.CPAApprove3Date"
                                  value={formData.CorrectiveAction?.CPAApprove3Date ? moment(formData.CorrectiveAction.CPAApprove3Date).format('YYYY-MM-DD') : ''}
                                  onChange={handleChange}
                                  disabled={!formData.CARequired}
                                  style={{ ...dateStyle }}
                                />
                              </FormGroup>
                            </Col>

                            {/* Completion Date (Separate Column) */}
                            <Col sm={2}>
                              <FormGroup>
                                <Label for="CPACompletionDate">Completion Date</Label>
                                <Input
                                  id="CorrectiveAction.CPACompletionDate"
                                  name="CorrectiveAction.CPACompletionDate"
                                  value={formData.CorrectiveAction?.CPACompletionDate ? moment(formData.CorrectiveAction.CPACompletionDate).format('YYYY-MM-DD') : ''}
                                  onChange={handleChange}
                                  type="date"
                                  disabled={!formData.CARequired}
                                  style={{ ...dateStyle }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>



                        </ModalBody>
                        <div style={{ textAlign: "center", marginTop: "2rem" }}>
                          <Button onClick={HandleSubmit} style={{ ...dropDownStyle, backgroundColor: "#0e226a", fontSize: "18px", padding: "10px 20px", border: "none", color: "#fff" }}>
                            Submit Changes
                          </Button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      }
    </div >
  );
}