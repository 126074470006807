import { Configuration, PopupRequest, RedirectRequest } from "@azure/msal-browser";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId: "c397f3f4-6ead-4c1e-9b6f-927ce4b5b848",
    authority: "https://login.microsoftonline.com/f656c4ed-b8aa-4875-86c1-7ffabc1df59e/",
    //redirectUri: "http://localhost:3000/conditionreports",
    redirectUri: "https://conditionreports.themagnigroup.com/conditionreports",
    //postLogoutRedirectUri: "http://localhost:3000/",
    postLogoutRedirectUri: "https://conditionreports.themagnigroup.com/",


    //redirectUri: "https://witty-river-0c3e34110.4.azurestaticapps.net/conditionreports",
    ///postLogoutRedirectUri: "https://witty-river-0c3e34110.4.azurestaticapps.net",


  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    secureCookies: false
  },
  system: {
    allowNativeBroker: false,

    // Disables WAM Broker
  }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: RedirectRequest = {
  scopes: ["api://c397f3f4-6ead-4c1e-9b6f-927ce4b5b848/CQS"]
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};

