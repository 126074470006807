import React, { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';
import { loginRequest } from './authConfig';

const AutoLogin: React.FC = () => {
  const { accounts, instance } = useMsal();
  const navigate = useNavigate();

  useEffect(() => {
    // Process the redirect response (if any) and clear the URL hash.
    instance
      .handleRedirectPromise()
      .then((response) => {
        // If we got a response and it contains an account, it means the redirect worked.
        if (response && response.account) {
          // Set the active account so the app knows the user is logged in.
          instance.setActiveAccount(response.account);
          navigate('/conditionreports', { replace: true });
        } else if (accounts && accounts.length > 0) {
          // No redirect response but the user is already logged in.
          navigate('/conditionreports', { replace: true });
        } else {
          // If not logged in, trigger the redirect.
          instance.loginRedirect(loginRequest).catch((err) => console.error(err));
        }
      })
      .catch((err) => {
        console.error(err);
        instance.loginRedirect(loginRequest).catch((err) => console.error(err));
      });
  }, [accounts, instance, navigate]);

  return <div>Loading...</div>;
};

export default AutoLogin;