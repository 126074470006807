import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Login from './Components/Login';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';

import { BrowserRouter } from "react-router-dom";
import axios from "axios";
import qs from "qs";
//import { resolve } from 'path';
// MSAL imports
import {
  PublicClientApplication,
  EventType,
  EventMessage,
  AuthenticationResult,
} from "@azure/msal-browser";
import { loginRequest } from './Components/authConfig';

import { useMsal } from '@azure/msal-react';
import { msalConfig } from "./Components/authConfig";
import { AuthProvider } from './Components/AuthContext';
import { createRoot } from 'react-dom/client';




export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.initialize().then(() => {
  setupAxiosRequestInterceptor(msalInstance);
  setupAxiosResponseInterceptor(msalInstance);
  // Account selection logic is app dependent. Adjust as needed for different use cases.

  // const existingAccount = localStorage.getItem("account");
  // const accounts = msalInstance.getAllAccounts();
  // if (existingAccount) {
  //   const activeAccount = accounts.find(acc => acc.username === existingAccount);
  //   if (activeAccount) {
  //     msalInstance.setActiveAccount(activeAccount);
  //     return;
  //   }
  // }

  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
      msalInstance.setActiveAccount(accounts[0]);
  }






  msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult;
      const account = payload.account;
      msalInstance.setActiveAccount(account);
      localStorage.setItem('account', account.username);

      setTimeout(() => { }, 5000);
    }
  });


  async function setupAxiosRequestInterceptor(msalInstance: PublicClientApplication) {
    axios.interceptors.request.use(
      async (config) => {
        let active = localStorage.getItem("account");
        let activeAccount = msalInstance.getActiveAccount();
        let attempts = 0;

        // Wait until active account is set or until we've tried a few times.
        while (!activeAccount && attempts < 3) {
          await new Promise(res => setTimeout(res, 500));  // Wait for 500ms
          activeAccount = msalInstance.getActiveAccount();
          attempts++;
        }

        if (!activeAccount) {
          return config;  // No active account after waiting
        }

        try {

          const tokenResponse = await msalInstance.acquireTokenSilent(loginRequest);
          if (tokenResponse) {
            config.headers['Authorization'] = `Bearer ${tokenResponse.accessToken}`;
            return config;
          }
        } catch (error) {
          console.error("Failed to acquire token", error);
        }

        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }


  async function setupAxiosResponseInterceptor(msalInstance: PublicClientApplication) {
    const RETRY_LIMIT = 3;
    const RETRY_DELAY = 2000; // 2 seconds in milliseconds

    axios.interceptors.response.use(
      async (response) => {
        return response; // If the response was successful, return it
      },
      async (error) => {
        const originalRequest = error.config;

        // Check if the response was a 401 and we haven't already retried
        if (error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;  // Mark the request as retried

          let retryCount = 0;
          while (retryCount < RETRY_LIMIT) {
            try {
              // Re-acquire token here. You might want to call `msalInstance.acquireTokenSilent` again.
              const tokenResponse = await msalInstance.acquireTokenSilent(loginRequest);
              // Update the header
              //originalRequest.headers['Authorization'] = `Bearer ${tokenResponse.accessToken}`;
              axios.defaults.headers.common['Authorization'] = `Bearer ${tokenResponse.accessToken}`;
              // Retry the original request
              return await axios(originalRequest);
            } catch (err) {
              retryCount++;  // Increment the retry count

              if (retryCount < RETRY_LIMIT) {
                console.log(`Retry attempt #${retryCount} failed. Retrying after ${RETRY_DELAY / 1000} seconds...`);
                await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
              }
            }
          }

          // If execution reaches here, all retry attempts failed. Throw an error.
          msalInstance.loginRedirect(loginRequest);
          return Promise.reject(error);
        }

        // If response was not a 401 or we already retried, just reject the promise.
        return Promise.reject(error);
      }
    );

  }





  // axios.interceptors.request.use(
  //  async (config)  => {

  // let token;
  // try {
  //   const { instance } = useMsal();
  //   token = await instance.acquireTokenSilent(loginRequest);
  // } catch (error) {
  //   console.error("Failed to acquire token", error);

  // }

  //    //const token = await getTokenFromLocalStorage(); // your function to get the token
  //    console.log(token);

  //    if (token) {
  //      config.headers['Authorization'] = `Bearer ${token.accessToken}`;
  //    }

  //    return config;
  //  },
  //  (error) => {
  //    return Promise.reject(error);
  //  }
  // );

  // axios.interceptors.response.use(
  //  (response) => {
  //    return response;
  //  },
  //  async (error) => {
  //    const originalRequest = error.config;
  //    if (error.response.status === 401 && !originalRequest._retry) {
  //      originalRequest._retry = true;
  //      const access_token = await refreshAccessToken(); // you need to define this function
  //      localStorage.setItem('access_token', access_token);
  //      axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
  //      return axios(originalRequest);
  //    }
  //    return Promise.reject(error);
  //  }
  // );

  // async function getTokenFromLocalStorage() {
  //   return new Promise((resolve) => {
  //     setTimeout(() => {
  //       const token = localStorage.getItem('access_token');
  //       resolve(token);
  //       console.log(token);
  //     }); 
  //   });
  // }
  // async function refreshAccessToken() {
  //   const refresh_token = localStorage.getItem('refresh_token');
  //   const response = await axios.post(
  //     'https://api.themagnigroup.com/authorize',
  //     qs.stringify({
  //       refresh_token: refresh_token,
  //       grant_type: 'refresh_token',
  //     }),
  //     {
  //       headers: {
  //         'Content-Type': 'application/x-www-form-urlencoded',
  //       },
  //     }
  //   );
  //   return new Promise ((resolve) => {
  //     setTimeout(() => {
  //       localStorage.setItem('access_token', response.data.access_token);
  //       localStorage.setItem('refresh_token', response.data.refresh_token);
  //       resolve(response.data.access_token);
  //     },5000);
  //   })
  // }

  const root = createRoot(document.getElementById("root")!); // Create root element
  root.render(
    <BrowserRouter>
      <AuthProvider>
        <App pca={msalInstance} />
      </AuthProvider>
    </BrowserRouter>
  );

});