import * as React from 'react';

import { Link } from "react-router-dom";
import { useState, useEffect, useCallback, } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import axios from "axios";
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import ClearAllTwoToneIcon from '@mui/icons-material/ClearAllTwoTone';
import { Hidden, Tooltip } from '@mui/material';
//import Moment from 'react-moment';
import { Button, Input, Row } from "reactstrap";
import Menu from './Menu';
import Header from './Header';
import Header2 from './Header2';

import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import type { } from '@mui/x-data-grid/themeAugmentation';


import '@inovua/reactdatagrid-community/index.css'

import moment from 'moment';
import { BounceLoader } from 'react-spinners';
import { Column, ReportObject, renderDate, ReportObjectGrid } from "./MagniTypes";
import { CheckBox } from '@mui/icons-material';
import { Checkbox } from '@mui/material';
import APICalls from './APICalls';
import * as ExcelJS from 'exceljs';
import { ToastContainer } from 'react-toastify';
import { DataLoading } from './Loading';
//import jsPDF from 'jspdf'
//import 'jspdf-autotable';


var jsPDF = require('jspdf').jsPDF;
require('jspdf-autotable');



window.moment = moment;




function TablePaginationActions(props: any) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event: any) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: any) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: any) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: any) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};


export default function ConditionReports() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // 768px as breakpoint for mobile
  const [loading, setLoading] = useState(true);
  const [rows, setData] = useState<any[]>([]);
  const [reload, setReload] = useState(false);



  const [isLandscape, setIsLandscape] = useState(window.innerWidth > window.innerHeight);

  const [users, setUsers] = useState<{ [key: number]: string }>({});

  // Add state for location filter
  const [selectedLocation, setSelectedLocation] = useState<"All" | "Detroit" | "Kentucky">("All");


  const apiCalls = new APICalls();

  useEffect(() => {
    setLoading(true);
  
    const loadData = async () => {
      await fetchUsers(); // Fetch users first
    };
  
    loadData();
  
    const handleResize = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
  
      setIsMobile(width <= 768);
      setWindowWidth(width);
      setIsLandscape(width > height);
    };
  
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [reload]);
  
  useEffect(() => {
    if (Object.keys(users).length > 0) {
      // Ensure GetActiveReports is only called after users are loaded
      // Get location parameter for initial load
      const locParam = selectedLocation === "All" ? undefined : selectedLocation === "Detroit" ? "D" : "K";
      try {
        setLoading(true);
        const fetchInitialData = async () => {
          const response = await apiCalls.GetActiveReports(locParam);
          const mappedData = mapReportData(response.data);
          setData(mappedData);
          setLoading(false);
        };
        
        fetchInitialData();
      } catch (error) {
        console.error('Error fetching initial data:', error);
        setLoading(false);
      }
    }
  }, [users]); // Only reload when users change, not when location changes


  // Function to fetch user data
  const fetchUsers = async () => {
    try {
      const response = await apiCalls.GetCRUsers();
      const userList = response.data;

      // Convert array into lookup object: { 1: 'John Doe', 2: 'Jane Smith', ... }
      const userMap: { [key: number]: string } = {};
      userList.forEach((user: { userID: number; FullName: string }) => {
        userMap[user.userID] = user.FullName;
      });

      setUsers(userMap);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };



// Assuming the fetchUsers function has already been called before GetActiveReports

const GetActiveReports = async () => {
  setLoading(true); // Show loading spinner if needed
  try {
    // Use location parameter
    const locParam = selectedLocation === "All" ? undefined : selectedLocation === "Detroit" ? "D" : "K";
    const response = await apiCalls.GetActiveReports(locParam);
    const mappedData = mapReportData(response.data);
    setData(mappedData); // Set the data with mapped user names
    console.log(mappedData);
    setLoading(false); // Hide loading spinner if needed
  } catch (error) {
    console.error('Error fetching active reports:', error);
    setLoading(false);
  }
};



const GetClosedReports = async () => {
  setLoading(true); // Show loading spinner if needed
  try {
    // Use location parameter
    const locParam = selectedLocation === "All" ? undefined : selectedLocation === "Detroit" ? "D" : "K";
    const response = await apiCalls.GetClosedReports(locParam);
    const mappedData = mapReportData(response.data);
    setData(mappedData); // Set the data with mapped user names
    console.log(mappedData);
    setLoading(false); // Hide loading spinner if needed
  } catch (error) {
    console.error('Error fetching closed reports:', error);
    setLoading(false);
  }
};



const GetAllReports = async () => {
  setLoading(true); // Show loading spinner if needed
  try {
    const locParam = selectedLocation === "All" ? undefined : selectedLocation === "Detroit" ? "D" : "K";
    const response = await apiCalls.GetAllReports(locParam);
    const mappedData = mapReportData(response.data);
    setData(mappedData); // Set the data with mapped user names
    console.log(mappedData);
    setLoading(false); // Hide loading spinner if needed
  } catch (error) {
    console.error('Error fetching all reports:', error);
    setLoading(false);
  }
};





  const [windowWidth, setWindowWidth] = useState(window.innerWidth);



  const commonHeaderStyle = {
    backgroundColor: '#d3d3d3',
    color: '#FFFFFF',
  };






  const columns2: GridColDef[] = [
    { field: 'CRNumber', headerName: 'CR Number', width: 70, type: 'number' },
    { field: 'DateInitiated', headerName: 'Date Initiated', width: 85, type: 'date' },
    { 
      field: 'CRTitle', 
      headerName: 'Title', 
      width: 160,
      flex: 0.7,
      type: 'string',
      renderCell: (params) => (
        <div style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          whiteSpace: 'normal',
          lineHeight: '1.2',
          padding: '4px',
          overflow: 'visible'
        }}>
          {params.value}
        </div>
      )
    },
    { 
      field: 'Product', 
      headerName: 'Product', 
      width: 75, 
      flex: 0.3,
      type: 'string',
      renderCell: (params) => (
        <div style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          whiteSpace: 'normal',
          lineHeight: '1.3',
          padding: '6px',
          overflow: 'visible',
          wordBreak: 'break-word'
        }}>
          {params.value}
        </div>
      )
    },
    { 
      field: 'Lot', 
      headerName: 'Lot', 
      width: 80,
      flex: 0.3,
      type: 'string',
      renderCell: (params) => (
        <div style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          whiteSpace: 'normal',
          lineHeight: '1.3',
          padding: '6px',
          overflow: 'visible',
          wordBreak: 'break-word'
        }}>
          {params.value}
        </div>
      )
    },
    {
      field: 'RMARequired',
      headerName: 'RMA',
      width: 45,
      type: 'boolean',
      headerClassName: 'headerRma',
      cellClassName: 'cellRma',
      renderCell: (params) => (
        <Checkbox checked={params.row.RMARequired} />
      ),
    },

    { 
      field: 'ActionAssigned', 
      headerName: 'Action Assigned', 
      width: 95,
      flex: 0.4, 
      type: 'string',
      renderCell: (params) => (
        <div style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          whiteSpace: 'normal',
          lineHeight: '1.2',
          padding: '4px',
          overflow: 'visible'
        }}>
          {params.value}
        </div>
      )
    },
    { field: 'DateCompleted', headerName: 'Date Completed', width: 85, flex: 0.3, type: 'date' },
    { 
      field: 'QCManagerApprove', 
      headerName: 'Approval 1', 
      width: 85,
      flex: 0.3,
      headerAlign: 'center',
      type: 'string',
      renderCell: (params) => (
        <div style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          whiteSpace: 'normal',
          lineHeight: '1.2',
          padding: '4px',
          overflow: 'visible'
        }}>
          {params.value}
        </div>
      )
    },
    { 
      field: 'InitiatorApprove', 
      headerName: 'Approval 2', 
      width: 85,
      flex: 0.3,
      headerAlign: 'center',
      type: 'string',
      renderCell: (params) => (
        <div style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          whiteSpace: 'normal',
          lineHeight: '1.2',
          padding: '4px',
          overflow: 'visible'
        }}>
          {params.value}
        </div>
      )
    },
    { 
      field: 'Approve3', 
      headerName: 'Approval 3', 
      width: 85,
      flex: 0.3,
      headerAlign: 'center',
      type: 'string',
      renderCell: (params) => (
        <div style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          whiteSpace: 'normal',
          lineHeight: '1.2',
          padding: '4px',
          overflow: 'visible'
        }}>
          {params.value}
        </div>
      )
    },
    {
      field: 'CARequired',
      headerName: 'CA',
      width: 30,
      type: 'boolean',
      headerClassName: 'headerRma',
      cellClassName: 'cellRma',
      renderCell: (params) => (
        <Checkbox checked={params.row.CARequired} />
      ),
    },
    { field: 'CPACompletionDate', headerName: 'CA Completion', width: 85, flex: 0.3, type: 'date' },
    { 
      field: 'CPAQCManagerApprove', 
      headerName: 'CA Approval 1', 
      width: 90,
      flex: 0.3, 
      type: 'string',
      headerAlign: 'center',
      renderCell: (params) => (
        <div style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          whiteSpace: 'normal',
          lineHeight: '1.2',
          padding: '4px',
          overflow: 'visible'
        }}>
          {params.value}
        </div>
      )
    },
    { 
      field: 'CPAInitiatorApprove', 
      headerName: 'CA Approval 2', 
      width: 90,
      flex: 0.3, 
      type: 'string',
      headerAlign: 'center',
      renderCell: (params) => (
        <div style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          whiteSpace: 'normal',
          lineHeight: '1.2',
          padding: '4px',
          overflow: 'visible'
        }}>
          {params.value}
        </div>
      )
    },
    { 
      field: 'CPAApprove3', 
      headerName: 'CA Approval 3', 
      width: 85,
      flex: 0.3, 
      type: 'string',
      headerAlign: 'center',
      renderCell: (params) => (
        <div style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          whiteSpace: 'normal',
          lineHeight: '1.2',
          padding: '4px',
          overflow: 'visible'
        }}>
          {params.value}
        </div>
      )
    },
    {
      field: 'Complaint',
      headerName: 'Complaint',
      width: 75,
      flex: 0.25,
      type: 'boolean',
      headerClassName: 'headerRma',
      cellClassName: 'cellRma',
      renderCell: (params) => (
        <Checkbox checked={params.row.Complaint} />
      ),
    },
  ];



  const generateRMA = async () => {
    const doc = new jsPDF('portrait', 'px', 'a4', false);

    doc.setProperties({
        title: 'MaterialAdditionsRequestForm_' + 'number',
    });

    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();

    // Main Header: "Magni Industries, Inc."
    const mainHeader = 'Magni Industries, Inc.';
    const mainHeaderFontSize = 24;
    const textWidthMainHeader = (doc.getStringUnitWidth(mainHeader) * mainHeaderFontSize) / doc.internal.scaleFactor;
    const startXMainHeader = (pageWidth - textWidthMainHeader) / 2;
    const startYMainHeader = 40;

    doc.setFontSize(mainHeaderFontSize);
    doc.text(mainHeader, startXMainHeader, startYMainHeader);

    // "RMA Number" and "Date Initiated" on the right
    const rmaLabel = 'RMA Number';
    const dateLabel = 'Date Initiated';
    const rightX = pageWidth - 60; // Right-aligned text starting 60px from the right

    doc.setFontSize(12);
    doc.text(rmaLabel, rightX, startYMainHeader + 30);
    doc.text(dateLabel, rightX, startYMainHeader + 45);

    // "Return Material Authorization" in big font centered
    const rmaTitle = 'Return Material Authorization';
    const rmaTitleFontSize = 18;
    const textWidthRMATitle = (doc.getStringUnitWidth(rmaTitle) * rmaTitleFontSize) / doc.internal.scaleFactor;
    const startXRMATitle = (pageWidth - textWidthRMATitle) / 2;
    const startYRMATitle = startYMainHeader + 60;

    doc.setFontSize(rmaTitleFontSize);
    doc.text(rmaTitle, startXRMATitle, startYRMATitle);

    // Section headers: "Issued To:", "Ship Material To:", "Sign and Fax To:", "Attn:"
    const sectionHeaders = [
        'Issued To:',
        'Ship Material To:',
        'Sign and Fax To:',
        'Attn:'
    ];

    const startYSection = startYRMATitle + 40;
    let currentY = startYSection;

    sectionHeaders.forEach(header => {
        doc.setFontSize(12);
        doc.text(header, 60, currentY);
        currentY += 15; // Spacing between each section header
    });



    








    doc.setFontSize(16)
    const title1 = ''
    const textWidth2 = (doc.getStringUnitWidth(title1) * 16) / doc.internal.scaleFactor // Calculate text width
    const startX1 = (pageWidth - textWidth2) / 2 // Calculate starting X position for centering text
    const startY1 = 215 // Set Y position for the text
    //doc.setFont('normal','normal','bold')
    doc.text(title1, startX1, startY1, )
    //
    doc.setFontSize(12)
    const header = [['Product', '# of Containers', 'Type of Container', '# of Gallons']]
    //

    //get rows of data
    const pdf_ma: any = []; //await GetRMAData();

    const data: any = [];
    pdf_ma.map(async (material: any, index: any) => {
      //const maNumUpdated = material.RM.split(' ').slice(1).join(' ');
      // const eachData = [
      //   '' + material.quantity,
      //   '' + material.WPM,
      //   '' + material.gallons,
      //   '' + material.rmN,
      //   '' + maNumUpdated,
      //   '',
      // ]
      //
      const eachData = [
        '1', //+ material.quantity,
        '2', //+ material.WPM,
        '3', //+ material.gallons,
        '4', //+ material.rmN,
        //'5', //+ material.RM.substring(material.RM.indexOf(' ') + 1),
        //'6',
      ]
      data.push(eachData)
    })
    // const data = [
    //   [
    //     '' + Submissiondata[dataIndex].MaterialAdditions[dataIndexRM].quantityLB,
    //     '',
    //     '' + Submissiondata[dataIndex].MaterialAdditions[dataIndexRM].gallons,
    //     '' + Submissiondata[dataIndex].MaterialAdditions[dataIndexRM].RM,
    //     '',
    //     '',
    //   ],
    // ]

    // Set the table styles
    doc.setFontSize(12)
    doc.setTextColor(0, 0, 0) // Black color

    // Add the header row to the table
    doc.autoTable({
      head: header,
      body: data,
      startY: 225, // Y position where the table starts
      theme: 'grid', // Table theme: 'striped', 'grid', 'plain'
      // columnStyles: {
      //   0: { fontStyle: 'bold' }, // Bold font style for the first column
      // },
      columnStyles: {
        0: { cellWidth: 40 },  // Shrink this column (e.g., for 'Quantity [lbs]')
        1: { cellWidth: 40 },  // Smaller width (e.g., for 'WPG')
        2: { cellWidth: 35 },  // Larger width (e.g., for 'Gallons')
        3: { cellWidth: 40 },  // Shrink column (e.g., for 'RM#')
        // 4: { cellWidth: 110 },  // Larger width (e.g., for 'RM Name')
        // 5: { cellWidth: 60 },  // Shrink column (e.g., for 'Barcode')
        // 6: { cellWidth: 60 },  // Standard width for 'Added By'
      },
      styles: {
        lineColor: [0, 0, 0]
      },
    })
    const numRows = data.length
    const tableHeight = numRows * 20
    //calculate the remaining height
    const remHeight = pageHeight - (250 + tableHeight)
    console.log(remHeight)
    if (remHeight < 80) {
      //curr date
      //doc.text(60, pageHeight-30, "Tuesday April 16, 2024");
      doc.addPage()
      //message
      const MyMessage = ''
      const lines = doc.splitTextToSize(MyMessage, pageWidth - 120)
      const lx = 60
      let ly = 60
      lines.forEach((line: any) => {
        doc.text(line,lx, ly)
        ly += doc.getLineHeight()
      })
      // Add an hr tag
      doc.setLineWidth(0.5) // Set line width for hr
      doc.setDrawColor(0) // Set line color for hr (black)
      //doc.line(60, 100, pageWidth - 60, 100) // Draw horizontal line
      //Signature
      //doc.text('QA Technician Signature:', 60, 130, )
      //doc.text('_____________________________________', 200, 130, )
      //curr date
      const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ]

      const daysOfWeek = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
      ]

      const today = new Date()
      const dayOfWeek = daysOfWeek[today.getDay()]
      const month = months[today.getMonth()]
      const day = today.getDate()
      const year = today.getFullYear()

      const formattedDate = `${dayOfWeek} ${month} ${day}, ${year}`
      doc.setFontSize(10)
      doc.text(formattedDate, 60, pageHeight - 30, )
    } else {
      //message
      const MyMessage = ''
      const lines = doc.splitTextToSize(MyMessage, pageWidth - 120)
      const lx = 60
      let ly = 270 + tableHeight
      lines.forEach((line: any) => {
        doc.text(line, lx, ly)
        ly += doc.getLineHeight()
      })
      //
      //
      //
      // Add an hr tag
      doc.setLineWidth(0.5) // Set line width for hr
      doc.setDrawColor(0) // Set line color for hr (black)
      //doc.line(60, pageHeight - 80, pageWidth - 60, pageHeight - 80) // Draw horizontal line
      //Signature
      //doc.text('QA Technician Signature:', 60, pageHeight - 50, )
      //doc.text('_____________________________________', 200, pageHeight - 50, )
      //curr date
      const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ]

      const daysOfWeek = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
      ]

      const today = new Date()
      const dayOfWeek = daysOfWeek[today.getDay()]
      const month = months[today.getMonth()]
      const day = today.getDate()
      const year = today.getFullYear()

      const formattedDate = `${dayOfWeek} ${month} ${day}, ${year}`
      doc.setFontSize(10)
      doc.text(formattedDate, 60, pageHeight - 30, )
      //doc.text(60, pageHeight-30, "Tuesday April 16, 2024");
    }
    //doc.text(60, 230, );
    //  doc.addImage(logo, 'PNG', 65,120,200,100)
    //  doc.addPage()
    //  doc.addImage(logo, 'PNG', 65,20,200,100)
    const string = doc.output('datauristring')
    var embed = "<embed width='100%' height='100%' src='" + string + "'/>"
    var x = window.open();
    if (x) {
      x.document.write(embed);
      x.document.close();
    } else {
      console.error('Failed to open the new window');
    }
  }













  const generateCAR = async () => {
    const doc = new jsPDF('portrait', 'px', 'a4', false);

    doc.setProperties({
        title: 'CorrectiveAction_' + 'number',
    });

    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();

    let currentY = 40;

    // Main Title: Corrective Action
    const mainTitle = 'Corrective Action';
    const mainTitleFontSize = 24;
    const textWidthMainTitle = (doc.getStringUnitWidth(mainTitle) * mainTitleFontSize) / doc.internal.scaleFactor;
    const startXMainTitle = (pageWidth - textWidthMainTitle) / 2;

    doc.setFontSize(mainTitleFontSize);
    doc.text(mainTitle, startXMainTitle, currentY);

    currentY += 40;

    // Section 1: Background
    const section1Title = '1. Background';
    const sectionTitleFontSize = 14;
    const textWidthSection1Title = (doc.getStringUnitWidth(section1Title) * sectionTitleFontSize) / doc.internal.scaleFactor;
    const startXSection1Title = (pageWidth - textWidthSection1Title) / 2;

    doc.setFontSize(sectionTitleFontSize);
    doc.text(section1Title, startXSection1Title, currentY);

    currentY += 30; // Move down after the header

    const column1X = 60;
    const column2X = pageWidth / 2 + 20;

    doc.setFontSize(11);

    doc.text('CR Number:', column1X, currentY);
    doc.text('CR Title:', column1X, currentY + 15);
    doc.text('Company:', column1X, currentY + 30);
    doc.text('Product:', column1X, currentY + 45);

    doc.text('Initiator:', column2X, currentY);
    doc.text('Team Leader:', column2X, currentY + 15);
    doc.text('Team Members:', column2X, currentY + 30);

    currentY += 60;

    // Add more space before next section header
    currentY += 30;  // Add extra space between sections

    // Check if content overflows, add a new page if necessary
    if (currentY > pageHeight - 60) {
        doc.addPage();
        currentY = 40;
    }

    // Section 2: Concern Description
    const section2Title = '2. Concern Description';
    const textWidthSection2Title = (doc.getStringUnitWidth(section2Title) * sectionTitleFontSize) / doc.internal.scaleFactor;
    const startXSection2Title = (pageWidth - textWidthSection2Title) / 2;

    doc.setFontSize(sectionTitleFontSize);
    doc.text(section2Title, startXSection2Title, currentY);

    currentY += 30; // Move down after the header

    doc.setFontSize(11);
    doc.text('Description:', 60, currentY);

    currentY += 15;

    // Add more space before next section header
    currentY += 30;  // Add extra space between sections

    // Check if content overflows, add a new page if necessary
    if (currentY > pageHeight - 60) {
        doc.addPage();
        currentY = 40;
    }

    // Section 3: Containment Actions
    const section3Title = '3. Containment Actions';
    const textWidthSection3Title = (doc.getStringUnitWidth(section3Title) * sectionTitleFontSize) / doc.internal.scaleFactor;
    const startXSection3Title = (pageWidth - textWidthSection3Title) / 2;

    doc.setFontSize(sectionTitleFontSize);
    doc.text(section3Title, startXSection3Title, currentY);

    currentY += 30; // Move down after the header

    doc.setFontSize(11);
    doc.text('Containment:', 60, currentY);

    currentY += 15;

    // Add more space before next section header
    currentY += 30;  // Add extra space between sections

    // Check if content overflows, add a new page if necessary
    if (currentY > pageHeight - 60) {
        doc.addPage();
        currentY = 40;
    }

    // Section 4: Define Root Cause(s)
    const section4Title = '4. Define Root Cause(s)';
    const textWidthSection4Title = (doc.getStringUnitWidth(section4Title) * sectionTitleFontSize) / doc.internal.scaleFactor;
    const startXSection4Title = (pageWidth - textWidthSection4Title) / 2;

    doc.setFontSize(sectionTitleFontSize);
    doc.text(section4Title, startXSection4Title, currentY);

    currentY += 30; // Move down after the header

    doc.setFontSize(11);
    doc.text('Root Cause:', 60, currentY);

    currentY += 15;

    // Add more space before next section header
    currentY += 30;  // Add extra space between sections

    // Section 5: Verify Corrective Actions
    const section5Title = '5. Verify Corrective Actions';
    const textWidthSection5Title = (doc.getStringUnitWidth(section5Title) * sectionTitleFontSize) / doc.internal.scaleFactor;
    const startXSection5Title = (pageWidth - textWidthSection5Title) / 2;

    doc.setFontSize(sectionTitleFontSize);
    doc.text(section5Title, startXSection5Title, currentY);

    currentY += 30; // Move down after the header

    doc.setFontSize(11);
    doc.text('Corrective Action:', 60, currentY);

    currentY += 15;

    // Add more space before next section header
    currentY += 30;  // Add extra space between sections

    // Section 6: Implementation of Permanent Actions
    const section6Title = '6. Implementation of Permanent Actions';
    const textWidthSection6Title = (doc.getStringUnitWidth(section6Title) * sectionTitleFontSize) / doc.internal.scaleFactor;
    const startXSection6Title = (pageWidth - textWidthSection6Title) / 2;

    doc.setFontSize(sectionTitleFontSize);
    doc.text(section6Title, startXSection6Title, currentY);

    currentY += 30; // Move down after the header

    doc.setFontSize(11);
    doc.text('Permanent Actions:', 60, currentY);
    doc.text('Person Responsible:', column2X, currentY);
    doc.text('Date Implemented:', column2X, currentY + 15);

    currentY += 45;

    // Add more space before next section header
    currentY += 30;  // Add extra space between sections

    // Section 7: Actions to Prevent Reoccurrence
    const section7Title = '7. Actions to Prevent Reoccurrence';
    const textWidthSection7Title = (doc.getStringUnitWidth(section7Title) * sectionTitleFontSize) / doc.internal.scaleFactor;
    const startXSection7Title = (pageWidth - textWidthSection7Title) / 2;

    // Check if there's enough space for this section, otherwise add a new page
    if (currentY + 30 + 30 > pageHeight) {
        doc.addPage();
        currentY = 40;  // Reset Y position after new page
    }

    doc.setFontSize(sectionTitleFontSize);
    doc.text(section7Title, startXSection7Title, currentY);

    currentY += 30; // Move down after the header

    doc.setFontSize(11);
    doc.text('Prevent Reoccurrence:', 60, currentY);

    currentY += 15;

    // Add more space before next section header
    currentY += 30;  // Add extra space between sections

    // Section 8: Status
    const section8Title = '8. Status';
    const textWidthSection8Title = (doc.getStringUnitWidth(section8Title) * sectionTitleFontSize) / doc.internal.scaleFactor;
    const startXSection8Title = (pageWidth - textWidthSection8Title) / 2;

    // Check if there's enough space for this section, otherwise add a new page
    if (currentY + 30 + 30 > pageHeight) {
        doc.addPage();
        currentY = 40;  // Reset Y position after new page
    }

    doc.setFontSize(sectionTitleFontSize);
    doc.text(section8Title, startXSection8Title, currentY);

    currentY += 30; // Move down after the header

    doc.setFontSize(11);
    doc.text('QC Approve:', 60, currentY);
    doc.text('QC Date Approve:', column2X, currentY);
    
    doc.text('Initiator Approve:', 60, currentY + 15);
    doc.text('Initiator Date Approve:', column2X, currentY + 15);

    currentY += 45;

        // Open the PDF in a new tab
        const pdfData = doc.output('blob');
        const pdfUrl = URL.createObjectURL(pdfData);
        window.open(pdfUrl, '_blank');
};


















  const [enableColumnAutosize, setEnableColumnAutosize] = useState(true);


  const [gridRef, setGridRef] = useState<any>(null);
  const SEPARATOR = ',';

  // const downloadBlob = (blob: Blob, fileName = 'All Reports.csv') => {
  //   const link = document.createElement('a');
  //   const url = URL.createObjectURL(blob);

  //   link.setAttribute('href', url);
  //   link.setAttribute('download', fileName);
  //   link.style.position = 'absolute';
  //   link.style.visibility = 'hidden';

  //   document.body.appendChild(link);

  //   link.click();

  //   document.body.removeChild(link);
  // };

  // const exportCSV = () => {
  //   const columns = gridRef.current.visibleColumns;

  //   const header = columns.map((c: any) => c.name).join(SEPARATOR);
  //   const rows: GridRowsProp = gridRef.current.data.map((data: any) => columns.map((c: any) => {
  //     let cellData = data[c.id];
  //     return String(cellData).replace(/\n/g, ' ').replace(/,/g, ' and ').replace(/\r/g, ' ');
  //   }
  //   ).join(SEPARATOR));

  //   const contents = [header].concat(rows).join('\n');
  //   const blob = new Blob([contents], { type: 'text/csv;charset=utf-8;' });

  //   downloadBlob(blob);

  // };

  /*

const exportCSV = () => {
    const columns = gridRef.current.visibleColumns;

    // Increase the width for each column by adding extra characters
    const header = columns.map((c: any) => `"${c.name}        "`).join(SEPARATOR); // Added extra spaces
    const rows = gridRef.current.data.map((data: any) => columns.map((c: any) => {
      let cellData = data[c.id];
      return `"${String(cellData).replace(/\n/g, ' ').replace(/,/g, ' and ').replace(/\r/g, ' ')}"`;
    }).join(SEPARATOR));

    const contents = [header].concat(rows).join('\n');
    const blob = new Blob([contents], { type: 'text/csv;charset=utf-8;' });

    downloadBlob(blob);  
};

*/




  const emptyText = loading ? <BounceLoader color={"#123abc"} loading={true} size={100} /> : <b style={{
    padding: 60,
    border: '1px solid #ef9a9a',
    color: '#ef9a9a',
    borderRadius: 10,
    fontSize: '30px',
    borderWidth: '3px'
  }}>No content found!</b>



  const filterValues = [
    { name: 'CRNumber', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'DateCreated', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'Name', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'Product', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'Lot', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'AssignedTo', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'CompleteDate', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'Approval1', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'Approval2', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'Approval3', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'Desc', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'CFDesc', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'Company', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'Initiator', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'Level', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'Basis', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'QAAuthSign', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'NoDrums', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'NoTotes', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'DrumGal', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'ToteGal', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'DrumGWeigh', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'ToteGWeigh', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'ActAssignDate', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'AnalysisNM', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'InstDisp', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'FinalInsp', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'SCAR', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'Approve1Date', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'Approve2Date', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'Approve3Date', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'InterimAction', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'RootCause', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'CPA', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'CPASign', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'CPADate', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'CPAFollowUP', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'CompleteDateCA', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'Approve1CA', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'Approve1DateCA', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'Approve2CA', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'Approve2DateCA', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'Approve3CA', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'Approve3DateCA', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'ReturnCategory', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'SerialNumber', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'MagniDetroitCR', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'ResearchProject', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'EstimatedCostofQuality', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'Department', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'CustomerFeedbackCategory', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'CustomerComplaint', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'AnalyticalReport', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'ProposedAuditDate', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'LastUpdate', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'Visc', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'WPG', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'WtSlds', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'Color', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },
    { name: 'Sales', operator: 'contains', type: 'string', value: '', defaultFlex: 1 },






  ]

  const navigate = useNavigate();

  // const handleColumnVisibilityChange = (rowProps: any) => {
  //   const baseUrl = window.location.origin;
  //   localStorage.setItem('redirecturl', '/conditionreports/crdetails/' + rowProps.data.CRNumber);
  //   console.log(`${baseUrl}/conditionreports/crdetails/${rowProps.data.CRNumber}`);
  //   window.open(`${baseUrl}/conditionreports/crdetails/${rowProps.data.CRNumber}`, '_blank', 'noopener,noreferrer');
  // }

  const onRowDoubleClick = useCallback((params: any) => {
    const baseUrl = window.location.origin;
    const reportUrl = `/conditionreports/crdetails/${params.row.CRNumber}`;

    // Storing the redirect URL in localStorage
    localStorage.setItem('redirecturl', reportUrl);

    // Logging and navigating to the details page
    console.log(`${baseUrl}${reportUrl}`);
    navigate(reportUrl);

    // Optional: If you want to open the report in a new window
    // const newWindow = window.open(reportUrl, '_blank', 'noopener,noreferrer');
    // if (newWindow) newWindow.opener = null;
  }, [navigate]);

  const onRowClick = useCallback((rowProps: any) => {
    localStorage.setItem('redirecturl', '/conditionreports/crdetails/' + rowProps.data.CRNumber);
    window.open('/conditionreports/crdetails/' + rowProps.data.CRNumber, '_blank', 'noopener,noreferrer');
    //toggle5(rowProps.data);
  }, [])


  const onRenderRow = useCallback((rowProps: any) => {
    const { onClick, onDoubleClick, ...restProps } = rowProps;

    const handleDoubleClick = (event: any) => {
      onRowDoubleClick(rowProps);
      if (onDoubleClick) {
        onDoubleClick(event);
      }
    };

    const handleClick = (event: any) => {
      onRowClick(rowProps);
      if (onClick) {
        onClick(event);
      }
    };



    // Assuming that rows are rendered as div elements in the original grid.
    // This might not be correct and you might need to look for the proper way 
    // to render a row if the library does not expose its default row rendering component.
    return <div {...restProps} onClick={isMobile ? handleClick : undefined}
      onDoubleClick={isMobile ? undefined : handleDoubleClick} />;
  }, [onRowClick, onRowDoubleClick, isMobile]);

  const handleAddNewReportClick = () => {
    navigate('/conditionreports/addcr/');
  };


  type ReportFilter = "all reports" | "active reports" | "closed reports";

  const defaultFilter: ReportFilter = "active reports";


  const [selectedFilter, setSelectedFilter] = useState<ReportFilter>(defaultFilter);

  const handleFilterChange = async (newFilter: ReportFilter) => {
    setSelectedFilter(newFilter);

    // Convert location to API parameter
    const locParam = selectedLocation === "All" ? undefined : selectedLocation === "Detroit" ? "D" : "K";

    if (newFilter === "all reports") {
      try {
        setLoading(true);
        const response = await apiCalls.GetAllReports(locParam);
        const mappedData = mapReportData(response.data);
        setData(mappedData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching all reports:', error);
        setLoading(false);
      }
    } else if (newFilter === "active reports") {
      try {
        setLoading(true);
        const response = await apiCalls.GetActiveReports(locParam);
        const mappedData = mapReportData(response.data);
        setData(mappedData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching active reports:', error);
        setLoading(false);
      }
    } else if (newFilter === "closed reports") {
      try {
        setLoading(true);
        const response = await apiCalls.GetClosedReports(locParam);
        const mappedData = mapReportData(response.data);
        setData(mappedData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching closed reports:', error);
        setLoading(false);
      }
    }
  };

  // Handler for location filter changes
  const handleLocationChange = async (newLocation: "All" | "Detroit" | "Kentucky") => {
    setSelectedLocation(newLocation);
    
    // Convert the NEW location to API parameter directly (don't rely on state)
    const locParam = newLocation === "All" ? undefined : newLocation === "Detroit" ? "D" : "K";
    
    // Reapply the current report filter with the new location
    if (selectedFilter === "all reports") {
      try {
        setLoading(true);
        const response = await apiCalls.GetAllReports(locParam);
        const mappedData = mapReportData(response.data);
        setData(mappedData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching all reports:', error);
        setLoading(false);
      }
    } else if (selectedFilter === "active reports") {
      try {
        setLoading(true);
        const response = await apiCalls.GetActiveReports(locParam);
        const mappedData = mapReportData(response.data);
        setData(mappedData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching active reports:', error);
        setLoading(false);
      }
    } else if (selectedFilter === "closed reports") {
      try {
        setLoading(true);
        const response = await apiCalls.GetClosedReports(locParam);
        const mappedData = mapReportData(response.data);
        setData(mappedData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching closed reports:', error);
        setLoading(false);
      }
    }
  };

  // Helper function to map report data with user names
  const mapReportData = (data: ReportObjectGrid[]) => {
    return data.map((report) => ({
      ...report,
      DateInitiated: report.DateInitiated ? new Date(report.DateInitiated) : null,
      InitiatorApprove: users[report.InitiatorApprove] || '',
      QCManagerApprove: users[report.QCManagerApprove] || '',
      Approve3: users[report.Approve3] || '',
      ActionAssigned: users[report.ActionAssigned] || '',
      CPACompletionDate: report.CorrectiveAction?.CPACompletionDate ? new Date(report.CorrectiveAction?.CPACompletionDate) : null,
      CPAQCManagerApprove: report.CorrectiveAction?.CPAQCManagerApprove ? users[report.CorrectiveAction?.CPAQCManagerApprove] : '',  
      CPAInitiatorApprove: report.CorrectiveAction?.CPAInitiatorApprove ? users[report.CorrectiveAction?.CPAInitiatorApprove] : '',
      CPAApprove3: report.CorrectiveAction?.CPAApprove3 ? users[report.CorrectiveAction?.CPAApprove3] : '',
      DateCompleted: report.DateCompleted ? new Date(report.DateCompleted) : null,
    }));
  }

  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundColor: '#0e226a',
        color: "#000000",
        padding: "1.5rem 1rem"
      }}
    >
      {/* Shift Header2 down by wrapping it in a container with marginTop */}
      <div style={{ marginTop: "0px" }}>
        <Header2 />
      </div>
      
      {/* Filter Container - Updated to include both report type and location filters */}
      <div style={{ display: "flex", justifyContent: "center", marginTop: "-10px" }}>
        {/* Report Type Filter */}
        <div
          style={{
            backgroundColor: "#efefef",
            borderRadius: "8px",
            padding: "5px",
            display: "flex",
            justifyContent: "center",
            margin: "5px auto",
            marginRight: "10px",
            width: "fit-content" // shrink container to content width
          }}
        >
          <label style={{ marginRight: "20px" }}>
            <input
              type="radio"
              value="all reports"
              checked={selectedFilter === "all reports"}
              onChange={() => handleFilterChange("all reports")}
              style={{ marginRight: "5px" }}
            />
            All Reports
          </label>
          <label style={{ marginRight: "20px" }}>
            <input
              type="radio"
              value="active reports"
              checked={selectedFilter === "active reports"}
              onChange={() => handleFilterChange("active reports")}
              style={{ marginRight: "5px" }}
            />
            Active Reports
          </label>
          <label>
            <input
              type="radio"
              value="closed reports"
              checked={selectedFilter === "closed reports"}
              onChange={() => handleFilterChange("closed reports")}
              style={{ marginRight: "5px" }}
            />
            Closed Reports
          </label>
        </div>

        {/* Location Filter */}
        <div
          style={{
            backgroundColor: "#efefef",
            borderRadius: "8px",
            padding: "5px",
            display: "flex",
            justifyContent: "center",
            margin: "5px auto",
            marginLeft: "10px",
            width: "fit-content" // shrink container to content width
          }}
        >
          <label style={{ marginRight: "20px" }}>
            <input
              type="radio"
              value="All"
              checked={selectedLocation === "All"}
              onChange={() => handleLocationChange("All")}
              style={{ marginRight: "5px" }}
            />
            All Locations
          </label>
          <label style={{ marginRight: "20px" }}>
            <input
              type="radio"
              value="Detroit"
              checked={selectedLocation === "Detroit"}
              onChange={() => handleLocationChange("Detroit")}
              style={{ marginRight: "5px" }}
            />
            Detroit
          </label>
          <label>
            <input
              type="radio"
              value="Kentucky"
              checked={selectedLocation === "Kentucky"}
              onChange={() => handleLocationChange("Kentucky")}
              style={{ marginRight: "5px" }}
            />
            Kentucky
          </label>
        </div>
      </div>

      <div style={{ display: 'flex', justifyContent: 'center', gap: '30px', marginTop: '10px' }}>
        <Button
          color="primary"
          onClick={handleAddNewReportClick}
          style={{
            fontSize: '18px',
            padding: '5px 10px',
            margin: '0 5px',
            backgroundColor: '#efefef',
            color: '#000000',
            outline: '1px solid black',
            marginBottom: '20px'
          }}
        >
          Create New CR
        </Button>

        {/* <Button
          color="primary"
          onClick={generateRMA}
          style={{
            fontSize: '18px',
            padding: '5px 10px',
            margin: '0 5px',
            backgroundColor: '#efefef',
            color: '#000000',
            outline: '1px solid black',
            marginBottom: '20px'
          }}
        >
          Print RMA for Selected
        </Button>

        <Button
          color="primary"
          onClick={generateCAR}
          style={{
            fontSize: '18px',
            padding: '5px 10px',
            margin: '0 5px',
            backgroundColor: '#efefef',
            color: '#000000',
            outline: '1px solid black',
            marginBottom: '20px'
          }}
        >
          Print CAR/PAR for Selected
        </Button> */}

        {/* <Button
          color="primary"
          onClick={() => navigate('/menu')}
          style={{
            fontSize: '18px',
            padding: '5px 10px',
            margin: '0 5px',
            backgroundColor: '#efefef',
            color: '#000000',
            outline: '1px solid black',
            marginBottom: '20px'
          }}
        >
          Return to Menu
        </Button> */}
        </div>
        {/* The remainder of your page content */}
        <div style={{ 
          backgroundColor: "#efefef", 
          color: "#000000", 
          padding: "0",
          width: "100%",
          overflow: "auto",
          marginLeft: "-0.5rem",
          marginRight: "-0.5rem"
        }}>
        <DataGrid
          rows={rows}
          columns={columns2}
          getRowId={(row) => row.CRNumber}
          onRowDoubleClick={onRowDoubleClick}
          style={{ width: "100%", height: "70vh" }}
          loading={loading} // Built-in loading property
          slots={{
            loadingOverlay: () => <DataLoading />, // Custom loading overlay
            noRowsOverlay: () => (
              <div
                style={{
                  display: 'flex',
                  top: '40%', // Adjust this to move the loader vertically
                  left: '50%', // Center horizontally
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  fontSize: '18px',
                  color: '#ef9a9a',
                }}
              >
                No contents here !!!
              </div>
            ),
          }}
          rowHeight={65}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 100 }
            },
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
              width: "100%",
              margin: 0,
              padding: 0
            },
            "& .MuiDataGrid-main": {
              width: "100%",
              maxWidth: "100%"
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#0e226a !important",
              color: "#FFFFFF",
              fontWeight: "bold",
              fontSize: "0.75rem",
              lineHeight: "1.1"
            },
            "& .MuiDataGrid-cell": {
              fontSize: "0.75rem",
              padding: "4px",
              whiteSpace: "normal",
              lineHeight: "1.3",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              overflow: "visible",
              wordBreak: "break-word",
              verticalAlign: "middle",
              "& > div": {
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                verticalAlign: "middle"
              }
            },
            "& .MuiDataGrid-columnHeader": {
              backgroundColor: "#0e226a !important",
              padding: "4px 2px"
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              whiteSpace: "pre-line",
              lineHeight: "1.1",
              textAlign: "center",
              padding: "0 4px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%"
            },
            "& .MuiDataGrid-columnHeaderTitleContainer": {
              justifyContent: "center",
              padding: "0 2px",
              height: "100%",
              display: "flex",
              alignItems: "center"
            },
            "& .MuiDataGrid-columnHeaderContent": {
              justifyContent: "center",
              height: "100%",
              display: "flex",
              alignItems: "center"
            },
            "& .MuiDataGrid-row:hover": {
              backgroundColor: "#0e226a",
              color: '#FFFFFF'
            },
            "& .MuiDataGrid-row:hover .MuiCheckbox-root": {
              color: "#FFFFFF"
            },
            "& .MuiDataGrid-row:hover .MuiCheckbox-root.Mui-checked": {
              color: "#FFFFFF"
            },
            "& .Mui-selected": {
              backgroundColor: "#0e226a !important",
              color: "#fff !important"
            },
            "& .MuiDataGrid-focusVisible": {
              outline: "none !important"
            },
            "& .MuiDataGrid-iconButtonContainer": {
              color: "white"
            },
            "& .MuiDataGrid-sortIcon": {
              color: "white"
            },
            "& .MuiDataGrid-menuIcon": {
              color: "white"
            },
            // Additional styling for menu icons and buttons - more specific selectors
            "& .MuiDataGrid-columnHeader .MuiButtonBase-root": {
              color: "white"
            },
            "& .MuiDataGrid-columnHeaderMenuButton": {
              color: "white"
            },
            "& .MuiDataGrid-columnHeader .MuiIconButton-root": {
              color: "white"
            },
            "& .MuiMenu-paper .MuiMenuItem-root": {
              fontSize: "0.875rem"
            },
            // Enhanced targeting for the specific menu icon in the column header
            "& .MuiDataGrid-columnHeader .MuiDataGrid-menuIconButton": {
              color: "white"
            },
            "& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer button": {
              color: "white"
            },
            "& .MuiDataGrid-columnHeader .MuiSvgIcon-root": {
              color: "white"
            },
            // Explicitly style checkboxes back to their original color
            "& .MuiCheckbox-root": {
              color: "#0e226a"
            },
            "& .MuiCheckbox-root.Mui-checked": {
              color: "#0e226a"
            }
          }}
        />
        </div>
      <ToastContainer />
    </div>
  );
}